import {
    CountryVersion,
    CurrencyCode,
    CurrencySymbolPosition,
    ISOLanguagesCode,
} from "constants/enum";

export const getPrice = (
    prices: any,
    version: CountryVersion,
    prefix = "base"
) => {
    let price = {};

    if (prices[`${prefix}WD`]?.[version]) {
        const total = prices[`${prefix}Total`]?.[version];

        price = {
            old: prices[prefix][version],
            current: prices[`${prefix}WD`][version],
            ...(total && { total }),
        };
    } else {
        const total = prices[`${prefix}Total`]?.[version];

        price = {
            current: prices[prefix][version],
            ...(total && { total }),
        };
    }

    return price;
};

export const getTreatmentCaseDuration = (
    prices: any,
    version: CountryVersion,
    prefix = "base"
) => {
    const duration = prices[`${prefix}Duration`]?.[version];
    return duration || prices.baseDuration?.[version];
};

export const getCurrencySymbolPosition = (
    version: CountryVersion,
    currency: string
) => {
    const isoCurrencyCode = (<any>CurrencyCode)[currency] || CurrencyCode["€"];
    const currencyString = new Intl.NumberFormat(ISOLanguagesCode[version], {
        style: "currency",
        currency: isoCurrencyCode,
        currencyDisplay: "code",
    }).format(99);

    if (currencyString.slice(0, 3) === isoCurrencyCode) {
        return CurrencySymbolPosition.BEFORE;
    }

    if (currencyString.slice(-3) === isoCurrencyCode) {
        return CurrencySymbolPosition.AFTER;
    }
};
