import {
    TmpImageTextAndPriceBlocksTypes,
    TmpImageTextAndPriceAreaContent,
} from "../../TmpImageTextAndPrice.types";
import { HeadingBlock, PriceBlock, ListBlock, ImageBlock } from "./";

export const renderContentBlock = ({
    block,
    stretchTop = [false, false],
    stretchBottom = [false, false],
}: {
    block: TmpImageTextAndPriceAreaContent;
    stretchTop: [boolean, boolean];
    stretchBottom: [boolean, boolean];
}) => {
    switch (block.type) {
        case TmpImageTextAndPriceBlocksTypes.HEADING: {
            return <HeadingBlock content={block.content} />;
        }
        case TmpImageTextAndPriceBlocksTypes.PRICE: {
            return <PriceBlock content={block.content} />;
        }
        case TmpImageTextAndPriceBlocksTypes.IMAGE: {
            return (
                <ImageBlock
                    content={block.content}
                    stretchTop={stretchTop}
                    stretchBottom={stretchBottom}
                />
            );
        }
        case TmpImageTextAndPriceBlocksTypes.LIST: {
            return <ListBlock content={block.content} />;
        }
    }
};
