import { Translate } from "next-translate";

const pageContent = (t: Translate) => {
    return {
        priceBlockPriceTitle: t("advanced:universalBlockPriceCostTitle"),
        priceBlockDurationTitle: t("advanced:universalBlockPriceDurationTitle"),
        priceBlockCTA: t("commonPricesNew:cta"),
    };
};

export default pageContent;
