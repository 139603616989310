import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        welcome: {
            image: {
                defaultSrc: "/images/new/como-funciona/us/main-mobile.jpg",
                images: [
                    {
                        jpeg: "/images/new/como-funciona/us/main-mobile.jpg",
                        webp: "/images/new/como-funciona/us/main-mobile.webp",
                    },
                    {
                        jpeg: "/images/new/como-funciona/us/main-desktop.jpg",
                        webp: "/images/new/como-funciona/us/main-desktop.webp",
                        media: "(min-width: 1024px)",
                    },
                ],
                rounded: true,
            },
        },
        faq: {
            title: t("faq:questionsTitle"),
            list: [
                {
                    question: t("faq:questionsListItemQuestionHowItWorks1"),
                    answer: t("faq:questionsListItemAnswerHowItWorks1"),
                },
                {
                    question: t("faq:questionsListItemQuestionHowItWorks2"),
                    answer: t("faq:questionsListItemAnswerHowItWorks2"),
                },
                {
                    question: t("faq:questionsListItemQuestionHowItWorks3"),
                    answer: t("faq:questionsListItemAnswerHowItWorks3"),
                },
                {
                    question: t("faq:questionsListItemQuestionHowItWorks4"),
                    answer: t("faq:questionsListItemAnswerHowItWorks4"),
                },
            ],
        },
    });

export default pageContent;
