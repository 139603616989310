import { CountryVersion } from "constants/enum";

import spain from "./spain";
import italy from "./italy";
import portugal from "./portugal";
import germany from "./germany";
import france from "./france";
import uk from "./uk";
import usChicago from "./usChicago";
import usSanFrancisco from "./usSanFrancisco";
import usSeattle from "./usSeattle";

export default {
    [CountryVersion.SPAIN]: spain,
    [CountryVersion.ITALY]: italy,
    [CountryVersion.PORTUGAL]: portugal,
    [CountryVersion.GERMANY]: germany,
    [CountryVersion.FRANCE]: france,
    [CountryVersion.UK]: uk,
    [CountryVersion.US]: {
        sanFrancisco: usSanFrancisco,
        chicago: usChicago,
        seattle: usSeattle,
    },
};
