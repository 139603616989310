import main from "./main";
import blog from "./blog";
import teen from "./teen";
import thanks from "./thanks";
import prices from "./prices";
import blogPost from "./blogPost";
import locations from "./locations";
import retainersSubscription from "./retainersSubscription";
import whyImpress from "./whyImpress";
import thanksMail from "./thanksMail";
import thanksText from "./thanksText";
import thanksSign from "./thanksSign";
import howItWorks from "./howItWorks";
import universalForm from "./universalForm";
import universalSelect from "./universalSelect";
import bookingWidget from "./bookingWidget";
import results from "./results";
import about from "./about";
import firstVisit from "./firstVisit";
import c2 from "./c2";
import referral from "./referral";
import referralForFriends from "./referralForFriends";
import newCity from "./newCity";
import quiz from "./quiz";
import advanced from "./advanced";
import associatedClinics from "./associatedClinics";
import thanksContact from "./thanksContact";
import kids from "./kids";

interface ContentMap {
    // [key: string]: { [key in Version]: (t: TranslatorFunction) => {} }; TODO https://impress-global.atlassian.net/browse/DEV-399
    [key: string]: any;
}

const contentMap: ContentMap = {
    main,
    blog,
    teen,
    thanks,
    results,
    prices,
    blogPost,
    locations,
    retainersSubscription,
    howItWorks,
    thanksText,
    thanksMail,
    whyImpress,
    universalForm,
    universalSelect,
    bookingWidget,
    about,
    thanksSign,
    firstVisit,
    c2,
    referral,
    referralForFriends,
    newCity,
    quiz,
    advanced,
    associatedClinics,
    thanksContact,
    kids,
};

export default contentMap;
