import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { pathConfig } from "configs/pathConfig";
import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const content = defaultContent(t, { version });

    return mergeDeepRight(content, {
        mainBanner: {
            image: {
                mobile: {
                    webp: "/images/new/results/us/mainBanner/hero-mobile.webp",
                    jpeg: "/images/new/results/us/mainBanner/hero-mobile.jpg",
                },
                desktop: {
                    webp: "/images/new/results/us/mainBanner/hero-desktop.webp",
                    jpeg: "/images/new/results/us/mainBanner/hero-desktop.jpg",
                },
                rounded: true,
            },
        },
        ourResults: {
            reviews: [
                {
                    hasTrustpilotRating: false,
                    image: {
                        mobile: {
                            webp: "/images/new/results/us/ourResults/step-1-mobile.webp",
                            jpeg: "/images/new/results/us/ourResults/step-1-mobile.jpeg",
                        },
                        desktop: {
                            webp: "/images/new/results/us/ourResults/step-1-desktop.webp",
                            jpeg: "/images/new/results/us/ourResults/step-1-desktop.jpeg",
                        },
                    },
                },
                {
                    hasTrustpilotRating: false,
                    image: {
                        mobile: {
                            webp: "/images/new/results/us/ourResults/step-2-mobile.webp",
                            jpeg: "/images/new/results/us/ourResults/step-2-mobile.jpeg",
                        },
                        desktop: {
                            webp: "/images/new/results/us/ourResults/step-2-desktop.webp",
                            jpeg: "/images/new/results/us/ourResults/step-2-desktop.jpeg",
                        },
                    },
                },
                {
                    hasTrustpilotRating: false,
                    image: {
                        mobile: {
                            webp: "/images/new/results/us/ourResults/step-3-mobile.webp",
                            jpeg: "/images/new/results/us/ourResults/step-3-mobile.jpeg",
                        },
                        desktop: {
                            webp: "/images/new/results/us/ourResults/step-3-desktop.webp",
                            jpeg: "/images/new/results/us/ourResults/step-3-desktop.jpeg",
                        },
                    },
                },
            ],
        },
        hygiene: {
            image: {
                desktop: {
                    webp: "/images/new/results/us/hygiene/woman-desktop.webp",
                    jpeg: "/images/new/results/us/hygiene/woman-desktop.jpg",
                },
                mobile: {
                    webp: "/images/new/results/us/hygiene/woman-mobile.webp",
                    jpeg: "/images/new/results/us/hygiene/woman-mobile.jpg",
                },
            },
        },
        followUp: {
            image: {
                desktop: {
                    webp: "/images/new/results/us/followUp/screen-desktop.webp",
                    jpeg: "/images/new/results/us/followUp/screen-desktop.jpg",
                },
                mobile: {
                    webp: "/images/new/results/us/followUp/screen-mobile.webp",
                    jpeg: "/images/new/results/us/followUp/screen-mobile.jpg",
                },
            },
            appointmentButton: {
                name: t("global:bookAppointmentButton"),
                link: pathConfig.universalForm[version],
            },
        },
        faq: {
            title: t("faq:questionsTitle"),
            list: [
                {
                    question: t("faq:questionsListItemQuestionResults1"),
                    answer: t("faq:questionsListItemAnswerResults1"),
                },
                {
                    question: t("faq:questionsListItemQuestionResults2"),
                    answer: t("faq:questionsListItemAnswerResults2"),
                },
                {
                    question: t("faq:questionsListItemQuestionResults3"),
                    answer: t("faq:questionsListItemAnswerResults3"),
                },
                {
                    question: t("faq:questionsListItemQuestionResults4"),
                    answer: t("faq:questionsListItemAnswerResults4"),
                },
            ],
        },
    });
};

export default pageContent;
