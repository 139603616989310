import { Translate } from "next-translate";

import prices from "configs/prices.json";
import { priceFormatter } from "utils/priceFormatter";
import { getDesignPostfix } from "utils/getDesignPostfix";
import { pathConfig } from "navigation/pathConfig";
import { PageContentProps } from "content/content.types";
import { MainPageContentProps } from "layouts/pages/MainPageLayout";

const pageContent = (
    t: Translate,
    { version }: PageContentProps
): MainPageContentProps => {
    const { newDesignPostfix } = getDesignPostfix(version);

    const lightMonthBasePrice =
        prices.lightPrice.monthly.baseWD[version] ||
        prices.lightPrice.monthly.base[version];
    const regularMonthBasePrice =
        prices.regularPrice.monthly.baseWD[version] ||
        prices.regularPrice.monthly.base[version];

    return {
        headerBanner: {
            title: t("main:headTitle"),
            description: t("main:headDescription", {
                amount: priceFormatter(
                    lightMonthBasePrice || regularMonthBasePrice,
                    prices.currency[version],
                    version
                ),
            }),
            image: {
                defaultSrc: `/images/new/main/header-mobile.jpg`,
                images: [
                    {
                        jpeg: `/images/new/main/header-mobile.jpg`,
                        webp: `/images/new/main/header-mobile.webp`,
                    },
                    {
                        jpeg: `/images/new/main/header-desktop.jpg`,
                        webp: `/images/new/main/header-desktop.webp`,
                    },
                ],
                rounded: true,
            },
        },
        features: {
            title: t("main:informationBlocksTitle"),
            description: t("main:informationBlocksDescription"),
            list: [
                {
                    image: {
                        images: [
                            {
                                webp: `/images/new/main/feature-1-mobile.webp`,
                                jpeg: `/images/new/main/feature-1-mobile.jpg`,
                            },
                            {
                                webp: `/images/new/main/feature-1-desktop.webp`,
                                jpeg: `/images/new/main/feature-1-desktop.jpg`,
                            },
                        ],
                    },
                    text: t("main:informationBlocksListItemText1"),
                },
                {
                    image: {
                        images: [
                            {
                                jpeg: `/images/new/main/feature-2-mobile.jpg`,
                                webp: `/images/new/main/feature-2-mobile.webp`,
                            },
                            {
                                jpeg: `/images/new/main/feature-2-desktop.jpg`,
                                webp: `/images/new/main/feature-2-desktop.webp`,
                            },
                        ],
                    },
                    text: t("main:informationBlocksListItemText2"),
                },
                {
                    image: {
                        images: [
                            {
                                webp: "/images/new/main/feature-3-mobile.webp",
                                jpeg: "/images/new/main/feature-3-mobile.jpg",
                            },
                            {
                                webp: "/images/new/main/feature-3-desktop.webp",
                                jpeg: "/images/new/main/feature-3-desktop.jpg",
                            },
                        ],
                    },
                    text: t("main:informationBlocksListItemText3"),
                },
                {
                    image: {
                        images: [
                            {
                                webp: `/images/new/main/step4-mobile-2x${newDesignPostfix}.webp`,
                                jpeg: `/images/new/main/step4-mobile-2x${newDesignPostfix}.jpg`,
                            },
                            {
                                webp: `/images/new/main/step4-desktop-2x${newDesignPostfix}.webp`,
                                jpeg: `/images/new/main/step4-desktop-2x${newDesignPostfix}.jpg`,
                            },
                        ],
                    },
                    text: t("main:informationBlocksListItemText4"),
                },
            ],
            button: {
                text: t("global:bookAppointmentButton"),
                link: pathConfig.universalForm[version],
            },
        },
        checkUp: {
            title: t("main:checkUpTitle"),
            list: [
                t("main:checkUpListItem1"),
                t("main:checkUpListItem2"),
                t("main:checkUpListItem3"),
                t("main:checkUpListItem4", {
                    amount: priceFormatter(
                        lightMonthBasePrice || regularMonthBasePrice,
                        prices.currency[version],
                        version
                    ),
                }),
            ],
            image: {
                images: [
                    {
                        webp: "/images/new/main/checkup-mobile.webp",
                        jpeg: "/images/new/main/checkup-mobile.jpg",
                    },
                    {
                        webp: "/images/new/main/checkup-desktop.webp",
                        jpeg: "/images/new/main/checkup-desktop.jpg",
                    },
                ],
                defaultSrc: "/images/new/main/checkup-mobile.jpg",
            },
            rounded: true,
        },
        quizPromo: {
            title: t("main:quizPromoTitle"),
            text: t("main:quizPromoDescription"),
            button: {
                name: t("main:quizPromoCTA"),
                link: pathConfig.quiz[version],
            },
            ctaComment: t("main:quizPromoCTAComment"),
            image: {
                images: [
                    {
                        webp: "/images/new/main/quiz-promo/quiz-promo-mobile.webp",
                        jpeg: "/images/new/main/quiz-promo/quiz-promo-mobile.jpg",
                    },
                    {
                        webp: "/images/new/main/quiz-promo/quiz-promo-desktop.webp",
                        jpeg: "/images/new/main/quiz-promo/quiz-promo-desktop.jpg",
                    },
                ],
            },
        },
        retainersPromo: {
            title: t("main:retainersPromoTitle"),
            button: {
                name: t("main:retainersPromoCTA"),
                link: pathConfig.retainersSubscription[version],
            },
            ctaComment: t("main:retainersPromoCTAComment", {
                price: priceFormatter(
                    prices.subscriptions.retainers.light.monthly[version],
                    prices.currency[version],
                    version
                ),
            }),
            image: {
                images: [
                    {
                        webp: "/images/new/main/retainers-promo/retainers-promo-mobile.webp",
                        jpeg: "/images/new/main/retainers-promo/retainers-promo-mobile.jpg",
                    },
                    {
                        webp: "/images/new/main/retainers-promo/retainers-promo-desktop.webp",
                        jpeg: "/images/new/main/retainers-promo/retainers-promo-desktop.jpg",
                    },
                ],
            },
        },
        steps: {
            title: t("main:stepsTitle"),
            list: [
                {
                    image: {
                        images: [
                            {
                                jpeg: `/images/new/main/step-1-mobile.jpg`,
                                webp: `/images/new/main/step-1-mobile.webp`,
                            },
                            {
                                jpeg: `/images/new/main/step-1-desktop.jpg`,
                                webp: `/images/new/main/step-1-desktop.webp`,
                            },
                        ],
                        defaultSrc: `/images/new/main/step-1-mobile.jpg`,
                    },
                    text: t("main:stepsListItemText1"),
                },
                {
                    image: {
                        images: [
                            {
                                jpeg: `/images/new/main/step-2-mobile.jpg`,
                                webp: `/images/new/main/step-2-mobile.webp`,
                            },
                            {
                                jpeg: `/images/new/main/step-2-desktop.jpg`,
                                webp: `/images/new/main/step-2-desktop.webp`,
                            },
                        ],
                        defaultSrc: `/images/new/main/step-2-mobile.jpg`,
                    },
                    text: t("main:stepsListItemText2"),
                },
                {
                    image: {
                        images: [
                            {
                                jpeg: `/images/new/main/step-3-mobile.jpg`,
                                webp: `/images/new/main/step-3-mobile.webp`,
                            },
                            {
                                jpeg: `/images/new/main/step-3-desktop.jpg`,
                                webp: `/images/new/main/step-3-desktop.webp`,
                            },
                        ],
                        defaultSrc: `/images/new/main/step-3-mobile.jpg`,
                    },
                    text: t("main:stepsListItemText3"),
                },
            ],
        },
        ourDoctors: {
            title: t("main:ourDoctorsTitle"),
            description: t("main:ourDoctorsDescription"),
            images: [
                {
                    images: [
                        {
                            webp: `/images/new/main/doctors-1-mobile.webp`,
                            jpeg: `/images/new/main/doctors-1-mobile.jpg`,
                        },
                        {
                            webp: `/images/new/main/doctors-1-desktop.webp`,
                            jpeg: `/images/new/main/doctors-1-desktop.jpg`,
                        },
                    ],
                    defaultSrc: `/images/new/main/doctors-1-mobile.jpg`,
                },
                {
                    images: [
                        {
                            webp: `/images/new/main/doctors-2-mobile.webp`,
                            jpeg: `/images/new/main/doctors-2-mobile.jpg`,
                        },
                        {
                            webp: `/images/new/main/doctors-2-desktop.webp`,
                            jpeg: `/images/new/main/doctors-2-desktop.jpg`,
                        },
                    ],
                    defaultSrc: `/images/new/main/doctors-2-mobile.jpg`,
                },
            ],
        },
        appointment: {
            title: t("global:bookAppointmentButton"),
            image: {
                images: [
                    {
                        jpeg: "/images/new/main/form-mobile.jpg",
                        webp: "/images/new/main/form-mobile.webp",
                    },
                    {
                        jpeg: "/images/new/main/form-desktop.jpg",
                        webp: "/images/new/main/form-desktop.webp",
                    },
                ],
                defaultSrc: "/images/new/main/form-desktop.jpg",
            },
        },
        advanced: {
            image: {
                images: [
                    {
                        jpeg: "/images/new/main/advanced/doctor-and-client-desktop.jpg",
                        webp: "/images/new/main/advanced/doctor-and-client-desktop.webp",
                    },
                ],
                defaultSrc:
                    "/images/new/main/advanced/doctor-and-client-desktop.jpg",
            },
            title: t("main:advancedBlockTitle"),
            titleLabel: t("main:advancedBlockLabelText"),
            description: t("main:advancedBlockDescription"),
            button: {
                link: pathConfig.advanced[version],
                name: t("main:advancedBlockButtonText"),
            },
        },
    };
};

export default pageContent;
