import { Translate } from "next-translate";

import { AssociatedClinicsContent } from "layouts/pages/AssociatedClinicsLayout";
import { TmpUniversalTitleWithImageBlocksTypes } from "components/templates/TmpUniversalTitleWithImage";
import {
    TmpImageTextAndPriceAreaTypes,
    TmpImageTextAndPriceBlocksTypes,
} from "components/templates/TmpImageTextAndPrice";
import { urlsConfig } from "configs/urlsConfig";
import { CountryVersion } from "constants/enum";

import { getDoctorsContent, MAX_DOCTORS } from "./helpers/getDoctorsContent";
import { getServicesContent, MAX_SERVICES } from "./helpers/getServicesContent";

export const generateListKeys = (
    t: Translate,
    blockIndex: number,
    listIndex: number,
    totalElements = 5
) => {
    return new Array(totalElements)
        .fill(null)
        .map((_, i) =>
            t(
                `associatedClinics:universalBlock${blockIndex}List${listIndex}El${
                    i + 1
                }${
                    blockIndex === 2 &&
                    listIndex === 1 &&
                    (i + 1 === 2 || i + 1 === 4)
                        ? "IT"
                        : ""
                }`,
                {},
                { fallback: "" }
            )
        )
        .filter((e) => e);
};

const pageContent = (
    t: Translate,
    { version }: { version: CountryVersion }
): AssociatedClinicsContent => ({
    tmpUniversalTitleWithImage1: {
        content: [
            {
                type: TmpUniversalTitleWithImageBlocksTypes.HEADING,
                content: {
                    title: t("associatedClinics:topHeadingIT"),
                    subtitle: t("associatedClinics:headerSubtitleIT"),
                    button: {
                        text: t("associatedClinics:universalCTA"),
                        link: urlsConfig.googleFormB2b[version],
                    },
                },
            },
            {
                type: TmpUniversalTitleWithImageBlocksTypes.IMAGE,
                content: {
                    image: {
                        images: [
                            {
                                jpeg: "/images/new/associatedClinics/header-mobile.jpg",
                                webp: "/images/new/associatedClinics/header-mobile.webp",
                            },
                            {
                                jpeg: "/images/new/associatedClinics/header-desktop.jpg",
                                webp: "/images/new/associatedClinics/header-desktop.webp",
                            },
                        ],
                        defaultSrc:
                            "/images/new/associatedClinics/header-desktop.jpg",
                    },
                },
            },
        ],
    },
    tmpUniversalTitleWithImage2: {
        content: [
            {
                type: TmpUniversalTitleWithImageBlocksTypes.HEADING,
                content: {
                    title: t("associatedClinics:bottomHeading"),
                    subtitle: t("associatedClinics:headerSubtitle"),
                    button: {
                        text: t("associatedClinics:universalCTA"),
                        link: urlsConfig.googleFormB2b[version],
                    },
                },
            },
        ],
    },
    universalBlock: [
        [
            {
                type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                leftContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.IMAGE,
                        content: {
                            image: {
                                images: [
                                    {
                                        jpeg: "/images/new/associatedClinics/aligners-mobile.jpg",
                                        webp: "/images/new/associatedClinics/aligners-mobile.webp",
                                    },
                                    {
                                        jpeg: "/images/new/associatedClinics/aligners-desktop.jpg",
                                        webp: "/images/new/associatedClinics/aligners-desktop.webp",
                                    },
                                ],
                                defaultSrc:
                                    "/images/new/associatedClinics/aligners-desktop.jpg",
                            },
                        },
                    },
                ],
                rightContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.LIST,
                        content: {
                            list: generateListKeys(t, 1, 1),
                            title: t(
                                "associatedClinics:universalBlock1Heading"
                            ),
                            button: {
                                text: t("associatedClinics:universalCTA"),
                                link: urlsConfig.googleFormB2b[version],
                            },
                        },
                    },
                ],
            },
        ],
        [
            {
                type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                leftContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.IMAGE,
                        content: {
                            image: {
                                images: [
                                    {
                                        jpeg: "/images/new/associatedClinics/app-mobile.jpg",
                                        webp: "/images/new/associatedClinics/app-mobile.webp",
                                    },
                                    {
                                        jpeg: "/images/new/associatedClinics/app-desktop.jpg",
                                        webp: "/images/new/associatedClinics/app-desktop.webp",
                                    },
                                ],
                                defaultSrc:
                                    "/images/new/associatedClinics/app-desktop.jpg",
                            },
                        },
                    },
                ],
                rightContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.LIST,
                        content: {
                            list: generateListKeys(t, 2, 1),
                            title: t(
                                "associatedClinics:universalBlock2Heading"
                            ),
                            button: {
                                text: t("associatedClinics:universalCTA"),
                                link: urlsConfig.googleFormB2b[version],
                            },
                        },
                    },
                ],
            },
        ],
    ],
    doctorsBlock: {
        title: t("associatedClinics:doctorsBlockTitleIT"),
        description: t("associatedClinics:doctorsBlockDescription"),
        doctors: getDoctorsContent(t, MAX_DOCTORS),
    },
    ourServicesBlock: {
        ourServicesContent: {
            title: t("associatedClinics:ourServicesBlockTitle"),
            list: getServicesContent(t, MAX_SERVICES, version),
        },
        ourServicesModalContent: {
            title: t("associatedClinics:ourServicesBlockModalTitleIT"),
            buttonText: t("associatedClinics:ourServicesBlockModalButtonText"),
            buttonLink: urlsConfig.googleFormB2b[version],
        },
    },
    trustBlock: [
        {
            title: t("associatedClinics:trustBlockEl1Title"),
            text: t("associatedClinics:trustBlockEl1Text"),
            image: {
                images: [
                    {
                        jpeg: "/images/new/associatedClinics/trust/el1-mobile.jpg",
                        webp: "/images/new/associatedClinics/trust/el1-mobile.webp",
                    },
                    {
                        jpeg: "/images/new/associatedClinics/trust/el1-desktop.jpg",
                        webp: "/images/new/associatedClinics/trust/el1-desktop.webp",
                    },
                ],
                defaultSrc:
                    "/images/new/associatedClinics/trust/el1-mobile.jpg",
            },
        },
        {
            title: t("associatedClinics:trustBlockEl2Title"),
            text: t("associatedClinics:trustBlockEl2TextIT"),
            image: {
                images: [
                    {
                        jpeg: "/images/new/associatedClinics/trust/el2-mobile.jpg",
                        webp: "/images/new/associatedClinics/trust/el2-mobile.webp",
                    },
                    {
                        jpeg: "/images/new/associatedClinics/trust/el2-desktop.jpg",
                        webp: "/images/new/associatedClinics/trust/el2-desktop.webp",
                    },
                ],
                defaultSrc:
                    "/images/new/associatedClinics/trust/el2-mobile.jpg",
            },
        },
        {
            title: t("associatedClinics:trustBlockEl3Title"),
            text: t("associatedClinics:trustBlockEl3Text"),
            image: {
                images: [
                    {
                        jpeg: "/images/new/associatedClinics/trust/el3-mobile.jpg",
                        webp: "/images/new/associatedClinics/trust/el3-mobile.webp",
                    },
                    {
                        jpeg: "/images/new/associatedClinics/trust/el3-desktop.jpg",
                        webp: "/images/new/associatedClinics/trust/el3-desktop.webp",
                    },
                ],
                defaultSrc:
                    "/images/new/associatedClinics/trust/el3-mobile.jpg",
            },
        },
        {
            title: t("associatedClinics:trustBlockEl4Title"),
            text: t("associatedClinics:trustBlockEl4Text"),
            image: {
                images: [
                    {
                        jpeg: "/images/new/associatedClinics/trust/el4-mobile.jpg",
                        webp: "/images/new/associatedClinics/trust/el4-mobile.webp",
                    },
                    {
                        jpeg: "/images/new/associatedClinics/trust/el4-desktop.jpg",
                        webp: "/images/new/associatedClinics/trust/el4-desktop.webp",
                    },
                ],
                defaultSrc:
                    "/images/new/associatedClinics/trust/el4-mobile.jpg",
            },
        },
    ],
});

export default pageContent;
