import { Translate } from "next-translate";

import contacts from "configs/contacts.json";
import { ContactTypes, SocialTypes } from "constants/enum";
import { getWALink } from "utils/getWALink";
import { ThankYouSimpleLayoutContent } from "layouts/pages/ThanksSimpleLayout/ThanksSimpleLayout.types";
import { pathConfig } from "navigation/pathConfig";
import { PageContentProps } from "content/content.types";

const pageContent: any = (
    t: Translate,
    { version }: PageContentProps
): ThankYouSimpleLayoutContent => ({
    background: {
        video: "/video/thanks.mp4",
    },
    logoLink: pathConfig.main[version],
    title: t("thanks-mail:title"),
    subtitle: t("thanks-mail:subtitle"),

    socialMedia: {
        title: t("thanks-mail:socialMediaTitle"),
        list: [
            {
                name: contacts.facebook[version].name,
                icon: SocialTypes.FB,
                link: contacts.facebook[version].link,
            },
            {
                name: contacts.instagram[version].username,
                icon: SocialTypes.INSTAGRAM,
                link: contacts.instagram[version].link,
            },
            {
                name: contacts.tiktok[version].name,
                icon: SocialTypes.TIKTOK,
                link: contacts.tiktok[version].link,
            },
        ],
    },
    contacts: {
        title: t("thanks-mail:contactsTitle"),
        list: [
            {
                id: ContactTypes.PHONE,
                name: contacts.phone[version].public,
                icon: ContactTypes.PHONE,
                link: `tel:${contacts.phone[version].link}`,
            },
            {
                id: SocialTypes.WA,
                name: "WhatsApp",
                icon: SocialTypes.WA,
                link: getWALink(
                    contacts.whatsapp[version].link,
                    t("global:whatsappMessage")
                ),
            },
            {
                id: ContactTypes.EMAIL,
                name: contacts.email[version].link,
                icon: ContactTypes.EMAIL,
                link: `mailto:${contacts.email[version].link}`,
            },
        ],
    },
});

export default pageContent;
