import { CountryVersion, Language } from "constants/enum";
import { PictureProps } from "types/picture";

export enum TmpImageTextAndPriceBlocksTypes {
    HEADING = "heading",
    IMAGE = "image",
    LIST = "list",
    PRICE = "price",
}

export enum TmpImageTextAndPriceAreaTypes {
    FULL_WIDTH = "fullWidth",
    TWO_COLUMNS = "twoColumns",
}

export interface HeadingBlockContent {
    title: string;
    subtitle?: string;
}
export interface ImageBlockContent {
    image: PictureProps;
}

export interface ListBlockContent {
    list: string[];
    title: string;

    subTitle?: string;
    button?: {
        text: string;
        link?: string;
        onClick?: () => {};
    };
}

export interface PriceBlockContent {
    duration: string;
    price: string;
    financationInfo: string;
}

export type TmpImageTextAndPriceAreaContent =
    | {
          type: TmpImageTextAndPriceBlocksTypes.HEADING;
          content: HeadingBlockContent;
      }
    | {
          type: TmpImageTextAndPriceBlocksTypes.IMAGE;
          content: ImageBlockContent;
      }
    | {
          type: TmpImageTextAndPriceBlocksTypes.LIST;
          content: ListBlockContent;
      }
    | {
          type: TmpImageTextAndPriceBlocksTypes.PRICE;
          content: PriceBlockContent;
      };

export interface FullWidthArea {
    type: TmpImageTextAndPriceAreaTypes.FULL_WIDTH;
    content: TmpImageTextAndPriceAreaContent[];
}

export interface TwoColumnsArea {
    type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS;
    leftContent: TmpImageTextAndPriceAreaContent[];
    rightContent: TmpImageTextAndPriceAreaContent[];
}

export type TmpImageTextAndPriceAreas = FullWidthArea | TwoColumnsArea;

export interface TmpImageTextAndPriceProps {
    content: TmpImageTextAndPriceAreas[];
    version: CountryVersion;
    language: Language;
}
