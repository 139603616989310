import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const content = defaultContent(t, { version });

    return mergeDeepRight(content, {
        ourResults: { ...content.ourResults, link: null },
    });
};

export default pageContent;
