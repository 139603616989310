import { Translate } from "next-translate";

import { Language } from "constants/enum";

import { versions } from "configs/versionsConfig";

const getPageData = (
    t: Translate,
    {
        language,
    }: {
        language: Language;
    }
) => ({
    language,
    tableOfContentsTitle: t("blog:content"),
    hideRegionSelector: true,
    shareTitle: t("blog:shareArticle"),
});

export default {
    [versions.italy]: getPageData,
    [versions.spain]: getPageData,
    [versions.germany]: getPageData,
    [versions.portugal]: getPageData,
    [versions.france]: getPageData,
    [versions.uk]: getPageData,
    [versions.us]: getPageData,
};
