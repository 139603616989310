import { CountryVersion } from "constants/enum";

export const getDesignPostfix = (version: CountryVersion) => {
    const countriesWithNewLayoutDesign = [
        CountryVersion.SPAIN,
        CountryVersion.ITALY,
        CountryVersion.PORTUGAL,
        CountryVersion.UK,
    ];
    const countriesWithoutPortugal = [
        CountryVersion.SPAIN,
        CountryVersion.ITALY,
        CountryVersion.UK,
        CountryVersion.FRANCE,
        CountryVersion.GERMANY,
    ];

    const countries = [...countriesWithoutPortugal, CountryVersion.PORTUGAL];
    const hasNewLayoutDesign = countriesWithNewLayoutDesign.includes(version);
    const newDesignPostfix = countries.includes(version) ? "_new" : "";
    const newDesignPostfixWithoutPT = countriesWithoutPortugal.includes(version)
        ? "_new"
        : "";

    return { hasNewLayoutDesign, newDesignPostfix, newDesignPostfixWithoutPT };
};
