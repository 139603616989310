import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import { PageContentProps } from "content/content.types";
import { pathConfig } from "navigation/pathConfig";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const content = defaultContent(t, { version });

    return mergeDeepRight(content, {
        headerBanner: {
            title: t("main:headTitle"),
            description: "",
            image: {
                defaultSrc: `/images/new/main/us/hero-desktop.jpg`,
                images: [
                    {
                        webp: `/images/new/main/us/hero-mobile.webp`,
                        jpeg: `/images/new/main/us/hero-mobile.jpg`,
                    },
                    {
                        webp: `/images/new/main/us/hero-desktop.webp`,
                        jpeg: `/images/new/main/us/hero-desktop.jpg`,
                    },
                ],
            },
        },
        checkUp: {
            title: t("main:checkUpTitle"),
            list: [
                t("main:checkUpListItem1"),
                t("main:checkUpListItem2"),
                t("main:checkUpListItem3"),
                t("main:checkUpListItem4"),
            ],
            image: {
                images: [
                    {
                        webp: "/images/new/main/us/check-up-mobile.webp",
                        jpeg: "/images/new/main/us/check-up-mobile.jpg",
                    },
                    {
                        webp: "/images/new/main/us/check-up-desktop.webp",
                        jpeg: "/images/new/main/us/check-up-desktop.jpg",
                    },
                ],
            },
            button: {
                name: t("main:checkUpBookButton"),
                link: pathConfig.universalForm[version],
            },
        },
        features: {
            list: [
                {
                    image: {
                        images: [
                            {
                                webp: `/images/new/main/us/step1-mobile.webp`,
                                jpeg: `/images/new/main/us/step1-mobile.jpg`,
                            },
                            {
                                webp: `/images/new/main/us/step1-desktop.webp`,
                                jpeg: `/images/new/main/us/step1-desktop.jpg`,
                                media: "(min-width: 1024px)",
                            },
                        ],
                    },
                    text: t("main:informationBlocksListItemText1"),
                },
                {
                    image: {
                        images: [
                            {
                                webp: `/images/new/main/us/step2-mobile.webp`,
                                jpeg: `/images/new/main/us/step2-mobile.jpg`,
                            },
                            {
                                webp: `/images/new/main/us/step2-desktop.webp`,
                                jpeg: `/images/new/main/us/step2-desktop.jpg`,
                            },
                        ],
                    },
                    text: t("main:informationBlocksListItemText2"),
                },
                {
                    image: {
                        images: [
                            {
                                webp: "/images/new/main/us/step3-mobile-new.webp",
                                jpeg: "/images/new/main/us/step3-mobile-new.jpg",
                            },
                            {
                                webp: "/images/new/main/us/step3-desktop-new.webp",
                                jpeg: "/images/new/main/us/step3-desktop-new.jpg",
                            },
                        ],
                    },
                    text: t("main:informationBlocksListItemText3"),
                },
            ],
        },
        ourDoctorsUs: {
            badgeUrl: "/images/new/main/member-of-logo.png",
            title: t("main:ourDoctorsTitle"),
            image: {
                images: [
                    {
                        webp: `/images/new/main/us/doctors-mobile.webp`,
                        jpeg: `/images/new/main/us/doctors-mobile.jpg`,
                    },
                    {
                        webp: `/images/new/main/us/doctors-desktop.webp`,
                        jpeg: `/images/new/main/us/doctors-desktop.jpg`,
                        media: "(min-width: 1024px)",
                    },
                ],
            },
            button: {
                text: t("main:ourDoctorsActionText"),
                link: pathConfig.universalForm[version],
            },
        },
        steps: {
            list: [
                {
                    image: {
                        images: [
                            {
                                webp: "/images/new/main/us/step21-mobile.webp",
                                jpeg: "/images/new/main/us/step21-mobile.jpg",
                            },
                            {
                                webp: "/images/new/main/us/step21-desktop.webp",
                                jpeg: "/images/new/main/us/step21-desktop.jpg",
                            },
                        ],
                    },
                    text: t("main:stepsListItemText1"),
                },
                {
                    image: {
                        images: [
                            {
                                webp: "/images/new/main/us/step22-mobile.webp",
                                jpeg: "/images/new/main/us/step22-mobile.jpg",
                            },
                            {
                                webp: "/images/new/main/us/step22-desktop.webp",
                                jpeg: "/images/new/main/us/step22-desktop.jpg",
                            },
                        ],
                    },
                    text: t("main:stepsListItemText2"),
                },
                {
                    image: {
                        images: [
                            {
                                webp: `/images/new/main/us/step23-mobile.webp`,
                                jpeg: `/images/new/main/us/step23-mobile.jpg`,
                            },
                            {
                                webp: `/images/new/main/us/step23-desktop.webp`,
                                jpeg: `/images/new/main/us/step23-desktop.jpg`,
                            },
                        ],
                    },
                    text: t("main:stepsListItemText3"),
                },
            ],
        },
        advanced: {
            image: {
                images: [
                    {
                        jpeg: "/images/new/main/advanced/doctor-and-client-mobile.jpg",
                        webp: "/images/new/main/advanced/doctor-and-client-mobile.webp",
                    },
                    {
                        jpeg: "/images/new/main/advanced/doctor-and-client-desktop.jpg",
                        webp: "/images/new/main/advanced/doctor-and-client-desktop.webp",
                    },
                ],
                defaultSrc:
                    "/images/new/main/advanced/doctor-and-client-mobile.jpg",
            },
            title: t("main:advancedBlockTitle"),
            titleLabel: t("main:advancedBlockLabelText"),
            description: t("main:advancedBlockDescription"),
            button: {
                link: pathConfig.advanced[version],
                name: t("main:advancedBlockButtonText"),
            },
        },
    });
};

export default pageContent;
