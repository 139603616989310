import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

export default (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        image: {
            mobile: {
                jpeg: "/images/referral/referral-mobile_100.jpg",
                webp: "/images/referral/referral-mobile_100.webp",
            },
            desktop: {
                jpeg: "/images/referral/referral-desktop_100.jpg",
                webp: "/images/referral/referral-desktop_100.webp",
            },
        },
    });
