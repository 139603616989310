import { Translate } from "next-translate";

import { CountryVersion } from "constants/enum";

const italyHackPostfix = "IT";

const getService = (t: Translate, id: number, version: CountryVersion) => ({
    title: t(
        `associatedClinics:ourServicesBlockItemTitle${id}${
            id === 1 && version === CountryVersion.ITALY ? italyHackPostfix : ""
        }`
    ),
    image: {
        images: [
            {
                webp: `/images/new/associatedClinics/our-services-${id}-desktop.webp`,
                jpeg: `/images/new/associatedClinics/our-services-${id}-desktop.jpg`,
            },
        ],
    },
    links: [
        {
            text: t(`associatedClinics:ourServicesBlockItem${id}Link1`),
            id: `ourServicesBlockItem${id}Link1`,
        },
        {
            text: t(`associatedClinics:ourServicesBlockItem${id}Link2`),
            id: `ourServicesBlockItem${id}Link2`,
        },
    ],
});

export const MAX_SERVICES = 3;

export const getServicesContent = (
    t: Translate,
    maxServices: number,
    version: CountryVersion = CountryVersion.SPAIN
) =>
    new Array(maxServices)
        .fill(null)
        .map((_, index) => getService(t, index + 1, version));
