import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import defaultContent from "./spain";

const pageContent = (t: Translate) =>
    mergeDeepRight(defaultContent(t), {
        welcome: {
            image: {
                mobile: {
                    webp: "/images/new/prices/us/header-mobile.webp",
                    jpeg: "/images/new/prices/us/header-mobile.jpg",
                },
                desktop: {
                    webp: "/images/new/prices/us/header-desktop.webp",
                    jpeg: "/images/new/prices/us/header-desktop.jpg",
                    media: "(min-width: 1024px)",
                },
                rounded: true,
            },
        },
        informationBlock: {
            image: {
                mobile: {
                    webp: "/images/new/prices/us/office-mobile.webp",
                    jpeg: "/images/new/prices/us/office-mobile.jpg",
                },
                desktop: {
                    webp: "/images/new/prices/us/office-desktop.webp",
                    jpeg: "/images/new/prices/us/office-desktop.jpg",
                    media: "(min-width: 1024px)",
                },
            },
        },
        warranty: {
            alignerImage: {
                mobile: {
                    jpeg: "/images/new/prices/us/doctor-mobile.jpg",
                    webp: "/images/new/prices/us/doctor-mobile.webp",
                },
                desktop: {
                    jpeg: "/images/new/prices/us/doctor-desktop.jpg",
                    webp: "/images/new/prices/us/doctor-desktop.webp",
                    media: "(min-width: 1024px)",
                },
            },
            list: [
                t("pricesPage:warrantyList1"),
                t("pricesPage:warrantyList2"),
                t("pricesPage:warrantyList4"),
                t("pricesPage:warrantyList5"),
            ],
        },
        faq: {
            title: t("faq:questionsTitle"),
            list: [
                {
                    question: t("faq:questionsListItemQuestionPrices1"),
                    answer: t("faq:questionsListItemAnswerPrices1"),
                },
                {
                    question: t("faq:questionsListItemQuestionPrices2"),
                    answer: t("faq:questionsListItemAnswerPrices2"),
                },
                {
                    question: t("faq:questionsListItemQuestionPrices3"),
                    answer: t("faq:questionsListItemAnswerPrices3"),
                },
                {
                    question: t("faq:questionsListItemQuestionPrices4"),
                    answer: t("faq:questionsListItemAnswerPrices4"),
                },
            ],
        },
    });

export default pageContent;
