import { Translate } from "next-translate";

import { versions } from "configs/versionsConfig";
import { PageContentProps } from "content/content.types";
import { pathConfig } from "navigation/pathConfig";

const getPageData = (t: Translate, { language, version }: PageContentProps) => {
    return {
        title: t("blog:pageTitle"),
        readMoreText: t("blog:readMoreLink"),
        readTimeText: t("blog:timeForReading"),
        sideBanner: {
            button: {
                name: t("blog:buttonText"),
                link: pathConfig.universalForm[version],
            },
        },
        language,
        hideRegionSelector: true,
    };
};

export default {
    [versions.italy]: getPageData,
    [versions.spain]: getPageData,
    [versions.germany]: getPageData,
    [versions.portugal]: getPageData,
    [versions.france]: getPageData,
    [versions.uk]: getPageData,
    [versions.us]: getPageData,
};
