import { Translate } from "next-translate";

import contacts from "configs/contacts.json";
import { ContactTypes, SocialTypes } from "constants/enum";
import { getWALink } from "utils/getWALink";
import { ThankYouLayoutContent } from "layouts/pages/ThanksLayout/ThanksLayout.types";
import { pathConfig } from "navigation/pathConfig";
import { PageContentProps } from "content/content.types";

const pageContent: any = (
    t: Translate,
    { version }: PageContentProps
): ThankYouLayoutContent => ({
    background: {
        video: "/video/thanks.mp4",
    },
    imageBanner: {
        default: {
            mobile: {
                webp: "/images/new/thanks/clinic-default.webp",
                jpeg: "/images/new/thanks/clinic-default.jpg",
            },
            alt: "clinic hall",
        },
        barcelona: {
            mobile: {
                webp: "/images/new/thanks/clinic-barcelona.webp",
                jpeg: "/images/new/thanks/clinic-barcelona.jpg",
            },
            alt: "clinic hall",
        },
        madrid: {
            mobile: {
                webp: "/images/new/thanks/clinic-madrid.webp",
                jpeg: "/images/new/thanks/clinic-madrid.jpg",
            },
            alt: "clinic hall",
        },
        bilbao: {
            mobile: {
                webp: "/images/new/thanks/clinic-bilbao.webp",
                jpeg: "/images/new/thanks/clinic-bilbao.jpg",
            },
            alt: "clinic hall",
        },
        malaga: {
            mobile: {
                webp: "/images/new/thanks/clinic-malaga.webp",
                jpeg: "/images/new/thanks/clinic-malaga.jpg",
            },
        },
        valencia: {
            mobile: {
                webp: "/images/new/thanks/clinic-valencia.webp",
                jpeg: "/images/new/thanks/clinic-valencia.jpg",
            },
            alt: "clinic hall",
        },
        granada: {
            mobile: {
                webp: "/images/new/thanks/clinic-granada.webp",
                jpeg: "/images/new/thanks/clinic-granada.jpg",
            },
            alt: "clinic hall",
        },
        pamplona: {
            mobile: {
                webp: "/images/new/thanks/clinic-pamplona.webp",
                jpeg: "/images/new/thanks/clinic-pamplona.jpg",
            },
            alt: "clinic hall",
        },
        sevilla: {
            mobile: {
                webp: "/images/new/thanks/clinic-sevilla.webp",
                jpeg: "/images/new/thanks/clinic-sevilla.jpg",
            },
            alt: "clinic hall",
        },
        mallorca: {
            mobile: {
                webp: "/images/new/thanks/clinic-mallorca.webp",
                jpeg: "/images/new/thanks/clinic-mallorca.jpg",
            },
            alt: "clinic hall",
        },
        "san-sebastian": {
            mobile: {
                webp: "/images/new/thanks/clinic-san-sebastian.webp",
                jpeg: "/images/new/thanks/clinic-san-sebastian.jpg",
            },
            alt: "clinic hall",
        },
    },
    logoLink: pathConfig.main[version],
    title: t("thanks:title"),
    congrats: t("thanks:congrats"),
    subtitle: t("thanks:subtitle"),
    description: {
        title: t("thanks:descriptionTitle"),
        list: [
            t("thanks:descriptionList1"),
            t("thanks:descriptionList2"),
            t("thanks:descriptionList3"),
            t("thanks:descriptionList4"),
        ],
    },
    dateLabel: t("thanks:dateLabel"),
    timeLabel: t("thanks:timeLabel"),
    addressLabel: t("thanks:addressLabel"),
    mapLabel: t("thanks:mapLabel"),
    socialMedia: {
        title: t("thanks:socialMediaTitle"),
        list: [
            {
                name: contacts.facebook[version].name,
                icon: SocialTypes.FB,
                link: contacts.facebook[version].link,
            },
            {
                name: contacts.instagram[version].username,
                icon: SocialTypes.INSTAGRAM,
                link: contacts.instagram[version].link,
            },
            {
                name: contacts.tiktok[version].name,
                icon: SocialTypes.TIKTOK,
                link: contacts.tiktok[version].link,
            },
        ],
    },
    contacts: {
        title: t("thanks:contactsTitle"),
        list: [
            {
                id: ContactTypes.PHONE,
                name: contacts.phone[version].public,
                icon: ContactTypes.PHONE,
                link: `tel:${contacts.phone[version].link}`,
            },
            {
                id: SocialTypes.WA,
                name: "WhatsApp",
                icon: SocialTypes.WA,
                link: getWALink(
                    contacts.whatsapp[version].link,
                    t("global:whatsappMessage")
                ),
            },
            {
                id: ContactTypes.EMAIL,
                name: contacts.email[version].link,
                icon: ContactTypes.EMAIL,
                link: `mailto:${contacts.email[version].link}`,
            },
        ],
    },
    titleOfContactsBlock: t("thanks:titleOfContactsBlock"),
});

export default pageContent;
