import { versions } from "configs/versionsConfig";

import france from "./france";
import germany from "./germany";
import italy from "./italy";
import portugal from "./portugal";
import spain from "./spain";
import uk from "./uk";
import us from "./us";

export default {
    [versions.france]: france,
    [versions.germany]: germany,
    [versions.italy]: italy,
    [versions.portugal]: portugal,
    [versions.spain]: spain,
    [versions.uk]: uk,
    [versions.us]: us,
};

export type LocationsLayoutType =
    | typeof spain
    | typeof italy
    | typeof portugal
    | typeof france
    | typeof germany
    | typeof uk;
