import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import defaultContent from "./spain";

const pageContent = (t: Translate) =>
    mergeDeepRight(defaultContent(t), {
        slides: {
            title: "Dai un'occhiata alle nostre cliniche...",
            images: {
                milan: [
                    {
                        mobile: {
                            webp: "/images/clinics-slides/milan/milan-clinic-mobile_1.webp",
                            jpeg: "/images/clinics-slides/milan/milan-clinic-mobile_1.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/milan/milan-clinic-desktop_1.webp",
                            jpeg: "/images/clinics-slides/milan/milan-clinic-desktop_1.jpg",
                        },
                    },
                    {
                        mobile: {
                            webp: "/images/clinics-slides/milan/milan-clinic-mobile_2.webp",
                            jpeg: "/images/clinics-slides/milan/milan-clinic-mobile_2.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/milan/milan-clinic-desktop_2.webp",
                            jpeg: "/images/clinics-slides/milan/milan-clinic-desktop_2.jpg",
                        },
                    },
                    {
                        mobile: {
                            webp: "/images/clinics-slides/milan/milan-clinic-mobile_3.webp",
                            jpeg: "/images/clinics-slides/milan/milan-clinic-mobile_3.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/milan/milan-clinic-desktop_3.webp",
                            jpeg: "/images/clinics-slides/milan/milan-clinic-desktop_3.jpg",
                        },
                    },
                    {
                        mobile: {
                            webp: "/images/clinics-slides/milan/milan-clinic-mobile_4.webp",
                            jpeg: "/images/clinics-slides/milan/milan-clinic-mobile_4.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/milan/milan-clinic-desktop_4.webp",
                            jpeg: "/images/clinics-slides/milan/milan-clinic-desktop_4.jpg",
                        },
                    },
                ],
            },
        },
        tabs: [
            {
                value: "milan",
                title: "Milano",
            },
        ],
    });

export default pageContent;
