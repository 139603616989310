import { Translate } from "next-translate";

import { pathConfig } from "configs/pathConfig";
import contacts from "configs/contacts.json";
import { ContactTypes, SocialTypes } from "constants/enum";
import { PageContentProps } from "content/content.types";

import { ShowUpPageContent } from "./content.types";

const pageContent = (
    t: Translate,
    { version }: PageContentProps
): ShowUpPageContent => ({
    mainBanner: {
        titlePrefix: t("firstVisit:titlePrefix"),
        title: t("firstVisit:title"),
        titleConfirm: t("firstVisit:titleConfirm"),
        listSubtitle: t("firstVisit:listSubtitle"),
        listTitle: t("firstVisit:listTitle"),
        listDescription: t("firstVisit:listDescription"),
        listActiveBookings: t("firstVisit:listActiveBookings"),
        confirmListButtonText: t("firstVisit:confirmListButtonText"),
        linkVideo: "/video/first-visit/main.mp4",
        linkGif: "/images/first-visit/promo.gif",
        ticker: t("firstVisit:ticker"),
        dateLabel: t("firstVisit:dateLabel"),
        timeLabel: t("firstVisit:timeLabel"),
        addressLabel: t("firstVisit:addressLabel"),
        mapLabel: t("firstVisit:mapLabel"),
        confirmButtonText: t("firstVisit:confirmButtonText"),
        cancelAllButtonText: t("firstVisit:cancelAllButtonText"),
        cancelAllModalContent: {
            label: t("firstVisit:cancelAllModalContentLabel"),
            buttonConfirmationText: t(
                "firstVisit:cancelAllModalContentButtonConfirmationText"
            ),
            buttonCancelText: t(
                "firstVisit:cancelAllModalContentButtonCancelText"
            ),
        },
        cancelAllSuccessModalContent: {
            label: t("firstVisit:cancelAllSuccessModalContentLabel"),
            bookNewVisitButtonText: t(
                "firstVisit:cancelAllSuccessModalContentBookNewVisitButtonText"
            ),
            contacts: [
                {
                    name: contacts.phone[version].public,
                    link: `tel:${contacts.phone[version].link}`,
                    icon: ContactTypes.PHONE,
                },
                {
                    name: "WhatsApp",
                    link: contacts.whatsapp[version].link,
                    icon: SocialTypes.WA,
                },
                {
                    name: t("global:email"),
                    link: `mailto:${contacts.email[version].link}`,
                    icon: ContactTypes.EMAIL,
                },
            ],
            buttonLink: pathConfig.universalSelect[version],
        },
    },
    advantages: {
        title: t("firstVisit:avantagesTitle"),
        list: [
            {
                title: t("firstVisit:avantagesListTitle1"),
                description: t("firstVisit:avantagesListDescription1"),
                image: {
                    mobile: {
                        jpeg: "/images/first-visit/first-visit-1-mobile.jpg",
                        webp: "/images/first-visit/first-visit-1-mobile.webp",
                    },
                    desktop: {
                        jpeg: "/images/first-visit/first-visit-1-desktop.jpg",
                        webp: "/images/first-visit/first-visit-1-desktop.webp",
                    },
                },
            },
            {
                title: t("firstVisit:avantagesListTitle2"),
                description: t("firstVisit:avantagesListDescription2"),
                image: {
                    mobile: {
                        jpeg: "/images/first-visit/first-visit-2-mobile.jpg",
                        webp: "/images/first-visit/first-visit-2-mobile.webp",
                    },
                    desktop: {
                        jpeg: "/images/first-visit/first-visit-2-desktop.jpg",
                        webp: "/images/first-visit/first-visit-2-desktop.webp",
                    },
                },
            },
            {
                title: t("firstVisit:avantagesListTitle3"),
                description: t("firstVisit:avantagesListDescription3"),
                image: {
                    mobile: {
                        jpeg: "/images/first-visit/first-visit-3-mobile.jpg",
                        webp: "/images/first-visit/first-visit-3-mobile.webp",
                    },
                    desktop: {
                        jpeg: "/images/first-visit/first-visit-3-desktop.jpg",
                        webp: "/images/first-visit/first-visit-3-desktop.webp",
                    },
                },
            },
            {
                title: t("firstVisit:avantagesListTitle4"),
                description: t("firstVisit:avantagesListDescription4"),
                image: {
                    mobile: {
                        jpeg: "/images/first-visit/first-visit-4-mobile.jpg",
                        webp: "/images/first-visit/first-visit-4-mobile.webp",
                    },
                    desktop: {
                        jpeg: "/images/first-visit/first-visit-4-desktop.jpg",
                        webp: "/images/first-visit/first-visit-4-desktop.webp",
                    },
                },
            },
        ],
    },
    slides: {
        title: t("firstVisit:slidesTitle"),
        images: [
            {
                mobile: {
                    webp: "/images/clinics/barcelona/slides/photo-1.webp",
                    jpeg: "/images/clinics/barcelona/slides/photo-1.jpg",
                },
            },
            {
                mobile: {
                    webp: "/images/clinics/barcelona/slides/photo-2.webp",
                    jpeg: "/images/clinics/barcelona/slides/photo-2.jpg",
                },
            },
            {
                mobile: {
                    webp: "/images/clinics/barcelona/slides/photo-3.webp",
                    jpeg: "/images/clinics/barcelona/slides/photo-3.jpg",
                },
            },
            {
                mobile: {
                    webp: "/images/clinics/barcelona/slides/photo-4.webp",
                    jpeg: "/images/clinics/barcelona/slides/photo-4.jpg",
                },
            },
        ],
    },
    medicsEquipment: {
        title: t("firstVisit:medicsEquipmentTitle"),
        description: t("firstVisit:medicsEquipmentDescription"),
        images: [
            {
                mobile: {
                    png: "/images/first-visit/first-visit-medics-equipment-mobile-1.png",
                },
                desktop: {
                    png: "/images/first-visit/first-visit-medics-equipment-desktop-1.png",
                },
            },
            {
                mobile: {
                    png: "/images/first-visit/first-visit-medics-equipment-mobile-2.png",
                },
                desktop: {
                    png: "/images/first-visit/first-visit-medics-equipment-desktop-2.png",
                },
            },
        ],
    },
});

export default pageContent;
