import { FC, useState } from "react";
import {
    Box,
    Text,
    Flex,
    Button,
    Icon,
    InfoOutlineIcon,
    Link,
} from "@smile2impress/components";
import useTranslation from "next-translate/useTranslation";

import prices from "configs/prices.json";
import { getCurrencySymbolPosition } from "utils/prices";
import {
    CurrencySymbolPosition,
    ThemeConfig,
    VersionToUniversalForm,
} from "constants/enum";
import { useLocationContext } from "context/locationContext";
import { sanitizeHtml } from "utils/sanitizeHtml";

import { PriceContentBlockProps } from "./contentBlocks.types";
import blockContent from "../../content";

export const PriceBlock: FC<PriceContentBlockProps> = ({ content }) => {
    const { t } = useTranslation();
    const { version } = useLocationContext();
    const versionContent = blockContent[version](t);
    const { priceBlockPriceTitle, priceBlockDurationTitle, priceBlockCTA } =
        versionContent;
    const { duration, price, financationInfo } = content;
    const [isInfoShown, setIsInfoShown] = useState(false);
    const currency = prices.currency[version];
    const showInfo = () => {
        setIsInfoShown(!isInfoShown);
    };
    const isCurrencyAfter =
        getCurrencySymbolPosition(version, currency) ===
        CurrencySymbolPosition.AFTER;

    return (
        <Box>
            <Text textStyle="body" color="text.secondary">
                {priceBlockDurationTitle}
            </Text>
            <Text textStyle="display1" color="base.accent" mb={[5, 5]}>
                {duration}
            </Text>
            <Text textStyle="body" color="text.secondary">
                {priceBlockPriceTitle}
            </Text>
            <Box mb={[3, 5]}>
                <Flex direction="row" justify="space-between" align="center">
                    <Flex
                        flexDirection={isCurrencyAfter ? "row" : "row-reverse"}
                    >
                        <Text textStyle="display1" color="base.accent">
                            {price}
                        </Text>
                        <Text textStyle="title3" color="text.accent">
                            {currency}
                        </Text>
                    </Flex>
                    {financationInfo && (
                        <Box>
                            <Button
                                variant="ghost"
                                size="xs"
                                ml={2}
                                onClick={showInfo}
                            >
                                <Icon
                                    as={InfoOutlineIcon}
                                    color="text.secondary"
                                />
                            </Button>
                        </Box>
                    )}
                </Flex>
            </Box>

            {isInfoShown && financationInfo && (
                <Text
                    textStyle="caption"
                    color="text.secondary"
                    mt={1}
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(financationInfo),
                    }}
                ></Text>
            )}
            <Box mt={[3, 5]}>
                <Button
                    width={["100%", "auto"]}
                    as={Link}
                    href={VersionToUniversalForm[version]}
                    size="md"
                    variant={ThemeConfig.appointmentButtonColor}
                >
                    {priceBlockCTA}
                </Button>
            </Box>
        </Box>
    );
};
