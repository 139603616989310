import { CountryVersion } from "constants/enum";

import france from "./france";
import uk from "./uk";
import italy from "./italy";
import portugal from "./portugal";
import spain from "./spain";

export default {
    [CountryVersion.FRANCE]: france,
    [CountryVersion.UK]: uk,
    [CountryVersion.ITALY]: italy,
    [CountryVersion.PORTUGAL]: portugal,
    [CountryVersion.SPAIN]: spain,
};
