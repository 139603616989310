import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";
import {
    TmpImageTextAndPriceAreaTypes,
    TmpImageTextAndPriceBlocksTypes,
} from "components/templates/TmpImageTextAndPrice";

import defaultContent, { generateListKeys } from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const content = defaultContent(t, { version });

    return mergeDeepRight(content, {
        headerBanner: {
            image: {
                defaultSrc: `/images/new/prices/advanced/germany/advanced-header-mobile.jpg`,
                images: [
                    {
                        jpeg: `/images/new/prices/advanced/germany/advanced-header-mobile.jpg`,
                        webp: `/images/new/prices/advanced/germany/advanced-header-mobile.webp`,
                    },
                    {
                        jpeg: `/images/new/prices/advanced/germany/advanced-header-desktop.jpg`,
                        webp: `/images/new/prices/advanced/germany/advanced-header-desktop.webp`,
                    },
                ],
                rounded: true,
            },
        },
        universalBlock: [
            ...content.universalBlock.splice(0, 3),
            [
                {
                    type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                    leftContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.IMAGE,
                            content: {
                                image: {
                                    images: [
                                        {
                                            jpeg: "/images/new/prices/advanced/germany/advanced-clinic-mobile.jpg",
                                            webp: "/images/new/prices/advanced/germany/advanced-clinic-mobile.webp",
                                        },
                                        {
                                            jpeg: "/images/new/prices/advanced/germany/advanced-clinic-desktop.jpg",
                                            webp: "/images/new/prices/advanced/germany/advanced-clinic-desktop.webp",
                                        },
                                    ],
                                    defaultSrc:
                                        "/images/new/prices/advanced/germany/advanced-clinic-desktop.jpg",
                                },
                            },
                        },
                    ],
                    rightContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.LIST,
                            content: {
                                list: generateListKeys(t, 4, 1),
                                title: t("advanced:universalBlock4List1Title"),
                            },
                        },
                    ],
                },
            ],
        ],
        appointment: {
            image: {
                images: [
                    {
                        jpeg: "/images/new/main/germany/form-mobile.jpg",
                        webp: "/images/new/main/germany/form-mobile.webp",
                    },
                    {
                        jpeg: "/images/new/main/germany/form-desktop.jpg",
                        webp: "/images/new/main/germany/form-desktop.webp",
                    },
                ],
                defaultSrc: "/images/new/main/germany/form-desktop.jpg",
            },
        },
    });
};

export default pageContent;
