import { Translate } from "next-translate";

import { CountryVersion } from "constants/enum";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: { version: CountryVersion }) =>
    defaultContent(t, { version });

export default pageContent;
