import { FC } from "react";
import { Box, Flex } from "@smile2impress/components";

import { TwoColumnsAreaProps } from "./areas.types";
import { renderContentBlock } from "../contentBlocks";

export const TwoColumnsArea: FC<TwoColumnsAreaProps> = ({
    leftContent,
    rightContent,
    isFirst,
    isLast,
}) => {
    return (
        <Box>
            <Flex direction={["column", "row"]}>
                <Flex
                    direction={["column"]}
                    width={["100%", "50%"]}
                    mr={[0, 10]}
                    mb={[3, 0]}
                >
                    {leftContent.map((block, i, arr) => (
                        <Box key={i} mb={[3, 5]} _last={{ mb: 0 }}>
                            {renderContentBlock({
                                block,
                                stretchTop: [
                                    isFirst && i === 0,
                                    isFirst && i === 0,
                                ],
                                stretchBottom: [
                                    false,
                                    isLast && i === arr.length - 1,
                                ],
                            })}
                        </Box>
                    ))}
                </Flex>
                <Flex
                    direction={["column"]}
                    width={["100%", "50%"]}
                    ml={[0, 10]}
                >
                    {rightContent.map((block, i, arr) => (
                        <Box key={i} mb={[3, 5]} _last={{ mb: 0 }}>
                            {renderContentBlock({
                                block,
                                stretchTop: [
                                    isFirst && i === 0,
                                    isFirst && i === 0,
                                ],
                                stretchBottom: [
                                    isLast && i === arr.length - 1,
                                    isLast && i === arr.length - 1,
                                ],
                            })}
                        </Box>
                    ))}
                </Flex>
            </Flex>
        </Box>
    );
};
