import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import { CountryVersion } from "constants/enum";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: { version: CountryVersion }) =>
    mergeDeepRight(defaultContent(t, { version }), {
        publicationsBlock: null,
    });

export default pageContent;
