import { versions } from "configs/versionsConfig";

import france from "./france";
import spain from "./spain";
import portugal from "./portugal";
import italy from "./italy";
import uk from "./uk";
import germany from "./germany";
import ukraine from "./ukraine";

export default {
    [versions.france]: france,
    [versions.spain]: spain,
    [versions.portugal]: portugal,
    [versions.italy]: italy,
    [versions.uk]: uk,
    [versions.germany]: germany,
    [versions.ukraine]: ukraine,
};
