import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import defaultContent from "./spain";

const pageContent = (t: Translate) => {
    const content = defaultContent(t);

    return mergeDeepRight(content, {
        prices: {
            advantages: null,
        },
    });
};

export default pageContent;
