import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import { PageContentProps } from "content/content.types";
import prices from "configs/prices.json";
import { priceFormatter } from "utils/priceFormatter";
import { pathConfig } from "navigation/pathConfig";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const content = defaultContent(t, { version });

    const lightMonthBasePrice =
        prices.lightPrice.once.baseWD[version] ||
        prices.lightPrice.once.base[version];
    const regularMonthBasePrice =
        prices.regularPrice.once.baseWD[version] ||
        prices.regularPrice.once.base[version];

    return mergeDeepRight(content, {
        headerBanner: {
            title: t("main:headTitle"),
            description: t("main:headDescription", {
                amount: priceFormatter(
                    lightMonthBasePrice || regularMonthBasePrice,
                    prices.currency[version],
                    version
                ),
            }),
        },
        welcome: {
            pricePerMonth: null,
        },
        retainersPromo: {
            title: t("main:retainersPromoTitle"),
            button: {
                name: t("main:retainersPromoCTA"),
                link: pathConfig.retainersSubscription[version],
            },
            ctaComment: "",
            image: {
                images: [
                    {
                        webp: "/images/new/main/retainers-promo/retainers-promo-mobile.webp",
                        jpeg: "/images/new/main/retainers-promo/retainers-promo-mobile.jpg",
                    },
                    {
                        webp: "/images/new/main/retainers-promo/retainers-promo-desktop.webp",
                        jpeg: "/images/new/main/retainers-promo/retainers-promo-desktop.jpg",
                    },
                ],
            },
        },
    });
};

export default pageContent;
