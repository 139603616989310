import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import prices from "configs/prices.json";
import { priceFormatter } from "utils/priceFormatter";
import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        priceBlock: {
            label: t("thanks:priceBlockLabel"),
            value: priceFormatter(
                prices.FVPrice[version],
                prices.currency[version],
                version
            ),
        },
    });

export default pageContent;
