import { Translate } from "next-translate";

import { CountryVersion } from "constants/enum";
import { pathConfig } from "navigation/pathConfig";
import { PageContentProps } from "content/content.types";
import { getDesignPostfix } from "utils/getDesignPostfix";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const hasSpecificImages =
        version === CountryVersion.GERMANY || version === CountryVersion.US;
    const countryPostfix = hasSpecificImages ? `${version}/` : "";
    const { newDesignPostfix } = getDesignPostfix(version);

    return {
        welcome: {
            title: t("howItWorks:bannerTitle"),
            image: {
                defaultSrc: `/images/new/como-funciona/main-mobile.jpg`,
                images: [
                    {
                        jpeg: `/images/new/como-funciona/main-mobile.jpg`,
                        webp: `/images/new/como-funciona/main-mobile.webp`,
                    },
                    {
                        jpeg: `/images/new/como-funciona/main-desktop.jpg`,
                        webp: `/images/new/como-funciona/main-desktop.webp`,
                        media: "(min-width: 1024px)",
                    },
                ],
                rounded: true,
            },
        },
        stages: {
            list: [
                {
                    title: t("howItWorks:stages1Title"),
                    list: [
                        {
                            image: {
                                images: [
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_1-1_mobile.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_1-1_mobile.webp`,
                                    },
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_1-1.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_1-1.webp`,
                                        media: "(min-width: 1024px)",
                                    },
                                ],
                            },
                            title: t("howItWorks:stages1Step1Title"),
                            text: t("howItWorks:stages1Step1Description"),
                        },
                        {
                            image: {
                                images: [
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_1-2_mobile.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_1-2_mobile.webp`,
                                    },
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_1-2.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_1-2.webp`,
                                        media: "(min-width: 1024px)",
                                    },
                                ],
                            },
                            title: t("howItWorks:stages1Step2Title"),
                            text: t("howItWorks:stages1Step2Description"),
                        },
                        {
                            image: {
                                images: [
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_1-3_mobile.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_1-3_mobile.webp`,
                                    },
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_1-3.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_1-3.webp`,
                                        media: "(min-width: 1024px)",
                                    },
                                ],
                            },
                            title: t("howItWorks:stages1Step3Title"),
                            text: t("howItWorks:stages1Step3Description"),
                        },
                    ],
                    button: {
                        text: t("howItWorks:stages1Button"),
                        link: pathConfig.universalForm[version],
                    },
                },
                {
                    title: t("howItWorks:stages2Title"),
                    list: [
                        {
                            image: {
                                images: [
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_2-1_mobile.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_2-1_mobile.webp`,
                                    },
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_2-1.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_2-1.webp`,
                                        media: "(min-width: 1024px)",
                                    },
                                ],
                            },
                            title: t("howItWorks:stages2Step1Title"),
                            description: t(
                                "howItWorks:stages2Step1Description"
                            ),
                        },
                        {
                            image: {
                                images: [
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_2-2_mobile.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_2-2_mobile.webp`,
                                    },
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_2-2.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_2-2.webp`,
                                        media: "(min-width: 1024px)",
                                    },
                                ],
                            },
                            title: t("howItWorks:stages2Step2Title"),
                            description: t(
                                "howItWorks:stages2Step2Description"
                            ),
                        },
                        {
                            image: {
                                images: [
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_2-3_mobile.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_2-3_mobile.webp`,
                                    },
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_2-3.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_2-3.webp`,
                                        media: "(min-width: 1024px)",
                                    },
                                ],
                            },
                            title: t("howItWorks:stages2Step3Title"),
                            description: t(
                                "howItWorks:stages2Step3Description"
                            ),
                        },
                    ],
                    button: {
                        text: t("howItWorks:stages2Button"),
                        link: pathConfig.universalForm[version],
                    },
                },
                {
                    title: t("howItWorks:stages3Title"),
                    list: [
                        {
                            image: {
                                images: [
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_3-1.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_3-1.webp`,
                                    },
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_3-1.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_3-1.webp`,
                                        media: "(min-width: 1024px)",
                                    },
                                ],
                            },
                            title: t("howItWorks:stages3Step1Title"),
                            description: t(
                                "howItWorks:stages3Step1Description"
                            ),
                        },
                        {
                            image: {
                                images: [
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_3-2${newDesignPostfix}.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_3-2${newDesignPostfix}.webp`,
                                    },
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_3-2${newDesignPostfix}.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_3-2${newDesignPostfix}.webp`,
                                        media: "(min-width: 1024px)",
                                    },
                                ],
                            },
                            title: t("howItWorks:stages3Step2Title"),
                            description: t(
                                "howItWorks:stages3Step2Description"
                            ),
                        },
                        {
                            image: {
                                images: [
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_3-3${newDesignPostfix}.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_3-3${newDesignPostfix}.webp`,
                                    },
                                    {
                                        jpeg: `/images/new/steps/${countryPostfix}stage_3-3${newDesignPostfix}.jpg`,
                                        webp: `/images/new/steps/${countryPostfix}stage_3-3${newDesignPostfix}.webp`,
                                        media: "(min-width: 1024px)",
                                    },
                                ],
                            },
                            title: t("howItWorks:stages3Step3Title"),
                            description: t(
                                "howItWorks:stages3Step3Description"
                            ),
                        },
                    ],
                    button: {
                        text: t("howItWorks:stages3Button"),
                        link: pathConfig.universalForm[version],
                    },
                },
            ],
        },
        appointment: {
            title: t("global:bookAppointmentButton"),
            image: {
                images: [
                    {
                        jpeg: `/images/new/main/form-mobile.jpg`,
                        webp: `/images/new/main/form-mobile.webp`,
                    },
                    {
                        jpeg: `/images/new/main/form-desktop.jpg`,
                        webp: `/images/new/main/form-desktop.webp`,
                        media: "(min-width: 1024px)",
                    },
                ],
            },
            analyticIdZone: "how-it-works-page",
        },
        questions: {
            title: t("faq:Still have questions"),
            list: [
                {
                    question: t(
                        "faq:What's the difference between Impress's aligners and traditional braces"
                    ),
                    answer: t(
                        "faq:What's the difference between Impress's aligners and traditional braces answer"
                    ),
                },
                {
                    question: t("faq:Can I eat and drink with the aligners on"),
                    answer: t(
                        "faq:Can I eat and drink with the aligners on answer"
                    ),
                },
                {
                    question: t(
                        "faq:How often do I have to change my aligners"
                    ),
                    answer: t(
                        "faq:How often do I have to change my aligners answer"
                    ),
                },
                {
                    question: t("faq:How long is the treatment"),
                    answer: t("faq:How long is the treatment answer"),
                },
            ],
        },
    };
};

export default pageContent;
