import { Translate } from "next-translate";

import { pathConfig } from "configs/pathConfig";
import { PageContentProps } from "content/content.types";
import { ResultsContent } from "layouts/pages/ResultsLayout";
import { getDesignPostfix } from "utils/getDesignPostfix";

const pageContent = (
    t: Translate,
    { version }: PageContentProps
): ResultsContent => {
    const { newDesignPostfix } = getDesignPostfix(version);

    return {
        mainBanner: {
            title: t("results:mainBannerTitle"),
            action: {
                name: t("global:bookAppointmentButton"),
                link: pathConfig.universalForm[version],
            },
            imageDesktop: "/images/new/results/mainBanner/people-desktop.jpg",
            imageMobile: "/images/new/results/mainBanner/people-desktop.jpg",
        },
        ourResults: {
            title: t("ourResults:title"),
            reviews: [
                {
                    name: "Gemma",
                    subtitle: t("global:resultsSubtitle"),
                    treatmentPlan: "Impress Lite",
                    content: t("ourResults:reviewContent1"),
                    image: {
                        desktop: {
                            webp: "/images/new/ourResults/review_desktop_1.webp",
                            jpeg: "/images/new/ourResults/review_desktop_1.jpg",
                        },
                        mobile: {
                            webp: "/images/new/ourResults/review_mobile_1.webp",
                            jpeg: "/images/new/ourResults/review_mobile_1.jpg",
                        },
                    },
                },
                {
                    name: "Joaquim",
                    subtitle: t("global:resultsSubtitle"),
                    treatmentPlan: "Impress Lite",
                    content: t("ourResults:reviewContent2"),
                    image: {
                        desktop: {
                            webp: "/images/new/ourResults/review_desktop_2.webp",
                            jpeg: "/images/new/ourResults/review_desktop_2.jpg",
                        },
                        mobile: {
                            webp: "/images/new/ourResults/review_mobile_2.webp",
                            jpeg: "/images/new/ourResults/review_mobile_2.jpg",
                        },
                    },
                },
                {
                    name: "Neus",
                    subtitle: t("global:resultsSubtitle"),
                    treatmentPlan: "Impress Lite",
                    content: t("ourResults:reviewContent3"),
                    image: {
                        desktop: {
                            webp: "/images/new/ourResults/review_desktop_3.webp",
                            jpeg: "/images/new/ourResults/review_desktop_3.jpg",
                        },
                        mobile: {
                            webp: "/images/new/ourResults/review_mobile_3.webp",
                            jpeg: "/images/new/ourResults/review_mobile_3.jpg",
                        },
                    },
                },
            ],
        },
        hygiene: {
            image: {
                mobile: {
                    webp: `/images/new/results/hygiene-mobile.webp`,
                    jpeg: `/images/new/results/hygiene-mobile.jpg`,
                },
                desktop: {
                    webp: `/images/new/results/hygiene-desktop.webp`,
                    jpeg: `/images/new/results/hygiene-desktop.jpg`,
                },
            },
            title: t("results:hygieneTitle"),
            subtitle: t("results:hygieneSubtitle"),
        },
        followUp: {
            title: t("results:followUpTitle"),
            subtitle: t("results:followUpSubtitle"),
            appointmentButton: {
                name: t("global:bookAppointmentButton"),
                link: pathConfig.universalForm[version],
            },
            image: {
                mobile: {
                    webp: `/images/new/results/followUp/banner-mobile${newDesignPostfix}.webp`,
                    jpeg: `/images/new/results/followUp/banner-mobile${newDesignPostfix}.jpg`,
                },
                desktop: {
                    webp: `/images/new/results/followUp/banner-desktop${newDesignPostfix}.webp`,
                    jpeg: `/images/new/results/followUp/banner-desktop${newDesignPostfix}.jpg`,
                },
            },
        },
    };
};

export default pageContent;
