import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import defaultContent from "./spain";

const pageContent = (t: Translate) =>
    mergeDeepRight(defaultContent(t), {
        welcome: {
            image: {
                desktop: {
                    jpeg: "/images/new/prices/doctor_de.jpg",
                    webp: "/images/new/prices/doctor_de.webp",
                    media: "(min-width: 1024px)",
                },
                mobile: {
                    jpeg: "/images/new/prices/doctor-mobile_de.jpg",
                    webp: "/images/new/prices/doctor-mobile_de.webp",
                },
            },
        },
        informationBlock: {
            image: {
                desktop: {
                    jpeg: "/images/new/prices/office-desktop_de.jpg",
                    webp: "/images/new/prices/office-desktop_de.webp",
                    media: "(min-width: 1024px)",
                },
                mobile: {
                    jpeg: "/images/new/prices/office-mobile_de.jpg",
                    webp: "/images/new/prices/office-mobile_de.webp",
                },
            },
        },
    });

export default pageContent;
