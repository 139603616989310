import { CountryVersion, CurrencyCode, ISOLanguagesCode } from "constants/enum";
import prices from "configs/prices.json";

const fixForUkraine = (value: string) =>
    value.replace("EUR", prices.currency.ukraine);

export const priceFormatter = (
    amount: number | string,
    currency: string,
    version?: CountryVersion
) => {
    const isoCountryCode = version
        ? ISOLanguagesCode[version]
        : CountryVersion.SPAIN;
    const isoCurrencyCode = (<any>CurrencyCode)[currency] ?? CurrencyCode["€"];

    const result = Number(amount).toLocaleString(isoCountryCode, {
        style: "currency",
        minimumFractionDigits: 0,
        currency: isoCurrencyCode,
        useGrouping: true,
    });

    return CountryVersion.UKRAINE ? fixForUkraine(result) : result;
};
