import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent: any = (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        mainBanner: {
            image: {
                desktop: {
                    webp: `/images/about/us/team-desktop.webp`,
                    jpeg: `/images/about/us/team-desktop.jpg`,
                    media: "(min-width: 1024px)",
                },
                mobile: {
                    webp: `/images/about/us/team-mobile.webp`,
                    jpeg: `/images/about/us/team-mobile.jpg`,
                },
                rounded: true,
            },
        },
        perfectSmile: {
            quote: null,
            image: {
                desktop: {
                    webp: "/images/about/us/perfect-smile-desktop.webp",
                    jpeg: "/images/about/us/perfect-smile-desktop.jpeg",
                },
            },
        },
        likeNeverBefore: {
            image: {
                desktop: {
                    webp: `/images/about/us/show-aligner-desktop.webp`,
                    jpeg: `/images/about/us/show-aligner-desktop.jpg`,
                    media: "(min-width: 1024px)",
                },
                mobile: {
                    webp: `/images/about/us/show-aligner-mobile.webp`,
                    jpeg: `/images/about/us/show-aligner-mobile.jpg`,
                },
                rounded: true,
            },
        },
        results: {
            stories: [
                {
                    mobile: {
                        webp: "/images/about/us/story-1-mobile.webp",
                        jpeg: "/images/about/us/story-1-mobile.jpg",
                    },
                    desktop: {
                        webp: "/images/about/us/story-1-desktop.webp",
                        jpeg: "/images/about/us/story-1-desktop.jpg",
                        media: "(min-width: 1024px)",
                    },
                },
                {
                    mobile: {
                        webp: "/images/about/us/story-2-mobile.webp",
                        jpeg: "/images/about/us/story-2-mobile.jpg",
                    },
                    desktop: {
                        webp: "/images/about/us/story-2-desktop.webp",
                        jpeg: "/images/about/us/story-2-desktop.jpg",
                        media: "(min-width: 1024px)",
                    },
                },
                {
                    mobile: {
                        webp: "/images/about/us/story-3-mobile.webp",
                        jpeg: "/images/about/us/story-3-mobile.jpg",
                    },
                    desktop: {
                        webp: "/images/about/us/story-3-desktop.webp",
                        jpeg: "/images/about/us/story-3-desktop.jpg",
                        media: "(min-width: 1024px)",
                    },
                },
            ],
        },
        partOfChanges: {
            quote: null,
            image: {
                desktop: {
                    webp: `/images/about/us/doctors-desktop.webp`,
                    jpeg: `/images/about/us/doctors-desktop.jpg`,
                    media: "(min-width: 1024px)",
                },
                mobile: {
                    webp: `/images/about/us/doctors-mobile.webp`,
                    jpeg: `/images/about/us/doctors-mobile.jpg`,
                },
                rounded: true,
            },
        },
        providers: {
            title: t("about:providersTitle"),
            description: t("about:providersDescription"),
            image: {
                images: [
                    {
                        webp: "/images/about/us/providers-mobile.webp",
                        jpg: "/images/about/us/providers-mobile.jpg",
                    },
                    {
                        webp: "/images/about/us/providers-desktop.webp",
                        jpg: "/images/about/us/providers-desktop.jpg",
                    },
                ],
                defaultSrc: "/images/about/us/providers-mobile.jpg",
            },
            button: {
                name: t("about:providersButton"),
                link: "https://docs.google.com/forms/d/e/1FAIpQLSdtOhVoQC5BMc6ntCaMLQrNNwYa7f6KgjlCe6BuJjpb4x6grQ/viewform",
                target: "_blank",
            },
            rounded: true,
        },
        legalAddress: null,
        faq: {
            title: t("faq:questionsTitle"),
            list: [
                {
                    question: t("faq:questionsListItemQuestionWhoWeAre1"),
                    answer: t("faq:questionsListItemAnswerWhoWeAre1"),
                },
                {
                    question: t("faq:questionsListItemQuestionWhoWeAre2"),
                    answer: t("faq:questionsListItemAnswerWhoWeAre2"),
                },
                {
                    question: t("faq:questionsListItemQuestionWhoWeAre3"),
                    answer: t("faq:questionsListItemAnswerWhoWeAre3"),
                },
                {
                    question: t("faq:questionsListItemQuestionWhoWeAre4"),
                    answer: t("faq:questionsListItemAnswerWhoWeAre4"),
                },
            ],
        },
    });

export default pageContent;
