import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import defaultContent from "./spain";

const pageContent = (t: Translate) => {
    const content = defaultContent(t);
    return mergeDeepRight(content, {
        doctors: [
            {
                photo: {
                    images: [{ png: "/images/doctors/avatars/widu.png" }],
                },
                color: "text.accent",
                icon: "drKhaledSignature",
                name: t("quiz:doctor1Name"),
                position: t("quiz:doctor1Position"),
            },
            ...content.doctors.slice(1, 5),
        ],
    });
};

export default pageContent;
