import { Translate } from "next-translate";

import contacts from "configs/contacts.json";
import { ContactTypes, SocialTypes } from "constants/enum";
import { getWALink } from "utils/getWALink";
import { PageContentProps } from "content/content.types";

const pageContent = (t: Translate, { version }: PageContentProps) => ({
    hideMenu: true,
    image: {
        desktop: {
            webp: "/images/new/booking-widget/clinic.webp",
            jpeg: "/images/new/booking-widget/clinic.jpg",
        },
    },
    contacts: [
        {
            name: contacts.phone[version].public,
            icon: ContactTypes.PHONE,
            link: `tel:${contacts.phone[version].link}`,
        },
        {
            name: "WhatsApp",
            icon: SocialTypes.WA,
            link: getWALink(
                contacts.whatsapp[version].link,
                t("global:whatsappMessage")
            ),
        },
        {
            name: "Telegram",
            icon: SocialTypes.TG,
            link: contacts.telegram[version].link,
        },
        {
            name: t("global:email"),
            icon: ContactTypes.EMAIL,
            link: `mailto:${contacts.email[version].link}`,
        },
    ],
});

export default pageContent;
