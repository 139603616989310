import { Translate } from "next-translate";

import { QuizLayoutContent } from "layouts/pages/QuizLayout/QuizLayout.types";

const pageContent = (t: Translate): QuizLayoutContent => ({
    quizData: new Array(5).fill({}).map((_, qi) => ({
        question: t(`quiz:question${qi + 1}`),
        answers: new Array(4).fill({}).map((_, ai) => ({
            text: t(`quiz:question${qi + 1}Answer${ai + 1}Text`),
            impressAnswer: t(
                `quiz:question${qi + 1}Answer${ai + 1}ImpressAnswer`
            ),
        })),
        defaultMessage: t(`quiz:question${qi + 1}DefaultMessage`),
    })),
    startScreen: {
        title: t("quiz:startScreenTitle"),
        subtitle: t("quiz:startScreenSubtitle"),
        action: t("quiz:startScreenAction"),
        comment: t("quiz:startScreenComment"),
    },
    questionCard: {
        actionContact: t("quiz:questionCardActionContact"),
        actionNext: t("quiz:questionCardActionNext"),
        actionFinal: t("quiz:questionCardActionFinal"),
        impressAnswerTitle: t("quiz:questionCardImpressAnswerTitle"),
    },
    endScreen: {
        titlePart1: t("quiz:endScreenTitlePart1"),
        titlePart2: t("quiz:endScreenTitlePart2"),
        action: t("quiz:endScreenAction"),
    },
    resultScreen: {
        title: t("quiz:resultScreenTitle"),
        subtitle: ({ points }: { points: number }) =>
            t("quiz:resultScreenSubtitle", { points }),
        discounts: {
            title: t("quiz:resultScreenDiscountsTitle"),
            text1: t("quiz:resultScreenDiscountsText1"),
            text2: t("quiz:resultScreenDiscountsText2"),
            text3: t("quiz:resultScreenDiscountsText3"),
            action: t("quiz:resultScreenDiscountsAction"),
        },
        resultCards: {
            answerTitle: t("quiz:resultScreenResultsCardAnswerTitle"),
            impressAnswerTitle: t(
                "quiz:resultScreenResultsCardImpressAnswerTitle"
            ),
            socialTitle: t("quiz:resultScreenSocialCardTitle"),
            socialSubtitle: t("quiz:resultScreenSocialCardSubtitle"),
        },
    },
    doctors: [
        {
            photo: {
                images: [{ png: "/images/doctors/avatars/khaled.png" }],
            },
            color: "text.accent",
            icon: "drKhaledSignature",
            name: t("quiz:doctor1Name"),
            position: t("quiz:doctor1Position"),
        },
        {
            photo: {
                images: [{ png: "/images/doctors/avatars/maria.png" }],
            },
            color: "text.link",
            icon: "drMariaSignature",
            name: t("quiz:doctor2Name"),
            position: t("quiz:doctor2Position"),
        },
        {
            photo: {
                images: [{ png: "/images/doctors/avatars/mariella.png" }],
            },
            color: "brand.honey",
            icon: "drMariellaSignature",
            name: t("quiz:doctor3Name"),
            position: t("quiz:doctor3Position"),
        },
        {
            photo: {
                images: [{ png: "/images/doctors/avatars/ines.png" }],
            },
            color: "action.success",
            icon: "drInesSignature",
            name: t("quiz:doctor4Name"),
            position: t("quiz:doctor4Position"),
        },
        {
            photo: {
                images: [{ png: "/images/doctors/avatars/ariel.png" }],
            },
            color: "text.primary",
            icon: "drArielSignature",
            name: t("quiz:doctor5Name"),
            position: t("quiz:doctor5Position"),
        },
    ],
});

export default pageContent;
