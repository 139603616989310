import { FC } from "react";
import { Box, Picture } from "@smile2impress/components";

import { ImageContentBlockProps } from "./contentBlocks.types";

export const ImageBlock: FC<ImageContentBlockProps> = ({ content }) => {
    const { image } = content;
    return (
        <Box mt={[0]} mb={[0]} borderRadius="xl" overflow="hidden">
            <Picture {...image} fit="cover" />
        </Box>
    );
};
