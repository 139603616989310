import { Translate } from "next-translate";

import { CountryVersion } from "constants/enum";
import { PageContentProps } from "content/content.types";
import { LocationLayoutContent } from "layouts/pages/LocationsLayoutNew/LocationsLayout.types";

const linkHelpUs = {
    [CountryVersion.SPAIN]:
        "https://smile2impress.com/es/blog/reciclaje-alineadores",
    [CountryVersion.UK]:
        "https://smile2impress.com/uk/blog/recyclable-aligners",
    [CountryVersion.ITALY]:
        "https://smile2impress.com/it/blog/allineatori-riciclabili",
    [CountryVersion.PORTUGAL]:
        "https://smile2impress.com/pt/blog/alinhadores-reciclaveis",
    [CountryVersion.CATALAN]:
        "https://smile2impress.com/es/blog/reciclaje-alineadores",
    [CountryVersion.FRANCE]: "",
    [CountryVersion.GERMANY]: "",
    [CountryVersion.UKRAINE]: "",
    [CountryVersion.US]: "",
};

const pageContent = (
    t: Translate,
    { version }: PageContentProps
): LocationLayoutContent => {
    return {
        title: t("locations:title", {
            countryName: t(`global:countryName.${version}`),
        }),
        subtitle: t("locations:subtitle"),
        description: t("locations:description"),
        imageGradientClinic: {
            jpeg: "/images/locations/gradient-hightlight.jpg",
        },
        imageClinic: {
            images: [
                {
                    jpeg: "/images/locations/clinic-mobile.jpg",
                    webp: "/images/locations/clinic-mobile.webp",
                },
                {
                    jpeg: "/images/locations/clinic-desktop.jpg",
                    webp: "/images/locations/clinic-desktop.webp",
                },
            ],
            defaultSrc: "/images/locations/clinic-desktop.jpg",
        },
        titleModal: t("common-form:titleModal"),
        buttonTextHighLightClinic: t("global:buttonTextHighLightClinic"),
        helpUs: {
            title: t("locations:helpUsTitle"),
            description: t("locations:helpUsDescription"),
            textLink: t("locations:helpUsTextLink"),
            link: linkHelpUs[version],
            imageGradient: {
                jpeg: "/images/locations/gradient-help.jpg",
            },
            image: {
                images: [
                    {
                        jpeg: "/images/locations/aligners-mobile.jpg",
                        webp: "/images/locations/aligners-mobile.webp",
                    },
                    {
                        png: "/images/locations/aligners-desktop.png",
                    },
                ],
                defaultSrc: "/images/locations/aligners-mobile.jpg",
            },
        },
    };
};

export default pageContent;
