import { FC } from "react";
import { Box } from "@smile2impress/components";

import {
    TmpImageTextAndPriceAreaTypes,
    TmpImageTextAndPriceProps,
    TmpImageTextAndPriceAreas,
} from "./TmpImageTextAndPrice.types";
import { FullWidthArea, TwoColumnsArea } from "./components/areas";

export const TmpImageTextAndPrice: FC<TmpImageTextAndPriceProps> = ({
    content,
}) => {
    const renderArea = (
        block: TmpImageTextAndPriceAreas,
        isFirst: boolean,
        isLast: boolean
    ) => {
        switch (block.type) {
            case TmpImageTextAndPriceAreaTypes.TWO_COLUMNS:
                return (
                    <TwoColumnsArea
                        leftContent={block.leftContent}
                        rightContent={block.rightContent}
                        isFirst={isFirst}
                        isLast={isLast}
                    />
                );
            case TmpImageTextAndPriceAreaTypes.FULL_WIDTH:
                return (
                    <FullWidthArea
                        content={block.content}
                        isFirst={isFirst}
                        isLast={isLast}
                    />
                );
        }
    };

    return (
        <Box
            bg="base.secondary"
            borderRadius="xl"
            overflow="hidden"
            px={[3, 10]}
            py={[5, 10]}
        >
            {content.map((block, i) => (
                <Box key={i} mb={[3, 10]} _last={{ mb: 0 }}>
                    {renderArea(block, i === 0, i === content.length - 1)}
                </Box>
            ))}
        </Box>
    );
};
