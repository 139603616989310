import { CountryVersion } from "constants/enum";

import spain from "./spain";
import italy from "./italy";
import portugal from "./portugal";
import germany from "./germany";
import france from "./france";
import uk from "./uk";
import us from "./us";

export default {
    [CountryVersion.SPAIN]: spain,
    [CountryVersion.ITALY]: italy,
    [CountryVersion.PORTUGAL]: portugal,
    [CountryVersion.GERMANY]: germany,
    [CountryVersion.FRANCE]: france,
    [CountryVersion.UK]: uk,
    [CountryVersion.US]: us,
};
