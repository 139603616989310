import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent: any = (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        mainBanner: {
            title: t("about:mainBannerTitle"),
            image: {
                desktop: {
                    webp: `/images/about/uk/team-desktop.webp`,
                    jpeg: `/images/about/uk/team-desktop.jpg`,
                    media: "(min-width: 1024px)",
                },
                mobile: {
                    webp: `/images/about/uk/team-mobile.webp`,
                    jpeg: `/images/about/uk/team-mobile.jpg`,
                },
            },
        },
        legalAddress: null,
    });

export default pageContent;
