import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";
import { getDesignPostfix } from "utils/getDesignPostfix";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const { newDesignPostfix } = getDesignPostfix(version);
    return mergeDeepRight(defaultContent(t, { version }), {
        welcome: {
            image: {
                defaultSrc: `/images/new/como-funciona/hand-mobile-2x${newDesignPostfix}.jpg`,
                images: [
                    {
                        jpeg: `/images/new/como-funciona/hand-mobile-2x${newDesignPostfix}.jpg`,
                        webp: `/images/new/como-funciona/hand-mobile-2x${newDesignPostfix}.webp`,
                    },
                    {
                        jpeg: `/images/new/como-funciona/hand-desktop-2x${newDesignPostfix}.jpg`,
                        webp: `/images/new/como-funciona/hand-desktop-2x${newDesignPostfix}.webp`,
                        media: "(min-width: 1024px)",
                    },
                ],
                rounded: true,
            },
        },
        appointment: {
            title: t("global:bookAppointmentButton"),
            image: {
                images: [
                    {
                        jpeg: "/images/new/main/form-ua-mobile.jpg",
                        webp: "/images/new/main/form-ua-mobile.webp",
                    },
                    {
                        jpeg: "/images/new/main/form-ua-desktop.jpg",
                        webp: "/images/new/main/form-ua-desktop.webp",
                    },
                ],
                defaultSrc: "/images/new/main/form-ua-desktop.jpg",
            },
        },
    });
};

export default pageContent;
