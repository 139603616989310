import { versions } from "configs/versionsConfig";

import spain from "./spain";
import portugal from "./portugal";
import italy from "./italy";
import france from "./france";
import uk from "./uk";

export default {
    [versions.spain]: spain,
    [versions.portugal]: portugal,
    [versions.italy]: italy,
    [versions.france]: france,
    [versions.uk]: uk,
};
