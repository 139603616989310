import { FC } from "react";
import { Box, Text } from "@smile2impress/components";

import { HeadingContentBlockProps } from "./contentBlocks.types";

export const HeadingBlock: FC<HeadingContentBlockProps> = ({ content }) => {
    const { title, subtitle } = content;
    return (
        <Box>
            <Box maxWidth={["100%", "60%"]}>
                <Text textStyle={["title1", "display2"]}>{title}</Text>
            </Box>
            {subtitle && (
                <Text mt={[2, 4]} textStyle="body">
                    {subtitle}
                </Text>
            )}
        </Box>
    );
};
