import { Translate } from "next-translate";

import contacts from "configs/contacts.json";
import { SocialTypes, ContactTypes } from "constants/enum";
import { getWALink } from "utils/getWALink";
import { pathConfig } from "navigation/pathConfig";
import { PageContentProps } from "content/content.types";
import { ThankSignLayoutContent } from "layouts/pages/ThanksSignLayout/ThanksSignLayout.types";

const pageContent = (
    t: Translate,
    { version }: PageContentProps
): ThankSignLayoutContent => ({
    logoLink: pathConfig.main[version],
    image: {
        mobile: {
            webp: "/images/new/thanks-sign/mobile-image.webp",
            jpeg: "/images/new/thanks-sign/mobile-image.jpg",
        },
        desktop: {
            webp: "/images/new/thanks-sign/desktop-image.webp",
            jpeg: "/images/new/thanks-sign/desktop-image.jpg",
        },
    },
    title: t("thanksSign:title"),
    description: t("thanksSign:description"),
    socialMedia: {
        title: t("thanksSign:socialMediaTitle"),
        list: [
            {
                name: contacts.facebook[version].name,
                icon: SocialTypes.FB,
                link: contacts.facebook[version].link,
            },
            {
                name: contacts.instagram[version].username,
                icon: SocialTypes.INSTAGRAM,
                link: contacts.instagram[version].link,
            },
            {
                name: contacts.tiktok[version].name,
                icon: SocialTypes.TIKTOK,
                link: contacts.tiktok[version].link,
            },
        ],
    },
    contacts: {
        title: t("thanksSign:contactTitle"),
        list: [
            {
                name: contacts.phone[version].public,
                icon: ContactTypes.PHONE,
                link: `tel:${contacts.phone[version].link}`,
            },
            {
                name: "WhatsApp",
                icon: SocialTypes.WA,
                link: getWALink(
                    contacts.whatsapp[version].link,
                    t("global:whatsappMessage")
                ),
            },
            {
                name: t("global:email"),
                icon: ContactTypes.EMAIL,
                link: `mailto:${contacts.email[version].link}`,
            },
        ],
    },
});

export default pageContent;
