import { FC } from "react";
import {
    Box,
    Icon,
    Text,
    Flex,
    CheckmarkThinOutlineIcon,
    Button,
    Link,
} from "@smile2impress/components";

import { sanitizeHtml } from "utils/sanitizeHtml";

import { ListContentBlockProps } from "./contentBlocks.types";

export const ListBlock: FC<ListContentBlockProps> = ({ content }) => {
    const { list, title, subTitle, button } = content;
    return (
        <Box>
            <Text
                textStyle="title2"
                mb={[2, 3]}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(title),
                }}
            ></Text>
            {subTitle && (
                <Text
                    textStyle="body"
                    mb={[2, 3]}
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(subTitle),
                    }}
                ></Text>
            )}
            <Box as="ul">
                {list.map((listEl, i) => (
                    <Box
                        as="li"
                        key={i}
                        listStyleType="none"
                        verticalAlign="top"
                        mb={[2, 2]}
                        _last={{ mb: 0 }}
                    >
                        <Flex direction="row">
                            <Box width={[3, 3]} height={[3, 3]} mr={[2, 2]}>
                                <Icon
                                    color="base.accent"
                                    as={CheckmarkThinOutlineIcon}
                                    height={[3, 3]}
                                />
                            </Box>

                            <Text textStyle="body">{listEl}</Text>
                        </Flex>
                    </Box>
                ))}
            </Box>
            {button && (
                <Box textAlign={["center", "left"]}>
                    <Button
                        mt={[3, 5]}
                        textStyle="body"
                        as={Link}
                        size="md"
                        px={3}
                        py={2}
                        fontWeight={600}
                        {...(button.link && { href: button.link })}
                        {...(button.onClick && { onClick: button.onClick })}
                    >
                        {button.text}
                    </Button>
                </Box>
            )}
        </Box>
    );
};
