import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import prices from "configs/prices.json";
import {
    TmpImageTextAndPriceAreaTypes,
    TmpImageTextAndPriceBlocksTypes,
} from "components/templates/TmpImageTextAndPrice";
import { PageContentProps } from "content/content.types";

import defaultContent, { generateListKeys } from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const content = defaultContent(t, { version });

    return mergeDeepRight(content, {
        universalBlock: [
            [
                {
                    type: TmpImageTextAndPriceAreaTypes.FULL_WIDTH,
                    content: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.HEADING,
                            content: {
                                title: t("advanced:universalBlock1Title"),
                                subtitle: t("advanced:universalBlock1Subtitle"),
                            },
                        },
                    ],
                },
                {
                    type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                    leftContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.LIST,
                            content: {
                                list: generateListKeys(t, 1, 1),
                                title: t("advanced:universalBlock1List1Title"),
                            },
                        },
                        {
                            type: TmpImageTextAndPriceBlocksTypes.LIST,
                            content: {
                                list: generateListKeys(t, 1, 2),
                                title: t("advanced:universalBlock1List2Title"),
                            },
                        },
                    ],
                    rightContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.PRICE,
                            content: {
                                duration: t(
                                    "advanced:universalBlockPriceDurationLabel",
                                    {
                                        duration:
                                            prices.advancedPrice.baseDuration[
                                                version
                                            ],
                                    }
                                ),
                                price:
                                    prices.advancedPrice.monthly.baseWD[
                                        version
                                    ] ||
                                    prices.advancedPrice.monthly.base[version],
                            },
                        },
                    ],
                },
            ],
            [
                {
                    type: TmpImageTextAndPriceAreaTypes.FULL_WIDTH,
                    content: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.HEADING,
                            content: {
                                title: t("advanced:universalBlock2Title"),
                                subtitle: t("advanced:universalBlock2Subtitle"),
                            },
                        },
                    ],
                },
                {
                    type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                    leftContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.LIST,
                            content: {
                                list: generateListKeys(t, 2, 1),
                                title: t("advanced:universalBlock2List1Title"),
                            },
                        },
                        {
                            type: TmpImageTextAndPriceBlocksTypes.LIST,
                            content: {
                                list: generateListKeys(t, 2, 2),
                                title: t("advanced:universalBlock2List2Title"),
                            },
                        },
                    ],
                    rightContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.PRICE,
                            content: {
                                duration: t(
                                    "advanced:universalBlockPriceDurationLabel",
                                    {
                                        duration:
                                            prices.advancedPrice.baseDuration[
                                                version
                                            ],
                                    }
                                ),
                                price:
                                    prices.advancedPrice.monthly.baseWD[
                                        version
                                    ] ||
                                    prices.advancedPrice.monthly.base[version],
                            },
                        },
                    ],
                },
            ],
            [
                {
                    type: TmpImageTextAndPriceAreaTypes.FULL_WIDTH,
                    content: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.HEADING,
                            content: {
                                title: t("advanced:universalBlock3Title"),
                                subtitle: t("advanced:universalBlock3Subtitle"),
                            },
                        },
                    ],
                },
                {
                    type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                    leftContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.LIST,
                            content: {
                                list: generateListKeys(t, 3, 1),
                                title: t("advanced:universalBlock3List1Title"),
                            },
                        },
                        {
                            type: TmpImageTextAndPriceBlocksTypes.LIST,
                            content: {
                                list: generateListKeys(t, 3, 2),
                                title: t("advanced:universalBlock3List2Title"),
                            },
                        },
                    ],
                    rightContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.PRICE,
                            content: {
                                duration: t(
                                    "advanced:universalBlockPriceDurationLabel",
                                    {
                                        duration:
                                            prices.advancedPrice.baseDuration[
                                                version
                                            ],
                                    }
                                ),
                                price:
                                    prices.advancedPrice.monthly.baseWD[
                                        version
                                    ] ||
                                    prices.advancedPrice.monthly.base[version],
                            },
                        },
                    ],
                },
            ],
            [
                {
                    type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                    leftContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.IMAGE,
                            content: {
                                image: {
                                    images: [
                                        {
                                            jpeg: "/images/new/prices/advanced/italy/advanced-clinic-mobile.jpg",
                                            webp: "/images/new/prices/advanced/italy/advanced-clinic-mobile.webp",
                                        },
                                        {
                                            jpeg: "/images/new/prices/advanced/italy/advanced-clinic-desktop.jpg",
                                            webp: "/images/new/prices/advanced/italy/advanced-clinic-desktop.webp",
                                        },
                                    ],
                                    defaultSrc:
                                        "/images/new/prices/advanced/italy/advanced-clinic-desktop.jpg",
                                },
                            },
                        },
                    ],
                    rightContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.LIST,
                            content: {
                                list: generateListKeys(t, 4, 1),
                                title: t("advanced:universalBlock4List1Title"),
                            },
                        },
                    ],
                },
            ],
        ],
    });
};

export default pageContent;
