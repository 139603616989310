import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

export default (t: Translate, { version }: PageContentProps) => {
    const content = defaultContent(t, { version });

    return mergeDeepRight(content, {
        main: {
            ...content.main,
            badge: {
                mobile: {
                    png: `/images/referral/referral-badge-uk.png`,
                },
            },
        },
    });
};
