import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        description: {
            title: t("thanks:descriptionTitle"),
            list: [
                t("thanks:descriptionList1"),
                t("thanks:descriptionList2"),
                t("thanks:descriptionList3"),
            ],
        },
    });

export default pageContent;
