import { Translate } from "next-translate";

import contacts from "configs/contacts.json";
import { pathConfig } from "navigation/pathConfig";
import { PageContentProps } from "content/content.types";

const pageContent = (t: Translate, { version }: PageContentProps) => ({
    leadersBlock: {
        title: t("whyImpress:leadersBlockTitle"),
        subtitle: t("whyImpress:leadersBlockSubtitle"),
        clinicImg: "/images/new/whyImpress/leaders/clinic.jpg",
        visitUs: {
            name: t("whyImpress:leadersBlockVisitUs"),
            link: pathConfig.universalForm[version],
        },
        seeAllClinics: {
            name: t("whyImpress:leadersBlockSeeAllClinis"),
            link: pathConfig.locations[version],
        },
        cabinetImg: "/images/new/whyImpress/leaders/cabinet.jpg",
        equipmentImg: "/images/new/whyImpress/leaders/equipment.jpg",
    },
    doctorsContent: {
        title: t("whyImpress:doctorsTitle"),
        description: t("whyImpress:doctorsDescription"),
        quote: t("whyImpress:doctorsQuote"),
        quoteAuthor: t("whyImpress:doctorsQuoteAuthor"),
        actionConsult: {
            link: pathConfig.universalForm[version],
            name: t("whyImpress:doctorsActionConsult"),
        },
        actionReadMore: {
            link: pathConfig.howItWorks[version],
            name: t("whyImpress:doctorsActionRead"),
        },
        medics: [
            {
                photo: {
                    images: [
                        {
                            png: "/images/new/whyImpress/medicPhoto-1.png",
                        },
                        {
                            png: "/images/new/whyImpress/medicPhoto-1.png",
                        },
                    ],
                },
                name: t("whyImpress:medicsName1"),
                position: t("whyImpress:medicsPosition1"),
                experience: t("whyImpress:medicsExperience1"),
            },
            {
                photo: {
                    images: [
                        {
                            png: "/images/new/whyImpress/medicPhoto-2.png",
                        },
                        {
                            png: "/images/new/whyImpress/medicPhoto-2.png",
                        },
                    ],
                },
                name: t("whyImpress:medicsName2"),
                position: t("whyImpress:medicsPosition2"),
            },
            {
                photo: {
                    images: [
                        {
                            png: "/images/new/whyImpress/medical-doctor.png",
                        },
                        {
                            png: "/images/new/whyImpress/medical-doctor.png",
                        },
                    ],
                },
                name: t("whyImpress:medicsName3"),
                position: t("whyImpress:medicsPosition3"),
            },
            {
                photo: {
                    images: [
                        {
                            png: "/images/new/whyImpress/medicPhoto-4.png",
                        },
                        {
                            png: "/images/new/whyImpress/medicPhoto-4.png",
                        },
                    ],
                },
                name: t("whyImpress:medicsName4"),
                position: t("whyImpress:medicsPosition2"),
            },
            {
                photo: {
                    images: [
                        {
                            png: "/images/new/whyImpress/medicPhoto-5.png",
                        },
                        {
                            png: "/images/new/whyImpress/medicPhoto-5.png",
                        },
                    ],
                },
                name: t("whyImpress:medicsName5"),
                position: t("whyImpress:medicsPosition2"),
            },
            {
                photo: {
                    images: [
                        {
                            png: "/images/new/whyImpress/medicPhoto-6.png",
                        },
                        {
                            png: "/images/new/whyImpress/medicPhoto-6.png",
                        },
                    ],
                },
                name: t("whyImpress:medicsName6"),
                position: t("whyImpress:medicsPosition2"),
            },
        ],
    },
    mainBanner: {
        imageDesktop: "/images/new/whyImpress/main-desktop.jpg",
        imageMobile: "/images/new/whyImpress/main-mobile.jpg",
        title: t("whyImpress:mainBannerTitle"),
    },
    tripleCheck: {
        companyName: "Impress",
        title: t("whyImpress:tripleCheckTitle"),
        subtitle: t("whyImpress:tripleCheckSubtitle"),
        firstCheck: {
            title: t("whyImpress:tripleCheckFirstCheckTitle"),
            content: t("whyImpress:tripleCheckFirstCheckContent"),
            checkImg: "/images/new/whyImpress/tripleCheck/clinicVisit.jpg",
        },
        secondCheck: {
            teethsGif: "/images/new/whyImpress/tripleCheck/teeths.gif",
            checkImg: "/images/new/whyImpress/tripleCheck/videoSimulation.jpg",
            title: t("whyImpress:tripleCheckSecondCheckTitle"),
            content: t("whyImpress:tripleCheckSecondCheckContent"),
        },
        thirdCheck: {
            title: t("whyImpress:tripleCheckThirdCheckTitle"),
            content: t("whyImpress:tripleCheckThirdCheckContent"),
            checkImg: "/images/new/whyImpress/tripleCheck/preProduction.jpg",
        },
    },
    customerReviews: {
        title: t("whyImpress:customerReviewsTitle"),
        trustpilotLink: contacts.trustpilot[version].link,
        instagramLink: contacts.instagram[version].link,
        instagramTitle: t("whyImpress:customerReviewsInstagramTitle"),
        images: [
            "/images/new/whyImpress/image_1.jpg",
            "/images/new/whyImpress/image_2.jpg",
            "/images/new/whyImpress/image_3.jpg",
            "/images/new/whyImpress/image_4.jpg",
            "/images/new/whyImpress/image_5.jpg",
            "/images/new/whyImpress/image_6.jpg",
            "/images/new/whyImpress/image_7.jpg",
            "/images/new/whyImpress/image_8.jpg",
            "/images/new/whyImpress/image_9.jpg",
        ],
    },
    details: {
        title: t("whyImpress:detailsTitle"),
        description: t("whyImpress:detailsDescription"),
        stages: [
            {
                text: t("whyImpress:detailsStageText1"),
                image: {
                    desktop: {
                        jpeg: "/images/new/whyImpress/detailsPhoto-1.jpg",
                    },
                },
                isActive: true,
            },
            {
                text: t("whyImpress:detailsStageText2"),
                image: {
                    desktop: {
                        jpeg: "/images/new/whyImpress/detailsPhoto-2.jpg",
                    },
                },
                isActive: false,
            },
            {
                text: t("whyImpress:detailsStageText3"),
                image: {
                    desktop: {
                        jpeg: "/images/new/whyImpress/detailsPhoto-3.jpg",
                    },
                },
                isActive: false,
            },
        ],
        actionShow: {
            name: t("whyImpress:detailsActionShow"),
            link: pathConfig.locations[version],
        },
        actionHow: {
            name: t("whyImpress:detailsActionHow"),
            link: pathConfig.howItWorks[version],
        },
    },
    resultsContent: {
        title: t("whyImpress:resultsTitle"),
        subtitle: t("whyImpress:resultsSubtitle"),
        results: {
            image: "/images/new/whyImpress/resultPhoto-2.png",
            listQualities: [
                t("whyImpress:resultsListQualities1"),
                t("whyImpress:resultsListQualities2"),
                t("whyImpress:resultsListQualities3"),
            ],
        },
    },
});

export default pageContent;
