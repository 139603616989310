import { FC } from "react";
import { Box } from "@smile2impress/components";

import { FullWidthAreaProps } from "./areas.types";
import { renderContentBlock } from "../contentBlocks";

export const FullWidthArea: FC<FullWidthAreaProps> = ({
    content,
    isFirst,
    isLast,
}) => {
    return (
        <Box>
            {content.map((block, i) => (
                <Box key={i} mb={[3, 5]} _last={{ mb: 0 }}>
                    {renderContentBlock({
                        block,
                        stretchTop: [isFirst && i === 0, isFirst && i === 0],
                        stretchBottom: [
                            isLast && i === content.length - 1,
                            isLast && i === content.length - 1,
                        ],
                    })}
                </Box>
            ))}
        </Box>
    );
};
