import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";
import prices from "configs/prices.json";
import { priceFormatter } from "utils/priceFormatter";

import defaultContent from "./spain";

export default (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        description: t("referralForFriends:description", {
            discount: priceFormatter(
                prices.discount.referralForFriends.us.sanFrancisco,
                prices.currency[version],
                version
            ),
        }),
        image: {
            mobile: {
                jpeg: "/images/referral/referral-us-mobile_15.jpg",
                webp: "/images/referral/referral-us-mobile_15.webp",
            },
            desktop: {
                jpeg: "/images/referral/referral-us-desktop_15.jpg",
                webp: "/images/referral/referral-us-desktop_15.webp",
            },
        },
    });
