import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent: any = (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        doctorsContent: {
            medics: [
                {
                    photo: {
                        images: [
                            {
                                png: "/images/new/whyImpress/uk/medicPhoto-1-mobile.png",
                            },
                            {
                                png: "/images/new/whyImpress/uk/medicPhoto-1.png",
                            },
                        ],
                    },
                    name: t("whyImpress:medicsName1UK"),
                    position: t("whyImpress:medicsPosition2UK"),
                },
                {
                    photo: {
                        images: [
                            {
                                png: "/images/new/whyImpress/medicPhoto-2.png",
                            },
                            {
                                png: "/images/new/whyImpress/medicPhoto-2.png",
                            },
                        ],
                    },
                    name: t("whyImpress:medicsName2"),
                    position: t("whyImpress:medicsPosition2"),
                },
                {
                    photo: {
                        images: [
                            {
                                png: "/images/new/whyImpress/uk/medicPhoto-3-mobile.png",
                            },
                            {
                                png: "/images/new/whyImpress/uk/medicPhoto-3.png",
                            },
                        ],
                    },
                    name: t("whyImpress:medicsName3UK"),
                    position: t("whyImpress:medicsPosition3"),
                },
                {
                    photo: {
                        images: [
                            {
                                png: "/images/new/whyImpress/uk/medicPhoto-4-mobile.png",
                            },
                            {
                                png: "/images/new/whyImpress/uk/medicPhoto-4.png",
                            },
                        ],
                    },
                    name: t("whyImpress:medicsName4UK"),
                    position: t("whyImpress:medicsPosition2UK"),
                },
                {
                    photo: {
                        images: [
                            {
                                png: "/images/new/whyImpress/uk/medicPhoto-5-mobile.png",
                            },
                            {
                                png: "/images/new/whyImpress/uk/medicPhoto-5.png",
                            },
                        ],
                    },
                    name: t("whyImpress:medicsName5UK"),
                    position: t("whyImpress:medicsPosition2UK"),
                },
                {
                    photo: {
                        images: [
                            {
                                png: "/images/new/whyImpress/uk/medicPhoto-6-mobile.png",
                            },
                            {
                                png: "/images/new/whyImpress/uk/medicPhoto-6.png",
                            },
                        ],
                    },
                    name: t("whyImpress:medicsName6UK"),
                    position: t("whyImpress:medicsPosition4"),
                },
            ],
        },
    });

export default pageContent;
