import { Translate } from "next-translate";

import { TmpSlideProps } from "components/templates/TmpContentSlider";

const LINKS_PUBLICATIONS = [
    "https://www.larazon.es/lr-content/20220704/7wqel67uzzfmjnsesgy4c7lija.html",
    "https://www.biocat.cat/es/start-up-generation/paciente-debe-ser-principal-prioridad-tu-negocio",
    "https://www.womenshealthmag.com/es/salud-bienestar/a39781196/impress-ortodoncia-invisible-sonrisa/",
];

const getPublication = (t: Translate, id: number): TmpSlideProps => ({
    title: t(`associatedClinics:publicationsBlockItemTitle${id}`),
    text: t(`associatedClinics:publicationsBlockItemText${id}`),
    link: LINKS_PUBLICATIONS[id - 1],
    image: {
        images: [
            {
                webp: `/images/new/associatedClinics/publications-${id}-mobile.webp`,
                jpeg: `/images/new/associatedClinics/publications-${id}-mobile.jpg`,
            },
            {
                webp: `/images/new/associatedClinics/publications-${id}-desktop.webp`,
                jpeg: `/images/new/associatedClinics/publications-${id}-desktop.jpg`,
            },
        ],
        defaultSrc: `/images/new/associatedClinics/publications-${id}-mobile.jpg`,
    },
});

export const MAX_PUBLICATIONS = 3;

export const getPublicationsList = (t: Translate, maxPublications: number) =>
    new Array(maxPublications)
        .fill(null)
        .map((_, i) => getPublication(t, i + 1));
