import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import prices from "configs/prices.json";
import { priceFormatter } from "utils/priceFormatter";
import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";
import { ShowUpPageContent } from "./content.types";

const pageContent = (
    t: Translate,
    { version }: PageContentProps
): ShowUpPageContent =>
    mergeDeepRight(defaultContent(t, { version }), {
        mainBanner: {
            priceBlock: {
                label: t("firstVisit:priceBlockLabel"),
                value: priceFormatter(
                    prices.FVPrice[version],
                    prices.currency[version],
                    version
                ),
            },
        },
    });

export default pageContent;
