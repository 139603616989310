import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import { pathConfig } from "navigation/pathConfig";
import prices from "configs/prices.json";
import { priceFormatter } from "utils/priceFormatter";
import { PageContentProps } from "content/content.types";
import { getDesignPostfix } from "utils/getDesignPostfix";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const content = defaultContent(t, { version });

    const lightMonthBasePrice =
        prices.lightPrice.monthly.baseWD[version] ||
        prices.lightPrice.monthly.base[version];
    const regularMonthBasePrice =
        prices.regularPrice.monthly.baseWD[version] ||
        prices.regularPrice.monthly.base[version];

    const { newDesignPostfix } = getDesignPostfix(version);

    return mergeDeepRight(content, {
        checkUp: {
            title: t("main:aboutUsTitle"),
            list: [
                t("main:aboutUsListItem1"),
                t("main:aboutUsListItem2"),
                t("main:aboutUsListItem3"),
                t("main:aboutUsListItem4", {
                    amount: priceFormatter(
                        lightMonthBasePrice || regularMonthBasePrice,
                        prices.currency[version],
                        version
                    ),
                }),
            ],
            image: {
                images: [
                    {
                        webp: "/images/new/main/doctor-mobile.webp",
                        jpeg: "/images/new/main/doctor-mobile.jpg",
                    },
                    {
                        webp: "/images/new/main/doctor-desktop.webp",
                        jpeg: "/images/new/main/doctor-desktop.jpg",
                    },
                ],
                defaultSrc: "/images/new/main/doctor-mobile.jpg",
            },
        },
        headerBanner: {
            image: {
                defaultSrc: `/images/new/main/girl-mobile.jpg`,
                images: [
                    {
                        jpeg: `/images/new/main/girl-mobile.jpg`,
                        webp: `/images/new/main/girl-mobile.webp`,
                    },
                    {
                        jpeg: `/images/new/main/girl-desktop.jpg`,
                        webp: `/images/new/main/girl-desktop.webp`,
                    },
                ],
                rounded: true,
            },
        },
        features: {
            list: [
                {
                    image: {
                        images: [
                            {
                                webp: `/images/new/main/feature-1-mobile.webp`,
                                jpeg: `/images/new/main/feature-1-mobile.jpg`,
                            },
                            {
                                webp: `/images/new/main/feature-1-desktop.webp`,
                                jpeg: `/images/new/main/feature-1-desktop.jpg`,
                            },
                        ],
                    },
                    text: t("main:informationBlocksListItemText1"),
                },
                {
                    image: {
                        images: [
                            {
                                jpeg: `/images/new/main/feature-2-desktop.jpg`,
                                webp: `/images/new/main/feature-2-desktop.webp`,
                            },
                            {
                                jpeg: `/images/new/main/feature-2-mobile.jpg`,
                                webp: `/images/new/main/feature-2-mobile.webp`,
                            },
                        ],
                    },
                    text: t("main:informationBlocksListItemText2"),
                },
                {
                    image: {
                        images: [
                            {
                                webp: "/images/new/main/feature-3-mobile.webp",
                                jpeg: "/images/new/main/feature-3-mobile.jpg",
                            },
                            {
                                webp: "/images/new/main/feature-3-desktop.webp",
                                jpeg: "/images/new/main/feature-3-desktop.jpg",
                            },
                        ],
                    },
                    text: t("main:informationBlocksListItemText3"),
                },
                {
                    image: {
                        images: [
                            {
                                webp: `/images/new/main/step4-desktop-2x${newDesignPostfix}.webp`,
                                jpeg: `/images/new/main/step4-desktop-2x${newDesignPostfix}.jpg`,
                            },
                            {
                                webp: `/images/new/main/step4-mobile-2x${newDesignPostfix}.webp`,
                                jpeg: `/images/new/main/step4-mobile-2x${newDesignPostfix}.jpg`,
                            },
                        ],
                    },
                    text: t("main:informationBlocksListItemText4"),
                },
            ],
        },
        quizPromo: null,
        locationsPromo: {
            title: t("main:locationsPromoTitle"),
            button: {
                name: t("main:locationsPromoCTA"),
                link: pathConfig.locations[version],
            },
            image: {
                images: [
                    {
                        webp: "/images/new/main/locations-promo/locations-promo-mobile.webp",
                        jpeg: "/images/new/main/locations-promo/locations-promo-mobile.jpg",
                    },
                    {
                        webp: "/images/new/main/locations-promo/locations-promo-desktop.webp",
                        jpeg: "/images/new/main/locations-promo/locations-promo-desktop.jpg",
                    },
                ],
            },
        },
    });
};

export default pageContent;
