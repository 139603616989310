import {
    TmpUniversalTitleWithImageBlocksTypes,
    TmpUniversalTitleWithImageBlockContent,
} from "../../TmpUniversalTitleWithImage.types";
import { HeadingBlock, ImageBlock } from "./";

export const renderContentBlock = (
    block: TmpUniversalTitleWithImageBlockContent
) => {
    switch (block.type) {
        case TmpUniversalTitleWithImageBlocksTypes.HEADING: {
            return <HeadingBlock content={block.content} />;
        }
        case TmpUniversalTitleWithImageBlocksTypes.IMAGE: {
            return <ImageBlock content={block.content} />;
        }
    }
};
