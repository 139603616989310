import { Translate } from "next-translate";
import {
    ShieldWithSmileIcon,
    ToothIcon,
    HandIcon,
} from "@smile2impress/components";

import {
    TmpImageTextAndPriceBlocksTypes,
    TmpImageTextAndPriceAreaTypes,
} from "components/templates/TmpImageTextAndPrice";
import { PageContentProps } from "content/content.types";
import { AdvantageItemSizeType } from "components/templates/TmpMultipurpose/components/Item/Item.types";
import { regions } from "configs/regionsConfig";
import { pathConfig } from "navigation/pathConfig";

export const generateListKeys = (
    t: Translate,
    blockIndex: number,
    listIndex: number,
    totalElements = 10
) => {
    return new Array(totalElements)
        .fill(null)
        .map((_, i) =>
            t(
                `kids:universalBlock${blockIndex}List${listIndex}El${i + 1}`,
                {},
                { fallback: "" }
            )
        )
        .filter((e) => e);
};

const multipurposePointsIcons = [ShieldWithSmileIcon, ToothIcon, HandIcon];

const pageContent = (t: Translate, { version }: PageContentProps) => ({
    headerBanner: {
        title: t("kids:headerTitle"),
        comment: t("kids:headerComment"),
        image: {
            defaultSrc: `/images/new/kids/header-mobile.jpg`,
            images: [
                {
                    jpeg: `/images/new/kids/header-mobile.jpg`,
                    webp: `/images/new/kids/header-mobile.webp`,
                },
                {
                    jpeg: `/images/new/kids/header-desktop.jpg`,
                    webp: `/images/new/kids/header-desktop.webp`,
                },
            ],
            rounded: true,
        },
    },
    universalBlock: [
        {
            type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
            leftContent: [
                {
                    type: TmpImageTextAndPriceBlocksTypes.IMAGE,
                    content: {
                        image: {
                            images: [
                                {
                                    jpeg: "/images/new/kids/necessary-mobile.jpg",
                                    webp: "/images/new/kids/necessary-mobile.webp",
                                },
                                {
                                    jpeg: "/images/new/kids/necessary-desktop.jpg",
                                    webp: "/images/new/kids/necessary-desktop.webp",
                                },
                            ],
                            defaultSrc:
                                "/images/new/kids/necessary-desktop.jpg",
                        },
                    },
                },
            ],
            rightContent: [
                {
                    type: TmpImageTextAndPriceBlocksTypes.LIST,
                    content: {
                        list: [
                            t("kids:universalBlockListEl1"),
                            t("kids:universalBlockListEl2"),
                            t("kids:universalBlockListEl3"),
                            t("kids:universalBlockListEl4"),
                            t("kids:universalBlockListEl5"),
                        ],
                        title: t("kids:universalBlockListTitle"),
                        subTitle: t("kids:universalBlockListSubTitle"),
                        button: {
                            text: t("global:bookAppointmentButton"),
                        },
                    },
                },
            ],
        },
    ],
    clinics: {
        flags: [
            regions.es,
            regions.fr,
            regions.it,
            regions.pt,
            regions.gb,
            regions.ch,
            regions.de,
            regions.ua,
            regions.us,
        ],
        items: [
            {
                flag: regions.es,
                location: t("kids:clinicsInfoLocation1"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/clinics-info_mobile_1.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_mobile_1.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_mobile_1.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_desktop_1.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_desktop_1.webp",
                        },
                    ],
                },
            },
            {
                flag: regions.fr,
                location: t("kids:clinicsInfoLocation2"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/clinics-info_mobile_2.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_mobile_2.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_mobile_2.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_desktop_2.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_desktop_2.webp",
                        },
                    ],
                },
            },
            {
                flag: regions.it,
                location: t("kids:clinicsInfoLocation3"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/clinics-info_mobile_3.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_mobile_3.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_mobile_3.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_desktop_3.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_desktop_3.webp",
                        },
                    ],
                },
            },
            {
                flag: regions.pt,
                location: t("kids:clinicsInfoLocation4"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/clinics-info_mobile_4.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_mobile_4.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_mobile_4.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_desktop_4.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_desktop_4.webp",
                        },
                    ],
                },
            },
            {
                flag: regions.gb,
                location: t("kids:clinicsInfoLocation5"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/clinics-info_mobile_5.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_mobile_5.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_mobile_5.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_desktop_5.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_desktop_5.webp",
                        },
                    ],
                },
            },
            {
                flag: regions.ch,
                location: t("kids:clinicsInfoLocation6"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/clinics-info_mobile_6.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_mobile_6.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_mobile_6.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_desktop_6.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_desktop_6.webp",
                        },
                    ],
                },
            },
            {
                flag: regions.de,
                location: t("kids:clinicsInfoLocation7"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/clinics-info_mobile_7.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_mobile_7.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_mobile_7.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_desktop_7.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_desktop_7.webp",
                        },
                    ],
                },
            },
            {
                flag: regions.ua,
                location: t("kids:clinicsInfoLocation8"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/clinics-info_mobile_8.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_mobile_8.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_mobile_8.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_desktop_8.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_desktop_8.webp",
                        },
                    ],
                },
            },
            {
                flag: regions.us,
                location: t("kids:clinicsInfoLocation9"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/clinics-info_mobile_9.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_mobile_9.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_mobile_9.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/clinics-info_desktop_9.jpg",
                            webp: "/images/new/kids/multipurpose/clinics-info_desktop_9.webp",
                        },
                    ],
                },
            },
        ],
        info: [
            {
                title: t("kids:clinicsInfoTitle1"),
                description: t("kids:clinicsInfoDescription1"),
            },
        ],
    },
    multipurpose: {
        items: [
            {
                description: t("kids:advantagesEl1Text1"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/advantage-first-group_mobile_1.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/advantage-first-group_mobile_1.jpg",
                            webp: "/images/new/kids/multipurpose/advantage-first-group_mobile_1.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/advantage-first-group_desktop_1.jpg",
                            webp: "/images/new/kids/multipurpose/advantage-first-group_desktop_1.webp",
                        },
                    ],
                },
                hasBorderImage: false,
            },
            {
                title: t("kids:advantagesEl2Text1"),
                description: t("kids:advantagesEl2Text2"),
                descriptionStyle: "title3",
                size: AdvantageItemSizeType.MEDIUM,
            },
            {
                description: t("kids:advantagesEl3Text1"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/advantage-first-group_mobile_4.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/advantage-first-group_mobile_4.jpg",
                            webp: "/images/new/kids/multipurpose/advantage-first-group_mobile_4.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/advantage-first-group_desktop_4.jpg",
                            webp: "/images/new/kids/multipurpose/advantage-first-group_desktop_4.webp",
                        },
                    ],
                },
                size: AdvantageItemSizeType.LARGE,
            },
            {
                title: t("kids:advantagesEl4Text2"),
                description: t("kids:advantagesEl4Text1"),
                isPositionReverse: true,
                size: AdvantageItemSizeType.LARGE,
            },
            {
                description: t("kids:advantagesEl5Text1"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/advantage-first-group_mobile_5.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/advantage-first-group_mobile_5.jpg",
                            webp: "/images/new/kids/multipurpose/advantage-first-group_mobile_5.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/advantage-first-group_desktop_5.jpg",
                            webp: "/images/new/kids/multipurpose/advantage-first-group_desktop_5.webp",
                        },
                    ],
                },
                hasBorderImage: false,
            },
            {
                description: t("kids:advantagesEl6Text1"),
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/advantage-second-group_mobile_2.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/advantage-second-group_mobile_2.jpg",
                            webp: "/images/new/kids/multipurpose/advantage-second-group_mobile_2.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/advantage-second-group_desktop_2.jpg",
                            webp: "/images/new/kids/multipurpose/advantage-second-group_desktop_2.webp",
                        },
                    ],
                },
            },
            {
                description: t("kids:advantagesEl7Text1"),
                descriptionStyle: "title3",
                image: {
                    defaultSrc:
                        "/images/new/kids/multipurpose/advantage-second-group_mobile_1.jpg",
                    images: [
                        {
                            jpeg: "/images/new/kids/multipurpose/advantage-second-group_mobile_1.jpg",
                            webp: "/images/new/kids/multipurpose/advantage-second-group_mobile_1.webp",
                        },
                        {
                            jpeg: "/images/new/kids/multipurpose/advantage-second-group_desktop_1.jpg",
                            webp: "/images/new/kids/multipurpose/advantage-second-group_desktop_1.webp",
                        },
                    ],
                },
                hasBorderImage: false,
            },
            {
                description: t("kids:advantagesEl8Text1"),
                descriptionStyle: "title3",
                size: AdvantageItemSizeType.LARGE,
                isPositionReverse: true,
                link: {
                    text: t("kids:advantagesEl8Text2"),
                    href: pathConfig.locations[version],
                },
            },
            {
                description: t("kids:advantagesEl9Text1"),
                descriptionStyle: "title3",
                size: AdvantageItemSizeType.MEDIUM,
                isPositionReverse: true,
                button: {
                    text: t("kids:advantagesEl9Text2"),
                    href: pathConfig.universalForm[version],
                },
            },
        ],
    },
    multipurposePointsBlock: {
        image: {
            desktop: "/images/new/kids/multipurpose-points-bg-desktop.jpg",
            mobile: "/images/new/kids/multipurpose-points-bg-mobile.jpg",
        },
        points: [...Array(3)].map((_, i) => ({
            icon: multipurposePointsIcons[i],
            title: t(`kids:multipurposePointsBlockTitleEl${i + 1}`),
            description: t(`kids:multipurposePointsBlockDescriptionEl${i + 1}`),
        })),
    },
    interceptiveTreatment: {
        title: t("kids:interceptiveTreatmentTitle"),
        text: t("kids:interceptiveTreatmentText"),
        image: {
            defaultSrc: `/images/new/kids/interceptive-treatment-mobile.jpg`,
            images: [
                {
                    jpeg: `/images/new/kids/interceptive-treatment-mobile.jpg`,
                    webp: `/images/new/kids/interceptive-treatment-mobile.webp`,
                },
                {
                    jpeg: `/images/new/kids/interceptive-treatment-desktop.jpg`,
                    webp: `/images/new/kids/interceptive-treatment-desktop.webp`,
                },
            ],
        },
        withTrustpilot: true,
    },
    improveHealth: {
        title: t("kids:improveHealthTitle"),
        description: t("kids:improveHealthDesc"),
        image: {
            images: [
                {
                    webp: `/images/new/kids/improve-health-mobile.webp`,
                    jpeg: `/images/new/kids/improve-health-mobile.jpg`,
                },
                {
                    webp: `/images/new/kids/improve-health-desktop.webp`,
                    jpeg: `/images/new/kids/improve-health-desktop.jpg`,
                },
            ],
        },
        list: [
            {
                title: t("kids:improveHealthItem1Title"),
                text: t("kids:improveHealthItem1Text"),
            },
            {
                title: t("kids:improveHealthItem2Title"),
                text: t("kids:improveHealthItem2Text"),
            },
            {
                title: t("kids:improveHealthItem3Title"),
                text: t("kids:improveHealthItem3Text"),
            },
            {
                title: t("kids:improveHealthItem4Title"),
                text: t("kids:improveHealthItem4Text"),
            },
        ],
        withCheckMarks: true,
    },
    howImpressWorks: {
        title: t("kids:howImpressWorksTitle"),
        description: t("kids:howImpressWorksDesc"),
        list: [
            {
                image: {
                    images: [
                        {
                            webp: `/images/new/kids/how-impress-works-1-mobile.webp`,
                            jpeg: `/images/new/kids/how-impress-works-1-mobile.jpg`,
                        },
                        {
                            webp: `/images/new/kids/how-impress-works-1-desktop.webp`,
                            jpeg: `/images/new/kids/how-impress-works-1-desktop.jpg`,
                        },
                    ],
                },
                title: t("kids:howImpressWorksItem1Title"),
                text: t("kids:howImpressWorksItem1Text"),
            },
            {
                image: {
                    images: [
                        {
                            webp: `/images/new/kids/how-impress-works-2-mobile.webp`,
                            jpeg: `/images/new/kids/how-impress-works-2-mobile.jpg`,
                        },
                        {
                            webp: `/images/new/kids/how-impress-works-2-desktop.webp`,
                            jpeg: `/images/new/kids/how-impress-works-2-desktop.jpg`,
                        },
                    ],
                },
                title: t("kids:howImpressWorksItem2Title"),
                text: t("kids:howImpressWorksItem2Text"),
            },
            {
                image: {
                    images: [
                        {
                            webp: `/images/new/kids/how-impress-works-3-mobile.webp`,
                            jpeg: `/images/new/kids/how-impress-works-3-mobile.jpg`,
                        },
                        {
                            webp: `/images/new/kids/how-impress-works-3-desktop.webp`,
                            jpeg: `/images/new/kids/how-impress-works-3-desktop.jpg`,
                        },
                    ],
                },
                title: t("kids:howImpressWorksItem3Title"),
                text: t("kids:howImpressWorksItem3Text"),
            },
            {
                image: {
                    images: [
                        {
                            webp: `/images/new/kids/how-impress-works-4-mobile.webp`,
                            jpeg: `/images/new/kids/how-impress-works-4-mobile.jpg`,
                        },
                        {
                            webp: `/images/new/kids/how-impress-works-4-desktop.webp`,
                            jpeg: `/images/new/kids/how-impress-works-4-desktop.jpg`,
                        },
                    ],
                },
                title: t("kids:howImpressWorksItem4Title"),
                text: t("kids:howImpressWorksItem4Text"),
            },
            {
                image: {
                    images: [
                        {
                            webp: `/images/new/kids/how-impress-works-5-mobile.webp`,
                            jpeg: `/images/new/kids/how-impress-works-5-mobile.jpg`,
                        },
                        {
                            webp: `/images/new/kids/how-impress-works-5-desktop.webp`,
                            jpeg: `/images/new/kids/how-impress-works-5-desktop.jpg`,
                        },
                    ],
                },
                text: t("kids:howImpressWorksItem5Text"),
                links: [
                    {
                        text: t("kids:howImpressWorksItem5LinkText"),
                        to: pathConfig.universalForm[version],
                    },
                ],
            },
        ],
    },
    ImpressExperience: {
        title: t("kids:ImpressExperienceTitle"),
        list: [
            {
                title: t("kids:ImpressExperienceListEl1Title"),
                text: t("kids:ImpressExperienceListEl1Text"),
                image: {
                    images: [
                        {
                            webp: `/images/new/kids/impress-experience-1-mobile.webp`,
                            jpeg: `/images/new/kids/impress-experience-1-mobile.jpg`,
                        },
                        {
                            webp: `/images/new/kids/impress-experience-1-desktop.webp`,
                            jpeg: `/images/new/kids/impress-experience-1-desktop.jpg`,
                        },
                    ],
                    defaultSrc: `/images/new/kids/impress-experience-1-desktop.jpg`,
                },
            },
            {
                title: t("kids:ImpressExperienceListEl2Title"),
                text: t("kids:ImpressExperienceListEl2Text"),
                image: {
                    images: [
                        {
                            webp: `/images/new/kids/impress-experience-2-mobile.webp`,
                            jpeg: `/images/new/kids/impress-experience-2-mobile.jpg`,
                        },
                        {
                            webp: `/images/new/kids/impress-experience-2-desktop.webp`,
                            jpeg: `/images/new/kids/impress-experience-2-desktop.jpg`,
                        },
                    ],
                    defaultSrc: `/images/new/kids/impress-experience-2-desktop.jpg`,
                },
            },
        ],
    },
    priceBlock: {
        title: t("kids:priceBlockTitle"),
        description: t("kids:priceBlockDescription"),
    },
    appointment: {
        title: t("global:bookAppointmentButton"),
        image: {
            images: [
                {
                    jpeg: "/images/new/main/form-mobile.jpg",
                    webp: "/images/new/main/form-mobile.webp",
                },
                {
                    jpeg: "/images/new/main/form-desktop.jpg",
                    webp: "/images/new/main/form-desktop.webp",
                },
            ],
            defaultSrc: "/images/new/main/form-desktop.jpg",
        },
    },
});

export default pageContent;
