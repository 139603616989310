import { PictureProps } from "@smile2impress/components/lib/@types/primitives/Picture/Picture.types";

export enum AdvantageItemSizeType {
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
}

export interface AdvantageItemProps {
    image?: PictureProps;
    title?: string;
    description?: string;
    descriptionStyle?: "title2" | "title3";
    size?: AdvantageItemSizeType;
    isPositionReverse?: boolean;
    hasBorderImage?: boolean;
    isMobile?: boolean;
    link?: {
        text: string;
        href: string;
    };
    button?: {
        text: string;
        href: string;
    };
}
