import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import defaultContent from "./spain";

const pageContent: any = (t: Translate) => {
    const content = defaultContent(t);

    return mergeDeepRight(content, {
        layout: {
            website: {
                url: "/ua",
            },
        },
    });
};

export default pageContent;
