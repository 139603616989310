import React, { FunctionComponent } from "react";
import classNames from "classnames/bind";
import { Text, Heading } from "@smile2impress/components";

import { BookingStepsProps } from "./BookingSteps.types";
import styles from "./BookingSteps.module.scss";

const cx = classNames.bind(styles);

export const BookingSteps: FunctionComponent<BookingStepsProps> = ({
    list = [],
    activeStepName,
    className,
}) => {
    const activeStepItem = list.find(
        (item) => item.stepName === activeStepName
    );

    return (
        <section className={cx("root", className)}>
            <ul className={cx("steps-list")}>
                {list.map((item, i) => {
                    const { stepName, text } = item;
                    const isActiveStep = activeStepName === stepName;
                    const isLastItem = i + 1 === list.length;
                    const isCompleteStep =
                        activeStepItem && activeStepItem.index > i + 1;

                    return (
                        <Text
                            as="li"
                            textStyle="title4"
                            justifyContent="center"
                            display="flex"
                            alignItems="center"
                            fontWeight="bold"
                            mr={isLastItem ? "0" : "2"}
                            boxSize={4}
                            borderRadius="50%"
                            borderColor={
                                isActiveStep ? "action.primary2Inverse" : ""
                            }
                            borderWidth={isActiveStep ? "2px" : ""}
                            backgroundColor={
                                isCompleteStep
                                    ? "action.primary2Inverse"
                                    : isActiveStep
                                    ? "base.primary"
                                    : "line.secondary"
                            }
                            color={
                                isCompleteStep
                                    ? "base.primary"
                                    : isActiveStep
                                    ? "action.primary2Inverse"
                                    : "text.secondary"
                            }
                            key={stepName}
                        >
                            {text}
                        </Text>
                    );
                })}
            </ul>

            <div className={cx("steps-title")}>
                {list.map((item) => {
                    const { stepName, title } = item;

                    return (
                        activeStepName === stepName && (
                            <Heading
                                mb={["0", "3"]}
                                as="h3"
                                key={`title${stepName}`}
                                textStyle="title1"
                            >
                                {title}
                            </Heading>
                        )
                    );
                })}
            </div>
        </section>
    );
};
