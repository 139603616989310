import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent: any = (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        mainBanner: {
            imageDesktop: "/images/new/whyImpress/bg-main.jpg",
            imageMobile: "/images/new/whyImpress/bg-main-mobile.jpg",
            title: t("whyImpress:mainBannerTitle"),
        },
    });

export default pageContent;
