import { Translate } from "next-translate";

import { BookingStepType } from "components/BookingSteps";

import { RemarketingC2PageContent } from "./content.types";

const pageContent = (t: Translate): RemarketingC2PageContent => ({
    header: {
        subtitle: t("c2:headerSubtitle"),
        title: t("c2:headerTitle"),
        button: {
            title: t("global:bookAppointmentButton"),
        },
    },
    steps: [
        {
            text: "1",
            index: 1,
            title: t("c2:stepsTitle1"),
            stepName: BookingStepType.FIRST,
        },
        {
            text: "2",
            index: 2,
            title: t("c2:stepsTitle2"),
            stepName: BookingStepType.SECOND,
        },
        {
            text: "3",
            index: 3,
            title: t("c2:stepsTitle3"),
            stepName: BookingStepType.THIRD,
        },
    ],
    advantages: {
        title: t("c2:advantagesTitle"),
        list: [
            {
                title: t("c2:advantagesListTitle1"),
                description: t("c2:advantagesListDescription1"),
                image: {
                    mobile: {
                        jpeg: "/images/c2/doctors-mobile-2x.jpg",
                        webp: "/images/c2/doctors-mobile-2x.webp",
                    },
                    desktop: {
                        jpeg: "/images/c2/doctors-desktop-2x.jpg",
                        webp: "/images/c2/doctors-desktop-2x.webp",
                    },
                },
            },
            {
                title: t("c2:advantagesListTitle2"),
                description: t("c2:advantagesListDescription2"),
                image: {
                    mobile: {
                        jpeg: "/images/first-visit/first-visit-1-mobile.jpg",
                        webp: "/images/first-visit/first-visit-1-mobile.webp",
                    },
                    desktop: {
                        jpeg: "/images/first-visit/first-visit-1-desktop.jpg",
                        webp: "/images/first-visit/first-visit-1-desktop.webp",
                    },
                },
            },
            {
                title: t("c2:advantagesListTitle2"),
                description: t("c2:advantagesListDescription2"),
                image: {
                    mobile: {
                        jpeg: "/images/first-visit/first-visit-4-mobile.jpg",
                        webp: "/images/first-visit/first-visit-4-mobile.webp",
                    },
                    desktop: {
                        jpeg: "/images/first-visit/first-visit-4-desktop.jpg",
                        webp: "/images/first-visit/first-visit-4-desktop.webp",
                    },
                },
            },
        ],
    },
    slides: {
        title: t("c2:slidesTitle"),
        images: {
            barcelona: [
                {
                    mobile: {
                        webp: "/images/clinics-slides/barcelona/barcelona-clinic-mobile_1.webp",
                        jpeg: "/images/clinics-slides/barcelona/barcelona-clinic-mobile_1.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/barcelona/barcelona-clinic-desktop_1.webp",
                        jpeg: "/images/clinics-slides/barcelona/barcelona-clinic-desktop_1.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/barcelona/barcelona-clinic-mobile_2.webp",
                        jpeg: "/images/clinics-slides/barcelona/barcelona-clinic-mobile_2.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/barcelona/barcelona-clinic-desktop_2.webp",
                        jpeg: "/images/clinics-slides/barcelona/barcelona-clinic-desktop_2.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/barcelona/barcelona-clinic-mobile_3.webp",
                        jpeg: "/images/clinics-slides/barcelona/barcelona-clinic-mobile_3.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/barcelona/barcelona-clinic-desktop_3.webp",
                        jpeg: "/images/clinics-slides/barcelona/barcelona-clinic-desktop_3.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/barcelona/barcelona-clinic-mobile_4.webp",
                        jpeg: "/images/clinics-slides/barcelona/barcelona-clinic-mobile_4.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/barcelona/barcelona-clinic-desktop_4.webp",
                        jpeg: "/images/clinics-slides/barcelona/barcelona-clinic-desktop_4.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/barcelona/barcelona-clinic-mobile_5.webp",
                        jpeg: "/images/clinics-slides/barcelona/barcelona-clinic-mobile_5.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/barcelona/barcelona-clinic-desktop_5.webp",
                        jpeg: "/images/clinics-slides/barcelona/barcelona-clinic-desktop_5.jpg",
                    },
                },
            ],
            madrid: [
                {
                    mobile: {
                        webp: "/images/clinics-slides/madrid/madrid-clinic-mobile_1.webp",
                        jpeg: "/images/clinics-slides/madrid/madrid-clinic-mobile_1.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/madrid/madrid-clinic-desktop_1.webp",
                        jpeg: "/images/clinics-slides/madrid/madrid-clinic-desktop_1.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/madrid/madrid-clinic-mobile_2.webp",
                        jpeg: "/images/clinics-slides/madrid/madrid-clinic-mobile_2.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/madrid/madrid-clinic-desktop_2.webp",
                        jpeg: "/images/clinics-slides/madrid/madrid-clinic-desktop_2.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/madrid/madrid-clinic-mobile_3.webp",
                        jpeg: "/images/clinics-slides/madrid/madrid-clinic-mobile_3.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/madrid/madrid-clinic-desktop_3.webp",
                        jpeg: "/images/clinics-slides/madrid/madrid-clinic-desktop_3.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/madrid/madrid-clinic-mobile_4.webp",
                        jpeg: "/images/clinics-slides/madrid/madrid-clinic-mobile_4.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/madrid/madrid-clinic-desktop_4.webp",
                        jpeg: "/images/clinics-slides/madrid/madrid-clinic-desktop_4.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/madrid/madrid-clinic-mobile_5.webp",
                        jpeg: "/images/clinics-slides/madrid/madrid-clinic-mobile_5.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/madrid/madrid-clinic-desktop_5.webp",
                        jpeg: "/images/clinics-slides/madrid/madrid-clinic-desktop_5.jpg",
                    },
                },
            ],
            mallorca: [
                {
                    mobile: {
                        webp: "/images/clinics-slides/mallorca/mallorca-clinic-mobile_1.webp",
                        jpeg: "/images/clinics-slides/mallorca/mallorca-clinic-mobile_1.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/mallorca/mallorca-clinic-desktop_1.webp",
                        jpeg: "/images/clinics-slides/mallorca/mallorca-clinic-desktop_1.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/mallorca/mallorca-clinic-mobile_2.webp",
                        jpeg: "/images/clinics-slides/mallorca/mallorca-clinic-mobile_2.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/mallorca/mallorca-clinic-desktop_2.webp",
                        jpeg: "/images/clinics-slides/mallorca/mallorca-clinic-desktop_2.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/mallorca/mallorca-clinic-mobile_3.webp",
                        jpeg: "/images/clinics-slides/mallorca/mallorca-clinic-mobile_3.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/mallorca/mallorca-clinic-desktop_3.webp",
                        jpeg: "/images/clinics-slides/mallorca/mallorca-clinic-desktop_3.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/mallorca/mallorca-clinic-mobile_4.webp",
                        jpeg: "/images/clinics-slides/mallorca/mallorca-clinic-mobile_4.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/mallorca/mallorca-clinic-desktop_4.webp",
                        jpeg: "/images/clinics-slides/mallorca/mallorca-clinic-desktop_4.jpg",
                    },
                },
            ],
            valencia: [
                {
                    mobile: {
                        webp: "/images/clinics-slides/valencia/valencia-clinic-mobile_1.webp",
                        jpeg: "/images/clinics-slides/valencia/valencia-clinic-mobile_1.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/valencia/valencia-clinic-desktop_1.webp",
                        jpeg: "/images/clinics-slides/valencia/valencia-clinic-desktop_1.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/valencia/valencia-clinic-mobile_2.webp",
                        jpeg: "/images/clinics-slides/valencia/valencia-clinic-mobile_2.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/valencia/valencia-clinic-desktop_2.webp",
                        jpeg: "/images/clinics-slides/valencia/valencia-clinic-desktop_2.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/valencia/valencia-clinic-mobile_3.webp",
                        jpeg: "/images/clinics-slides/valencia/valencia-clinic-mobile_3.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/valencia/valencia-clinic-desktop_3.webp",
                        jpeg: "/images/clinics-slides/valencia/valencia-clinic-desktop_3.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/valencia/valencia-clinic-mobile_4.webp",
                        jpeg: "/images/clinics-slides/valencia/valencia-clinic-mobile_4.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/valencia/valencia-clinic-desktop_4.webp",
                        jpeg: "/images/clinics-slides/valencia/valencia-clinic-desktop_4.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/valencia/valencia-clinic-mobile_5.webp",
                        jpeg: "/images/clinics-slides/valencia/valencia-clinic-mobile_5.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/valencia/valencia-clinic-desktop_5.webp",
                        jpeg: "/images/clinics-slides/valencia/valencia-clinic-desktop_5.jpg",
                    },
                },
            ],
            bilbao: [
                {
                    mobile: {
                        webp: "/images/clinics-slides/bilbao/bilbao-clinic-mobile_1.webp",
                        jpeg: "/images/clinics-slides/bilbao/bilbao-clinic-mobile_1.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/bilbao/bilbao-clinic-desktop_1.webp",
                        jpeg: "/images/clinics-slides/bilbao/bilbao-clinic-desktop_1.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/bilbao/bilbao-clinic-mobile_2.webp",
                        jpeg: "/images/clinics-slides/bilbao/bilbao-clinic-mobile_2.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/bilbao/bilbao-clinic-desktop_2.webp",
                        jpeg: "/images/clinics-slides/bilbao/bilbao-clinic-desktop_2.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/bilbao/bilbao-clinic-mobile_3.webp",
                        jpeg: "/images/clinics-slides/bilbao/bilbao-clinic-mobile_3.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/bilbao/bilbao-clinic-desktop_3.webp",
                        jpeg: "/images/clinics-slides/bilbao/bilbao-clinic-desktop_3.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/bilbao/bilbao-clinic-mobile_4.webp",
                        jpeg: "/images/clinics-slides/bilbao/bilbao-clinic-mobile_4.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/bilbao/bilbao-clinic-desktop_4.webp",
                        jpeg: "/images/clinics-slides/bilbao/bilbao-clinic-desktop_4.jpg",
                    },
                },
            ],
            sevilla: [
                {
                    mobile: {
                        webp: "/images/clinics-slides/sevilla/sevilla-clinic-mobile_1.webp",
                        jpeg: "/images/clinics-slides/sevilla/sevilla-clinic-mobile_1.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/sevilla/sevilla-clinic-desktop_1.webp",
                        jpeg: "/images/clinics-slides/sevilla/sevilla-clinic-desktop_1.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/sevilla/sevilla-clinic-mobile_2.webp",
                        jpeg: "/images/clinics-slides/sevilla/sevilla-clinic-mobile_2.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/sevilla/sevilla-clinic-desktop_2.webp",
                        jpeg: "/images/clinics-slides/sevilla/sevilla-clinic-desktop_2.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/sevilla/sevilla-clinic-mobile_3.webp",
                        jpeg: "/images/clinics-slides/sevilla/sevilla-clinic-mobile_3.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/sevilla/sevilla-clinic-desktop_3.webp",
                        jpeg: "/images/clinics-slides/sevilla/sevilla-clinic-desktop_3.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/sevilla/sevilla-clinic-mobile_4.webp",
                        jpeg: "/images/clinics-slides/sevilla/sevilla-clinic-mobile_4.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/sevilla/sevilla-clinic-desktop_4.webp",
                        jpeg: "/images/clinics-slides/sevilla/sevilla-clinic-desktop_4.jpg",
                    },
                },
            ],
            malaga: [
                {
                    mobile: {
                        webp: "/images/clinics-slides/malaga/malaga-clinic-mobile_1.webp",
                        jpeg: "/images/clinics-slides/malaga/malaga-clinic-mobile_1.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/malaga/malaga-clinic-desktop_1.webp",
                        jpeg: "/images/clinics-slides/malaga/malaga-clinic-desktop_1.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/malaga/malaga-clinic-mobile_2.webp",
                        jpeg: "/images/clinics-slides/malaga/malaga-clinic-mobile_2.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/malaga/malaga-clinic-desktop_2.webp",
                        jpeg: "/images/clinics-slides/malaga/malaga-clinic-desktop_2.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/malaga/malaga-clinic-mobile_3.webp",
                        jpeg: "/images/clinics-slides/malaga/malaga-clinic-mobile_3.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/malaga/malaga-clinic-desktop_3.webp",
                        jpeg: "/images/clinics-slides/malaga/malaga-clinic-desktop_3.jpg",
                    },
                },
                {
                    mobile: {
                        webp: "/images/clinics-slides/malaga/malaga-clinic-mobile_4.webp",
                        jpeg: "/images/clinics-slides/malaga/malaga-clinic-mobile_4.jpg",
                    },
                    desktop: {
                        webp: "/images/clinics-slides/malaga/malaga-clinic-desktop_4.webp",
                        jpeg: "/images/clinics-slides/malaga/malaga-clinic-desktop_4.jpg",
                    },
                },
            ],
        },
    },
    tabs: [
        {
            value: "barcelona",
            title: "Barcelona",
        },
        {
            value: "madrid",
            title: "Madrid",
        },
        {
            value: "valencia",
            title: "Valencia",
        },
        {
            value: "bilbao",
            title: "Bilbao",
        },
        {
            value: "mallorca",
            title: "Mallorca",
        },
        {
            value: "sevilla",
            title: "Sevilla",
        },
        {
            value: "malaga",
            title: "Málaga",
        },
    ],
    cta: {
        title: t("c2:ctaTitle"),
        button: {
            title: t("global:bookAppointmentButton"),
        },
    },
    image: {
        desktop: {
            webp: "/images/booking-widget/clinic.webp",
            jpeg: "/images/booking-widget/clinic.jpg",
        },
    },
});

export default pageContent;
