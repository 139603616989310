import { FC } from "react";
import { Box, Button, Text, Link } from "@smile2impress/components";

import { sanitizeHtml } from "utils/sanitizeHtml";

import { HeadingContentBlockProps } from "./contentBlocks.types";

export const HeadingBlock: FC<HeadingContentBlockProps> = ({ content }) => {
    const { title, subtitle, button } = content;
    return (
        <Box mb={[4, 8]} px={[3, 3, 0]}>
            {subtitle && (
                <Text mb={[3, 3]} textStyle="title2" color="action.primary2">
                    {subtitle}
                </Text>
            )}
            <Box maxW={["100%", "900px"]}>
                <Text
                    textStyle={["display1"]}
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(title),
                    }}
                ></Text>
            </Box>
            {button && (
                <Button
                    mt={[3, 3]}
                    textStyle="body"
                    as={Link}
                    href={button.link}
                    size="md"
                    px={3}
                    py={2}
                    fontWeight={600}
                >
                    {button.text}
                </Button>
            )}
        </Box>
    );
};
