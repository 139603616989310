import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent: any = (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        legalAddress: null,
        mainBanner: {
            image: {
                desktop: {
                    webp: `/images/about/team-desktop.webp`,
                    jpeg: `/images/about/team-desktop.jpg`,
                    media: "(min-width: 1024px)",
                },
                mobile: {
                    webp: `/images/about/team-mobile.webp`,
                    jpeg: `/images/about/team-mobile.jpg`,
                },
            },
        },
        perfectSmile: {
            quote: {
                imageDesktop: "/images/about/perfect-smile-doctor-germany.jpeg",
                text: "",
            },
            image: {
                desktop: {
                    webp: "/images/about/perfect-smile-germany.webp",
                    jpeg: "/images/about/perfect-smile-germany.jpeg",
                },
            },
        },
        likeNeverBefore: {
            image: {
                desktop: {
                    webp: "/images/about/show-aligner-germany.webp",
                    jpeg: "/images/about/show-aligner-germany.jpg",
                },
            },
        },
        results: {
            stories: [
                {
                    mobile: {
                        webp: "/images/about/story-1-germany.webp",
                        jpeg: "/images/about/story-1-germany.jpeg",
                    },
                    desktop: {
                        webp: "/images/about/story-2-desktop-germany.webp",
                        jpeg: "/images/about/story-2-desktop-germany.jpeg",
                        media: "(min-width: 1024px)",
                    },
                },
                {
                    mobile: {
                        webp: "/images/about/story-3-germany.webp",
                        jpeg: "/images/about/story-3-germany.jpeg",
                    },
                    desktop: {
                        webp: "/images/about/story-3-desktop-germany.webp",
                        jpeg: "/images/about/story-3-desktop-germany.jpeg",
                        media: "(min-width: 1024px)",
                    },
                },
                {
                    mobile: {
                        webp: "/images/about/story-2-germany.webp",
                        jpeg: "/images/about/story-2-germany.jpeg",
                    },
                    desktop: {
                        webp: "/images/about/story-1-desktop-germany.webp",
                        jpeg: "/images/about/story-1-desktop-germany.jpeg",
                        media: "(min-width: 1024px)",
                    },
                },
            ],
        },
        partOfChanges: {
            image: {
                desktop: {
                    webp: "/images/about/doctors-desktop-germany.webp",
                    jpeg: "/images/about/doctors-desktop-germany.jpeg",
                    media: "(min-width: 1024px)",
                },
                mobile: {
                    webp: "/images/about/doctors-mobile-germany.webp",
                    jpeg: "/images/about/doctors-mobile-germany.jpeg",
                },
            },
            quote: {
                imageDesktop: "",
                text: "",
            },
        },
    });

export default pageContent;
