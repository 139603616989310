import { FC } from "react";
import { Box } from "@smile2impress/components";

import { TmpUniversalTitleWithImageProps } from "./TmpUniversalTitleWithImage.types";
import { renderContentBlock } from "./components/contentBlocks";

export const TmpUniversalTitleWithImage: FC<TmpUniversalTitleWithImageProps> =
    ({ content }) => {
        return (
            <Box>
                {content.map((block, i) => (
                    <Box key={i} mb={[3, 10]} _last={{ mb: 0 }}>
                        {renderContentBlock(block)}
                    </Box>
                ))}
            </Box>
        );
    };
