import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";
import {
    TmpImageTextAndPriceAreaTypes,
    TmpImageTextAndPriceBlocksTypes,
} from "components/templates/TmpImageTextAndPrice/TmpImageTextAndPrice.types";

import defaultContent, { generateListKeys } from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const translatedContent = defaultContent(t, { version });
    const { universalBlock } = translatedContent;

    return mergeDeepRight(translatedContent, {
        universalBlock: [
            ...universalBlock.slice(0, 3),
            [
                {
                    type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                    leftContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.IMAGE,
                            content: {
                                image: {
                                    images: [
                                        {
                                            jpeg: "/images/new/prices/advanced/advanced-clinic-ua-mobile.jpg",
                                            webp: "/images/new/prices/advanced/advanced-clinic-ua-mobile.webp",
                                        },
                                        {
                                            jpeg: "/images/new/prices/advanced/advanced-clinic-ua-desktop.jpg",
                                            webp: "/images/new/prices/advanced/advanced-clinic-ua-desktop.webp",
                                        },
                                    ],
                                    defaultSrc:
                                        "/images/new/prices/advanced/advanced-clinic-ua-desktop.jpg",
                                },
                            },
                        },
                    ],
                    rightContent: [
                        {
                            type: TmpImageTextAndPriceBlocksTypes.LIST,
                            content: {
                                list: generateListKeys(t, 4, 1),
                                title: t("advanced:universalBlock4List1Title"),
                            },
                        },
                    ],
                },
            ],
        ],
    });
};

export default pageContent;
