import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent: any = (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        legalAddress: null,
    });

export default pageContent;
