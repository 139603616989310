import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const content = defaultContent(t, { version });

    return mergeDeepRight(content, {
        layout: {
            header: { withHeader: false },
            footer: { withFooter: false },
        },
    });
};

export default pageContent;
