import { FC } from "react";
import { Box, Picture } from "@smile2impress/components";

import { ImageContentBlockProps } from "./contentBlocks.types";

export const ImageBlock: FC<ImageContentBlockProps> = ({
    content,
    stretchTop = [false, false],
    stretchBottom = [false, false],
}) => {
    const { image } = content;
    const [stM, stD] = stretchTop;
    const [sbM, sbD] = stretchBottom;
    return (
        <Box
            mt={[stM ? -5 : 0, stD ? -10 : 0]}
            mb={[sbM ? -5 : 0, sbD ? -10 : 0]}
            mx={[-3, -10]}
        >
            <Picture {...image} fit="cover" />
        </Box>
    );
};
