import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";
import { getDesignPostfix } from "utils/getDesignPostfix";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const { newDesignPostfix } = getDesignPostfix(version);

    return mergeDeepRight(defaultContent(t, { version }), {
        ourResults: {
            reviews: [
                {
                    image: {
                        desktop: {
                            webp: "/images/new/ourResults/germany/review_desktop_1.webp",
                            jpeg: "/images/new/ourResults/germany/review_desktop_1.jpg",
                        },
                        mobile: {
                            webp: "/images/new/ourResults/germany/review_mobile_1.webp",
                            jpeg: "/images/new/ourResults/germany/review_mobile_1.jpg",
                        },
                    },
                    hasTrustpilotRating: false,
                },
                {
                    image: {
                        desktop: {
                            webp: "/images/new/ourResults/germany/review_desktop_2.webp",
                            jpeg: "/images/new/ourResults/germany/review_desktop_2.jpg",
                        },
                        mobile: {
                            webp: "/images/new/ourResults/germany/review_mobile_2.webp",
                            jpeg: "/images/new/ourResults/germany/review_mobile_2.jpg",
                        },
                    },
                    hasTrustpilotRating: false,
                },
                {
                    image: {
                        desktop: {
                            webp: "/images/new/ourResults/germany/review_desktop_3.webp",
                            jpeg: "/images/new/ourResults/germany/review_desktop_3.jpg",
                        },
                        mobile: {
                            webp: "/images/new/ourResults/germany/review_mobile_3.webp",
                            jpeg: "/images/new/ourResults/germany/review_mobile_3.jpg",
                        },
                    },
                    hasTrustpilotRating: false,
                },
            ],
        },
        hygiene: {
            image: {
                mobile: {
                    webp: `/images/results/hygiene-mobile${newDesignPostfix}.webp`,
                    jpeg: `/images/results/hygiene-mobile${newDesignPostfix}.jpg`,
                },
                desktop: {
                    webp: `/images/results/hygiene-desktop${newDesignPostfix}.webp`,
                    jpeg: `/images/results/hygiene-desktop${newDesignPostfix}.jpg`,
                },
            },
        },
    });
};

export default pageContent;
