import { Translate } from "next-translate";

import prices from "configs/prices.json";
import { priceFormatter } from "utils/priceFormatter";
import { PageContentProps } from "content/content.types";
import { pathConfig } from "navigation/pathConfig";

export default (t: Translate, { version }: PageContentProps) => ({
    title: t("referralForFriends:title"),
    subtitle: t("referralForFriends:subtitle"),
    description: t("referralForFriends:description", {
        discount: priceFormatter(
            prices.discount.referralForFriends[version] as string,
            prices.currency[version],
            version
        ),
    }),
    image: {
        mobile: {
            jpeg: "/images/referral/referral-mobile_100.jpg",
            webp: "/images/referral/referral-mobile_100.webp",
        },
        desktop: {
            jpeg: "/images/referral/referral-desktop_100.jpg",
            webp: "/images/referral/referral-desktop_100.webp",
        },
    },
    buttonText: t("referralForFriends:buttonText"),
    toastText: t("referralForFriends:buttonCopiedText"),
    terms: {
        title: t("referralForFriends:termsTitle"),
        link: pathConfig.referralTerms[version as string],
    },
});
