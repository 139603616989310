import { Translate } from "next-translate";

import prices from "configs/prices.json";
import contacts from "configs/contacts.json";
import { priceFormatter } from "utils/priceFormatter";
import { PageContentProps } from "content/content.types";
import { pathConfig } from "navigation/pathConfig";

export default (t: Translate, { version }: PageContentProps) => ({
    main: {
        title: t("referral:title"),
        description: t("referral:description", {
            discount: priceFormatter(
                prices.discount.referral[version],
                prices.currency[version],
                version
            ),
        }),
        image: {
            mobile: {
                jpeg: "/images/referral/referral-main-mobile.jpg",
                webp: "/images/referral/referral-main-mobile.webp",
            },
            desktop: {
                jpeg: "/images/referral/referral-main-desktop.jpg",
                webp: "/images/referral/referral-main-desktop.webp",
                media: "(min-width: 1024px)",
            },
            desktopL: {
                jpeg: "/images/referral/referral-main-desktop-L.jpg",
                webp: "/images/referral/referral-main-desktop-L.webp",
                media: "(min-width: 1440px)",
            },
        },
        badge: {
            mobile: {
                png: `/images/referral/referral-badge.png`,
            },
        },
    },
    form: {
        title: t("global:bookAppointmentButton"),
    },
    ourResults: {
        title: t("ourResults:title"),
        reviews: [
            {
                name: "Gemma",
                subtitle: t("global:resultsSubtitle"),
                treatmentPlan: "Impress Lite",
                content: t("ourResults:reviewContent1"),
                image: {
                    desktop: {
                        webp: "/images/our-results/review_desktop_1.webp",
                        jpeg: "/images/our-results/review_desktop_1.jpg",
                    },
                    mobile: {
                        webp: "/images/our-results/review_mobile_1.webp",
                        jpeg: "/images/our-results/review_mobile_1.jpg",
                    },
                },
            },
            {
                name: "Joaquim",
                subtitle: t("global:resultsSubtitle"),
                treatmentPlan: "Impress Lite",
                content: t("ourResults:reviewContent2"),
                image: {
                    desktop: {
                        webp: "/images/our-results/review_desktop_2.webp",
                        jpeg: "/images/our-results/review_desktop_2.jpg",
                    },
                    mobile: {
                        webp: "/images/our-results/review_mobile_2.webp",
                        jpeg: "/images/our-results/review_mobile_2.jpg",
                    },
                },
            },
            {
                name: "Neus",
                subtitle: t("global:resultsSubtitle"),
                treatmentPlan: "Impress Lite",
                content: t("ourResults:reviewContent3"),
                image: {
                    desktop: {
                        webp: "/images/our-results/review_desktop_3.webp",
                        jpeg: "/images/our-results/review_desktop_3.jpg",
                    },
                    mobile: {
                        webp: "/images/our-results/review_mobile_3.webp",
                        jpeg: "/images/our-results/review_mobile_3.jpg",
                    },
                },
            },
        ],
        link: {
            icon: "trustpilotFull",
            to: contacts.trustpilot[version].link,
        },
    },
    terms: {
        title: t("referral:termsTitle"),
        link: pathConfig.referralTerms[version as string],
    },
});
