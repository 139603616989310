import { Translate } from "next-translate";

import { ThanksTextLayoutContent } from "layouts/pages/ThanksTextLayout/ThanksTextLayout.types";

const pageContent: any = (t: Translate): ThanksTextLayoutContent => ({
    title: t("thanks-text:title"),
    layout: {
        website: {
            url: "https://smilike.me/",
        },
        header: { withHeader: false },
        footer: { withFooter: false },
    },
});

export default pageContent;
