import { Translate } from "next-translate";

import {
    TmpImageTextAndPriceBlocksTypes,
    TmpImageTextAndPriceAreaTypes,
} from "components/templates/TmpImageTextAndPrice";
import prices from "configs/prices.json";
import { PageContentProps } from "content/content.types";

export const generateListKeys = (
    t: Translate,
    blockIndex: number,
    listIndex: number,
    totalElements = 10
) => {
    return new Array(totalElements)
        .fill(null)
        .map((_, i) =>
            t(
                `advanced:universalBlock${blockIndex}List${listIndex}El${
                    i + 1
                }`,
                {},
                { fallback: "" }
            )
        )
        .filter((e) => e);
};

const pageContent = (t: Translate, { version }: PageContentProps) => ({
    headerBanner: {
        title: t("advanced:headerTitle"),
        comment: t(`advanced:headerComment.${version}`),
        image: {
            defaultSrc: `/images/new/prices/advanced/advanced-header-mobile.jpg`,
            images: [
                {
                    jpeg: `/images/new/prices/advanced/advanced-header-mobile.jpg`,
                    webp: `/images/new/prices/advanced/advanced-header-mobile.webp`,
                },
                {
                    jpeg: `/images/new/prices/advanced/advanced-header-desktop.jpg`,
                    webp: `/images/new/prices/advanced/advanced-header-desktop.webp`,
                },
            ],
            rounded: true,
        },
    },
    universalBlock: [
        [
            {
                type: TmpImageTextAndPriceAreaTypes.FULL_WIDTH,
                content: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.HEADING,
                        content: {
                            title: t("advanced:universalBlock1Title"),
                            subtitle: t("advanced:universalBlock1Subtitle"),
                        },
                    },
                ],
            },
            {
                type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                leftContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.LIST,
                        content: {
                            list: generateListKeys(t, 1, 1),
                            title: t("advanced:universalBlock1List1Title"),
                        },
                    },
                    {
                        type: TmpImageTextAndPriceBlocksTypes.LIST,
                        content: {
                            list: generateListKeys(t, 1, 2),
                            title: t("advanced:universalBlock1List2Title"),
                        },
                    },
                ],
                rightContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.PRICE,
                        content: {
                            duration: t(
                                "advanced:universalBlockPriceDurationLabel",
                                {
                                    duration:
                                        prices.advancedPrice.baseDuration[
                                            version
                                        ],
                                }
                            ),
                            price:
                                prices.advancedPrice.monthly.baseWD[version] ||
                                prices.advancedPrice.monthly.base[version],
                            financationInfo: t(
                                `commonPricesNew:financationInfoCase4.${version}`
                            ),
                        },
                    },
                ],
            },
        ],
        [
            {
                type: TmpImageTextAndPriceAreaTypes.FULL_WIDTH,
                content: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.HEADING,
                        content: {
                            title: t("advanced:universalBlock2Title"),
                            subtitle: t("advanced:universalBlock2Subtitle"),
                        },
                    },
                ],
            },
            {
                type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                leftContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.LIST,
                        content: {
                            list: generateListKeys(t, 2, 1),
                            title: t("advanced:universalBlock2List1Title"),
                        },
                    },
                    {
                        type: TmpImageTextAndPriceBlocksTypes.LIST,
                        content: {
                            list: generateListKeys(t, 2, 2),
                            title: t("advanced:universalBlock2List2Title"),
                        },
                    },
                ],
                rightContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.PRICE,
                        content: {
                            duration: t(
                                "advanced:universalBlockPriceDurationLabel",
                                {
                                    duration:
                                        prices.advancedPrice.baseDuration[
                                            version
                                        ],
                                }
                            ),
                            price:
                                prices.advancedPrice.monthly.baseWD[version] ||
                                prices.advancedPrice.monthly.base[version],
                            financationInfo: t(
                                `commonPricesNew:financationInfoCase4.${version}`
                            ),
                        },
                    },
                ],
            },
        ],
        [
            {
                type: TmpImageTextAndPriceAreaTypes.FULL_WIDTH,
                content: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.HEADING,
                        content: {
                            title: t("advanced:universalBlock3Title"),
                            subtitle: t("advanced:universalBlock3Subtitle"),
                        },
                    },
                ],
            },
            {
                type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                leftContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.LIST,
                        content: {
                            list: generateListKeys(t, 3, 1),
                            title: t("advanced:universalBlock3List1Title"),
                        },
                    },
                    {
                        type: TmpImageTextAndPriceBlocksTypes.LIST,
                        content: {
                            list: generateListKeys(t, 3, 2),
                            title: t("advanced:universalBlock3List2Title"),
                        },
                    },
                ],
                rightContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.PRICE,
                        content: {
                            duration: t(
                                "advanced:universalBlockPriceDurationLabel",
                                {
                                    duration:
                                        prices.advancedPrice.baseDuration[
                                            version
                                        ],
                                }
                            ),
                            price:
                                prices.advancedPrice.monthly.baseWD[version] ||
                                prices.advancedPrice.monthly.base[version],
                            financationInfo: t(
                                `commonPricesNew:financationInfoCase4.${version}`
                            ),
                        },
                    },
                ],
            },
        ],
        [
            {
                type: TmpImageTextAndPriceAreaTypes.TWO_COLUMNS,
                leftContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.IMAGE,
                        content: {
                            image: {
                                images: [
                                    {
                                        jpeg: "/images/new/prices/advanced/advanced-clinic-mobile.jpg",
                                        webp: "/images/new/prices/advanced/advanced-clinic-mobile.webp",
                                    },
                                    {
                                        jpeg: "/images/new/prices/advanced/advanced-clinic-desktop.jpg",
                                        webp: "/images/new/prices/advanced/advanced-clinic-desktop.webp",
                                    },
                                ],
                                defaultSrc:
                                    "/images/new/prices/advanced/advanced-clinic-desktop.jpg",
                            },
                        },
                    },
                ],
                rightContent: [
                    {
                        type: TmpImageTextAndPriceBlocksTypes.LIST,
                        content: {
                            list: generateListKeys(t, 4, 1),
                            title: t("advanced:universalBlock4List1Title"),
                        },
                    },
                ],
            },
        ],
    ],
    appointment: {
        title: t("global:bookAppointmentButton"),
        image: {
            images: [
                {
                    jpeg: "/images/new/main/form-mobile.jpg",
                    webp: "/images/new/main/form-mobile.webp",
                },
                {
                    jpeg: "/images/new/main/form-desktop.jpg",
                    webp: "/images/new/main/form-desktop.webp",
                },
            ],
            defaultSrc: "/images/new/main/form-desktop.jpg",
        },
    },
});

export default pageContent;
