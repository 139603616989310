import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import defaultContent from "./spain";

const pageContent = (t: Translate) =>
    mergeDeepRight(defaultContent(t), {
        advantages: {
            list: [
                {
                    title: t("c2:advantagesListTitle1"),
                    description: t("c2:advantagesListDescription1"),
                    image: {
                        mobile: {
                            jpeg: "/images/c2/doctors-mobile-us.jpg",
                            webp: "/images/c2/doctors-mobile-us.webp",
                        },
                        desktop: {
                            jpeg: "/images/c2/doctors-desktop-2x-us.jpg",
                            webp: "/images/c2/doctors-desktop-2x-us.webp",
                        },
                    },
                },
                {
                    title: t("c2:advantagesListTitle2"),
                    description: t("c2:advantagesListDescription2"),
                    image: {
                        mobile: {
                            jpeg: "/images/first-visit/first-visit-1-mobile-us.jpg",
                            webp: "/images/first-visit/first-visit-1-mobile-us.webp",
                        },
                        desktop: {
                            jpeg: "/images/first-visit/first-visit-1-desktop-us.jpg",
                            webp: "/images/first-visit/first-visit-1-desktop-us.webp",
                        },
                    },
                },
                {
                    title: t("c2:advantagesListTitle2"),
                    description: t("c2:advantagesListDescription2"),
                    image: {
                        mobile: {
                            jpeg: "/images/first-visit/first-visit-4-mobile-us.jpg",
                            webp: "/images/first-visit/first-visit-4-mobile-us.webp",
                        },
                        desktop: {
                            jpeg: "/images/first-visit/first-visit-4-desktop-us.jpg",
                            webp: "/images/first-visit/first-visit-4-desktop-us.webp",
                        },
                    },
                },
            ],
        },
        slides: {
            title: t("c2:slidesTitle"),
            images: {
                sanFrancisco: [
                    {
                        mobile: {
                            webp: "/images/clinics-slides/us/san-francisco/clinic-mobile_1.webp",
                            jpeg: "/images/clinics-slides/us/san-francisco/clinic-mobile_1.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/us/san-francisco/clinic-desktop_1.webp",
                            jpeg: "/images/clinics-slides/us/san-francisco/clinic-desktop_1.jpg",
                        },
                    },
                    {
                        mobile: {
                            webp: "/images/clinics-slides/us/san-francisco/clinic-mobile_2.webp",
                            jpeg: "/images/clinics-slides/us/san-francisco/clinic-mobile_2.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/us/san-francisco/clinic-desktop_2.webp",
                            jpeg: "/images/clinics-slides/us/san-francisco/clinic-desktop_2.jpg",
                        },
                    },
                    {
                        mobile: {
                            webp: "/images/clinics-slides/us/san-francisco/clinic-mobile_3.webp",
                            jpeg: "/images/clinics-slides/us/san-francisco/clinic-mobile_3.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/us/san-francisco/clinic-desktop_3.webp",
                            jpeg: "/images/clinics-slides/us/san-francisco/clinic-desktop_3.jpg",
                        },
                    },
                ],
                chicago: [
                    {
                        mobile: {
                            webp: "/images/clinics-slides/us/chicago/clinic-mobile_1.webp",
                            jpeg: "/images/clinics-slides/us/chicago/clinic-mobile_1.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/us/chicago/clinic-desktop_1.webp",
                            jpeg: "/images/clinics-slides/us/chicago/clinic-desktop_1.jpg",
                        },
                    },
                    {
                        mobile: {
                            webp: "/images/clinics-slides/us/chicago/clinic-mobile_2.webp",
                            jpeg: "/images/clinics-slides/us/chicago/clinic-mobile_2.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/us/chicago/clinic-desktop_2.webp",
                            jpeg: "/images/clinics-slides/us/chicago/clinic-desktop_2.jpg",
                        },
                    },
                    {
                        mobile: {
                            webp: "/images/clinics-slides/us/chicago/clinic-mobile_3.webp",
                            jpeg: "/images/clinics-slides/us/chicago/clinic-mobile_3.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/us/chicago/clinic-desktop_3.webp",
                            jpeg: "/images/clinics-slides/us/chicago/clinic-desktop_3.jpg",
                        },
                    },
                ],
                seattle: [
                    {
                        mobile: {
                            webp: "/images/clinics-slides/us/seattle/clinic-mobile_1.webp",
                            jpeg: "/images/clinics-slides/us/seattle/clinic-mobile_1.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/us/seattle/clinic-desktop_1.webp",
                            jpeg: "/images/clinics-slides/us/seattle/clinic-desktop_1.jpg",
                        },
                    },
                    {
                        mobile: {
                            webp: "/images/clinics-slides/us/seattle/clinic-mobile_2.webp",
                            jpeg: "/images/clinics-slides/us/seattle/clinic-mobile_2.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/us/seattle/clinic-desktop_2.webp",
                            jpeg: "/images/clinics-slides/us/seattle/clinic-desktop_2.jpg",
                        },
                    },
                    {
                        mobile: {
                            webp: "/images/clinics-slides/us/seattle/clinic-mobile_3.webp",
                            jpeg: "/images/clinics-slides/us/seattle/clinic-mobile_3.jpg",
                        },
                        desktop: {
                            webp: "/images/clinics-slides/us/seattle/clinic-desktop_3.webp",
                            jpeg: "/images/clinics-slides/us/seattle/clinic-desktop_3.jpg",
                        },
                    },
                ],
            },
        },
        tabs: [
            {
                value: "sanFrancisco",
                title: "San Francisco",
            },
            {
                value: "chicago",
                title: "Chicago",
            },
            {
                value: "seattle",
                title: "Seattle",
            },
        ],
    });

export default pageContent;
