import { Translate } from "next-translate";

import contacts from "configs/contacts.json";
import { PageContentProps } from "content/content.types";
import { pathConfig } from "navigation/pathConfig";

const pageContent = (t: Translate, { version }: PageContentProps) => ({
    mainBanner: {
        title: t("about:mainBannerTitle"),
        image: {
            desktop: {
                webp: `/images/about/team-desktop.webp`,
                jpeg: `/images/about/team-desktop.jpg`,
                media: "(min-width: 1024px)",
            },
            mobile: {
                webp: `/images/about/team-mobile.webp`,
                jpeg: `/images/about/team-mobile.jpg`,
            },
        },
    },
    perfectSmile: {
        title: t("about:perfectSmileTitle"),
        description: t("about:perfectSmileDescription"),
        title2: t("about:perfectSmileTitle2"),
        quote: {
            text: t("about:perfectSmileQuoteText"),
            author: t("about:perfectSmileQuoteAuthor"),
            imageDesktop: "/images/about/khaled-desktop.jpeg",
            imageMobile: "/images/about/khaled-avatar.png",
        },
        actionBooking: {
            name: t("global:bookAppointmentButton"),
            link: pathConfig.universalForm[version],
        },
        image: {
            desktop: {
                webp: "/images/about/perfect-smile-desktop.webp",
                jpeg: "/images/about/perfect-smile-desktop.jpg",
            },
        },
    },
    likeNeverBefore: {
        title: t("about:likeNeverBeforeTitle"),
        description: t("about:likeNeverBeforeDescription"),
        quote: {
            text: t("about:likeNeverBeforeQuoteText"),
            author: t("about:likeNeverBeforeQuoteAuthor"),
            imageDesktop: "/images/about/vladimir-avatar.jpg",
        },
        actionJoinUs: {
            name: t("global:bookAppointmentButton"),
            link: pathConfig.universalForm[version],
        },
        image: {
            desktop: {
                webp: "/images/about/show-aligner.webp",
                jpeg: "/images/about/show-aligner.jpeg",
            },
        },
    },
    partOfChanges: {
        title: t("about:partOfChangesTitle"),
        description: t("about:partOfChangesDescription"),
        quote: {
            text: t("about:partOfChangesQuoteText"),
            author: t("about:partOfChangesQuoteAuthor"),
            imageDesktop: "/images/about/khaled-avatar.png",
        },
        actionJoinUs: {
            name: t("about:partOfChangesActionTitle"),
            link: "https://www.linkedin.com/company/smile2impress/jobs/",
        },
        image: {
            desktop: {
                webp: "/images/about/doctors-desktop.webp",
                jpeg: "/images/about/doctors-desktop.jpg",
            },
        },
    },
    questions: {
        title: t("about:faqTitle"),
        list: [
            {
                question: t("faq:questionsListItemQuestion1"),
                answer: t("faq:questionsListItemAnswer1"),
            },
            {
                question: t("faq:questionsListItemQuestion2"),
                answer: t("faq:questionsListItemAnswer2"),
            },
            {
                question: t("faq:questionsListItemQuestion3"),
                answer: t("faq:questionsListItemAnswer3"),
            },
            {
                question: t("faq:questionsListItemQuestion4"),
                answer: t("faq:questionsListItemAnswer4"),
            },
        ],
    },
    results: {
        actionBooking: {
            name: t("global:bookAppointmentButton"),
            link: pathConfig.universalForm[version],
        },
        title: t("about:resultsTitle"),
        description: t("about:resultsDescription"),
        stories: [
            {
                mobile: {
                    webp: "/images/about/story-1.webp",
                    jpeg: "/images/about/story-1.jpeg",
                },
                desktop: {
                    webp: "/images/about/story-2.webp",
                    jpeg: "/images/about/story-2.jpeg",
                    media: "(min-width: 1024px)",
                },
            },
            {
                mobile: {
                    webp: "/images/about/story-3.webp",
                    jpeg: "/images/about/story-3.jpeg",
                },
                desktop: {
                    webp: "/images/about/story-3-desktop.webp",
                    jpeg: "/images/about/story-3-desktop.jpeg",
                    media: "(min-width: 1024px)",
                },
            },
            {
                mobile: {
                    webp: "/images/about/story-2.webp",
                    jpeg: "/images/about/story-2.jpeg",
                },
                desktop: {
                    webp: "/images/about/story-1-desktop.webp",
                    jpeg: "/images/about/story-1-desktop.jpeg",
                    media: "(min-width: 1024px)",
                },
            },
        ],
    },
    legalAddress: {
        register: t("about:legalAddressDescription"),
        title: t("about:legalAddressTitle"),
        description: t("about:legalAddressDescription"),
        fields: [
            {
                label: t("about:legalAddressDirectionTitle"),
                value: t(`about:legalAddressDirectionValue.${version}`),
            },
            {
                label: t("about:legalAddressPhoneTitle"),
                value: contacts.phone[version].public,
            },
            {
                label: t("global:email"),
                value: t("about:legalAddressEmailValue", {
                    email: `${contacts.email[version].link}`,
                }),
            },
            {
                label: t("about:legalRegisterTitle"),
                value: t(`about:legalRegisterValue.${version}`),
            },
        ],
    },
});

export default pageContent;
