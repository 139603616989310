export interface BookingStepsProps {
    list: StepsListProps[];
    activeStepName: BookingStepType;

    className?: string;
}

export interface StepsListProps {
    text: string;
    index: number;
    title: string;
    stepName: BookingStepType;
}

export enum BookingStepType {
    FIRST = "first",
    SECOND = "second",
    THIRD = "third",
    FOURTH = "fourth",
}
