import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { pathConfig } from "navigation/pathConfig";
import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent: any = (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        leadersBlock: {
            title: t("whyImpress:leadersBlockTitle"),
            subtitle: t("whyImpress:leadersBlockSubtitle"),
            clinicImg: "/images/new/whyImpress/leaders/leader-1-desktop.jpg",
            visitUs: {
                name: t("whyImpress:leadersBlockVisitUs"),
                link: pathConfig.universalForm[version],
            },
            seeAllClinics: {
                name: t("whyImpress:leadersBlockSeeAllClinis"),
                link: pathConfig.locations[version],
            },
            cabinetImg: "/images/new/whyImpress/leaders/leader-2-desktop.jpg",
            equipmentImg: "/images/new/whyImpress/leaders/leader-3-desktop.jpg",
        },
        mainBanner: {
            imageDesktop: "/images/new/whyImpress/bg-main.jpg",
            imageMobile: "/images/new/whyImpress/bg-main-mobile.jpg",
            title: t("whyImpress:mainBannerTitle"),
        },
    });

export default pageContent;
