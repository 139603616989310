import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import defaultContent from "./spain";

const pageContent = (t: Translate) =>
    mergeDeepRight(defaultContent(t), {
        main: {
            quote: null,
            poster_big: {
                mobile: {
                    jpeg: "/images/teens/portugal/main-girl-mobile-1.jpg",
                    webp: "/images/teens/portugal/main-girl-mobile-1.webp",
                },
                desktop: {
                    jpeg: "/images/teens/portugal/main-girl-desktop-1.jpg",
                    webp: "/images/teens/portugal/main-girl-desktop-1.webp",
                },
            },
            poster_small: {
                mobile: {
                    jpeg: "/images/teens/portugal/main-girl-mobile-2.jpg",
                    webp: "/images/teens/portugal/main-girl-mobile-2.webp",
                },
                desktop: {
                    jpeg: "/images/teens/portugal/main-girl-desktop-2.jpg",
                    webp: "/images/teens/portugal/main-girl-desktop-2.webp",
                },
            },
        },
        steps: {
            list: [
                {
                    title: t("teen:stepsListItem1Title"),
                    img: {
                        jpeg: "/images/teens/step1-2x.jpg",
                        webp: "/images/teens/step1-2x.webp",
                    },
                    text: t("teen:stepsListItem1Text"),
                },
                {
                    title: t("teen:stepsListItem2Title"),
                    img: {
                        jpeg: "/images/teens/step2-2x.jpg",
                        webp: "/images/teens/step2-2x.webp",
                    },
                    text: t("teen:stepsListItem2Text"),
                },
                {
                    title: t("teen:stepsListItem3Title"),
                    img: {
                        jpeg: "/images/teens/portugal/step3.jpg",
                        webp: "/images/teens/portugal/step3.webp",
                    },
                    text: t("teen:stepsListItem3Text"),
                },
            ],
        },
        brackets: {
            firstExperience: {
                mainPhoto: {
                    mobile: {
                        jpeg: "/images/teens/portugal/girl-mobile.jpg",
                        webp: "/images/teens/portugal/girl-mobile.webp",
                    },
                    desktop: {
                        jpeg: "/images/teens/portugal/girl-desktop.jpg",
                        webp: "/images/teens/portugal/girl-desktop.webp",
                    },
                },
                subtitle: null,
                tooltip: null,
                treatmentPlan: null,
                description: t("teen:bracketsFirstExperienceDescription"),
                titleNextOpinion: null,
            },
            secondExperience: null,
        },
    });

export default pageContent;
