import { Translate } from "next-translate";

import { PricesLayoutContent } from "layouts/pages/PricesLayout/PricesLayout.types";

const pageContent = (t: Translate): PricesLayoutContent => ({
    welcome: {
        title: t("pricesPage:welcomeTitle"),
        image: {
            images: [
                {
                    jpeg: "/images/new/prices/hero-mobile.jpg",
                    webp: "/images/new/prices/hero-mobile.webp",
                },
                {
                    jpeg: "/images/new/prices/hero-desktop.jpg",
                    webp: "/images/new/prices/hero-desktop.webp",
                },
            ],
            rounded: true,
        },
    },
    informationBlock: {
        title: t("pricesPage:informationBlockTitle"),
        description: t("pricesPage:informationBlockDescription"),
        image: {
            desktop: {
                jpeg: "/images/new/prices/office-desktop.jpg",
                webp: "/images/new/prices/office-desktop.webp",
                media: "(min-width: 1024px)",
            },
            mobile: {
                jpeg: "/images/new/prices/office-mobile.jpg",
                webp: "/images/new/prices/office-mobile.webp",
            },
        },
    },
    warranty: {
        title: t("pricesPage:warrantyTitle"),
        alignerImage: {
            desktop: {
                jpeg: "/images/new/prices/aligner-desktop.jpg",
                webp: "/images/new/prices/aligner-desktop.webp",
                media: "(min-width: 1024px)",
            },
            mobile: {
                jpeg: "/images/new/prices/aligner-mobile.jpg",
                webp: "/images/new/prices/aligner-mobile.webp",
            },
        },
        list: [
            t("pricesPage:warrantyList1"),
            t("pricesPage:warrantyList2"),
            t("pricesPage:warrantyList3"),
            t("pricesPage:warrantyList4"),
            t("pricesPage:warrantyList5"),
        ],
    },
    appointment: {
        title: t("global:bookAppointmentButton"),
        image: {
            images: [
                {
                    jpeg: `/images/new/main/form-mobile.jpg`,
                    webp: `/images/new/main/form-mobile.webp`,
                },
                {
                    jpeg: `/images/new/main/form-desktop.jpg`,
                    webp: `/images/new/main/form-desktop.webp`,
                },
            ],
        },
        analyticIdZone: "prices-page",
    },
});

export default pageContent;
