import { Translate } from "next-translate";

import { pathConfig } from "navigation/pathConfig";
import { PageContentProps } from "content/content.types";
import { ThanksContactLayoutContent } from "layouts/pages/ThanksContactLayout/ThanksContactLayout.types";

const pageContent = (
    t: Translate,
    { version }: PageContentProps
): ThanksContactLayoutContent => ({
    logoLink: pathConfig.main[version],
    image: {
        images: [
            {
                webp: "/images/new/thanks-contact/mobile.webp",
                jpeg: "/images/new/thanks-contact/mobile.jpg",
            },
            {
                webp: "/images/new/thanks-contact/desktop.webp",
                jpeg: "/images/new/thanks-contact/desktop.jpg",
            },
        ],
        defaultSrc: "/images/new/thanks-contact/mobile.jpg",
    },
    title: t("title"),
    description: t("description"),
});

export default pageContent;
