import { Translate } from "next-translate";

import { TmpDoctorProps } from "components/templates/TmpDoctorsBlock";

export const MAX_DOCTORS = 4;

const getDoctorById = (t: Translate, id: number): TmpDoctorProps => ({
    name: t(`associatedClinics:doctorsBlockDoctorName${id}`),
    description: t(`associatedClinics:doctorsBlockDoctorDescription${id}`),
    image: {
        images: [
            {
                webp: `/images/new/associatedClinics/doctors-${id}-mobile.webp`,
                jpeg: `/images/new/associatedClinics/doctors-${id}-mobile.jpg`,
            },
            {
                webp: `/images/new/associatedClinics/doctors-${id}-desktop.webp`,
                jpeg: `/images/new/associatedClinics/doctors-${id}-desktop.jpg`,
            },
        ],
        defaultSrc: `/images/new/associatedClinics/doctors-${id}-mobile.webp`,
    },
});

export const getDoctorsContent = (t: Translate, maxDoctors: number) =>
    new Array(maxDoctors).fill(null).map((_, i) => getDoctorById(t, i + 1));
