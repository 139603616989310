import { Translate } from "next-translate";

const pageContent = (t: Translate) => ({
    appointment: {
        title: t("global:bookAppointmentButton"),
    },
    main: {
        title: t("teen:mainTitle"),
        subtitle: t("teen:mainSubtitle"),
        description: t("teen:mainDescription"),
        ctaButton: t("global:bookAppointmentButton"),
        quote: {
            avatar: "/images/quotes/authors/khaled.jpg",
            text: t("teen:mainDoctorQuote"),
            author: t("teen:mainDoctorName"),
        },
        poster_big: {
            mobile: {
                jpeg: "/images/teens/main-girl-mobile-1-2x.jpg",
                webp: "/images/teens/main-girl-mobile-1-2x.webp",
            },
            desktop: {
                jpeg: "/images/teens/main-girl-desktop-1-2x.jpg",
                webp: "/images/teens/main-girl-desktop-1-2x.webp",
            },
        },
        poster_small: {
            mobile: {
                jpeg: "/images/teens/main-girl-mobile-2-2x.jpg",
                webp: "/images/teens/main-girl-mobile-2-2x.webp",
            },
            desktop: {
                jpeg: "/images/teens/main-girl-desktop-2-2x.jpg",
                webp: "/images/teens/main-girl-desktop-2-2x.webp",
            },
        },
        advantages: [
            t("teen:mainAdvantagesListItem1"),
            t("teen:mainAdvantagesListItem2"),
            t("teen:mainAdvantagesListItem3"),
            t("teen:mainAdvantagesListItem4"),
            t("teen:mainAdvantagesListItem5"),
        ],
    },
    steps: {
        title: t("teen:stepsTitle"),
        list: [
            {
                title: t("teen:stepsListItem1Title"),
                img: {
                    jpeg: "/images/teens/step1-2x.jpg",
                    webp: "/images/teens/step1-2x.webp",
                },
                text: t("teen:stepsListItem1Text"),
            },
            {
                title: t("teen:stepsListItem2Title"),
                img: {
                    jpeg: "/images/teens/step2-2x.jpg",
                    webp: "/images/teens/step2-2x.webp",
                },
                text: t("teen:stepsListItem2Text"),
            },
            {
                title: t("teen:stepsListItem3Title"),
                img: {
                    jpeg: "/images/teens/step3-2x.jpg",
                    webp: "/images/teens/step3-2x.webp",
                },
                text: t("teen:stepsListItem3Text"),
            },
        ],
        ctaButton: t("global:bookAppointmentButton"),
    },
    brackets: {
        firstExperience: {
            mainPhoto: {
                mobile: {
                    jpeg: "/images/teens/girl-mobile.jpg",
                    webp: "/images/teens/girl-mobile.webp",
                },
                desktop: {
                    jpeg: "/images/teens/girl-desktop.jpg",
                    webp: "/images/teens/girl-desktop.webp",
                },
            },
            title: t("teen:bracketsFirstExperienceTitle"),
            images: [
                {
                    mobile: {
                        jpeg: "/images/teens/teeth-1_mobile.jpg",
                        webp: "/images/teens/teeth-1_mobile.webp",
                    },
                    desktop: {
                        jpeg: "/images/teens/teeth-1_desktop.jpg",
                        webp: "/images/teens/teeth-1_desktop.webp",
                    },
                },
                {
                    mobile: {
                        jpeg: "/images/teens/teeth-2_mobile.jpg",
                        webp: "/images/teens/teeth-2_mobile.webp",
                    },
                    desktop: {
                        jpeg: "/images/teens/teeth-2_desktop.jpg",
                        webp: "/images/teens/teeth-2_desktop.webp",
                    },
                },
            ],
            subtitle: t("teen:bracketsFirstExperienceSubtitle"),
            tooltip: t("teen:bracketsFirstExperienceTooltip"),
            treatmentPlan: t("teen:bracketsFirstExperienceTreatmentPlan"),
            description: t("teen:bracketsFirstExperienceDescription"),
            titleNextOpinion: t("teen:bracketsFirstExperienceTitleNextOpinion"),
        },
        secondExperience: {
            title: t("teen:bracketsSecondExperienceTitle"),
            mainPhoto: {
                mobile: {
                    jpeg: "/images/teens/dad-mobile.jpg",
                    webp: "/images/teens/dad-mobile.webp",
                },
                desktop: {
                    jpeg: "/images/teens/dad-desktop.jpg",
                    webp: "/images/teens/dad-desktop.webp",
                },
            },
            subtitle: t("teen:bracketsSecondExperienceSubtitle"),
            tooltip: t("teen:bracketsSecondExperienceTooltip"),
            description: t("teen:bracketsSecondExperienceDescription"),
            titleNextOpinion: t(
                "teen:bracketsSecondExperienceTitleNextOpinion"
            ),
        },
    },
    clinic_visit: {
        title: t("teen:clinicVisitTitle"),
        description: t("teen:clinicVisitDescription"),
        ctaButton: t("global:bookAppointmentButton"),
        poster_small: {
            mobile: {
                jpeg: "/images/teens/clinic-visit-boy-mobile-1-2x.jpg",
                webp: "/images/teens/clinic-visit-boy-mobile-1-2x.webp",
            },
            desktop: {
                jpeg: "/images/teens/clinic-visit-boy-desktop-1-2x.jpg",
                webp: "/images/teens/clinic-visit-boy-desktop-1-2x.webp",
            },
        },
        poster_big: {
            mobile: {
                jpeg: "/images/teens/clinic-visit-boy-mobile-2-2x.jpg",
                webp: "/images/teens/clinic-visit-boy-mobile-2-2x.webp",
            },
            desktop: {
                jpeg: "/images/teens/clinic-visit-boy-desktop-2-2x.jpg",
                webp: "/images/teens/clinic-visit-boy-desktop-2-2x.webp",
            },
        },
    },
    advantages: {
        title: t("teen:pricesAdvantagesTitle"),
        list: [
            {
                icon: "scan3D",
                text: t("teen:pricesAdvantagesContentItem1Text"),
            },
            {
                icon: "house",
                text: t("teen:pricesAdvantagesContentItem2Text"),
            },
        ],
    },
});

export default pageContent;
