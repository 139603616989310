import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { RemarketingC2PageContent } from "./content.types";
import defaultContent from "./spain";

const pageContent = (t: Translate): RemarketingC2PageContent =>
    mergeDeepRight(defaultContent(t), {
        slides: null,
        tabs: null,
    });

export default pageContent;
