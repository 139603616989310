import { Translate } from "next-translate";

import { NewCityAdvertisingLayoutContent } from "./content.types";

const content = (t: Translate): NewCityAdvertisingLayoutContent => ({
    title: t("newCity:title"),
    image: {
        mobile: {
            jpeg: "/images/new-city/mobile-image.jpg",
            webp: "/images/new-city/mobile-image.webp",
        },
    },
    background: [
        "/images/new-city/01.jpg",
        "/images/new-city/02.jpg",
        "/images/new-city/03.jpg",
        "/images/new-city/04.jpg",
        "/images/new-city/05.jpg",
        "/images/new-city/06.jpg",
    ],
});

export default content;
