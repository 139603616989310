import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";
import { getDesignPostfix } from "utils/getDesignPostfix";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const { newDesignPostfix } = getDesignPostfix(version);

    return mergeDeepRight(defaultContent(t, { version }), {
        hygiene: {
            image: {
                mobile: {
                    webp: `/images/results/hygiene-mobile${newDesignPostfix}.webp`,
                    jpeg: `/images/results/hygiene-mobile${newDesignPostfix}.jpg`,
                },
                desktop: {
                    webp: `/images/results/hygiene-desktop${newDesignPostfix}.webp`,
                    jpeg: `/images/results/hygiene-desktop${newDesignPostfix}.jpg`,
                },
            },
        },
    });
};

export default pageContent;
