import { versions } from "configs/versionsConfig";

import france from "./france";
import italy from "./italy";
import portugal from "./portugal";
import spain from "./spain";
import uk from "./uk";

export default {
    [versions.france]: france,
    [versions.italy]: italy,
    [versions.portugal]: portugal,
    [versions.spain]: spain,
    [versions.uk]: uk,
};
