import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) =>
    mergeDeepRight(defaultContent(t, { version }), {
        advantages: {
            title: t("firstVisit:avantagesTitle"),
            list: [
                {
                    title: t("firstVisit:avantagesListTitle1"),
                    description: t("firstVisit:avantagesListDescription1"),
                    image: {
                        mobile: {
                            jpeg: "/images/first-visit/us/first-visit-1-mobile.jpg",
                            webp: "/images/first-visit/us/first-visit-1-mobile.webp",
                        },
                        desktop: {
                            jpeg: "/images/first-visit/us/first-visit-1-desktop.jpg",
                            webp: "/images/first-visit/us/first-visit-1-desktop.webp",
                        },
                    },
                },
                {
                    title: t("firstVisit:avantagesListTitle2"),
                    description: t("firstVisit:avantagesListDescription2"),
                    image: {
                        mobile: {
                            jpeg: "/images/first-visit/us/first-visit-2-mobile.jpg",
                            webp: "/images/first-visit/us/first-visit-2-mobile.webp",
                        },
                        desktop: {
                            jpeg: "/images/first-visit/us/first-visit-2-desktop.jpg",
                            webp: "/images/first-visit/us/first-visit-2-desktop.webp",
                        },
                    },
                },
                {
                    title: t("firstVisit:avantagesListTitle4"),
                    description: t("firstVisit:avantagesListDescription4"),
                    image: {
                        mobile: {
                            jpeg: "/images/first-visit/us/first-visit-3-mobile.jpg",
                            webp: "/images/first-visit/us/first-visit-3-mobile.webp",
                        },
                        desktop: {
                            jpeg: "/images/first-visit/us/first-visit-3-desktop.jpg",
                            webp: "/images/first-visit/us/first-visit-3-desktop.webp",
                        },
                    },
                },
            ],
        },
        slides: {
            title: t("firstVisit:slidesTitle"),
            images: [
                {
                    mobile: {
                        webp: "/images/clinics/us/slides/photo-1.webp",
                        jpeg: "/images/clinics/us/slides/photo-1.jpg",
                    },
                    rounded: true,
                },
                {
                    mobile: {
                        webp: "/images/clinics/us/slides/photo-2.webp",
                        jpeg: "/images/clinics/us/slides/photo-2.jpg",
                    },
                    rounded: true,
                },
                {
                    mobile: {
                        webp: "/images/clinics/us/slides/photo-3.webp",
                        jpeg: "/images/clinics/us/slides/photo-3.jpg",
                    },
                    rounded: true,
                },
                {
                    mobile: {
                        webp: "/images/clinics/us/slides/photo-4.webp",
                        jpeg: "/images/clinics/us/slides/photo-4.jpg",
                    },
                    rounded: true,
                },
                {
                    mobile: {
                        webp: "/images/clinics/us/slides/photo-5.webp",
                        jpeg: "/images/clinics/us/slides/photo-5.jpg",
                    },
                    rounded: true,
                },
            ],
        },
        doctors: {
            title: t("firstVisit:doctorsTitle"),
            description: t("firstVisit:doctorsDescription"),
            images: [
                {
                    image: {
                        desktop: {
                            jpeg: "/images/doctors/us/doctor_desktop_1.jpg",
                            webp: "/images/doctors/us/doctor_desktop_1.webp",
                            media: "(min-width: 1024px)",
                        },
                        mobile: {
                            jpeg: "/images/doctors/us/doctor_mobile_1.jpg",
                            webp: "/images/doctors/us/doctor_mobile_1.webp",
                        },
                    },
                },
                {
                    image: {
                        desktop: {
                            jpeg: "/images/doctors/us/doctor_desktop_2.jpg",
                            webp: "/images/doctors/us/doctor_desktop_2.webp",
                            media: "(min-width: 1024px)",
                        },
                        mobile: {
                            jpeg: "/images/doctors/us/doctor_mobile_2.jpg",
                            webp: "/images/doctors/us/doctor_mobile_2.webp",
                        },
                    },
                },
                {
                    image: {
                        desktop: {
                            jpeg: "/images/doctors/us/doctor_desktop_3.jpg",
                            webp: "/images/doctors/us/doctor_desktop_3.webp",
                            media: "(min-width: 1024px)",
                        },
                        mobile: {
                            jpeg: "/images/doctors/us/doctor_mobile_3.jpg",
                            webp: "/images/doctors/us/doctor_mobile_3.webp",
                        },
                    },
                },
                {
                    image: {
                        desktop: {
                            jpeg: "/images/doctors/us/doctor_desktop_4.jpg",
                            webp: "/images/doctors/us/doctor_desktop_4.webp",
                            media: "(min-width: 1024px)",
                        },
                        mobile: {
                            jpeg: "/images/doctors/us/doctor_mobile_4.jpg",
                            webp: "/images/doctors/us/doctor_mobile_4.webp",
                        },
                    },
                },
            ],
        },
    });

export default pageContent;
