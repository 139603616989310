import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import defaultContent from "./spain";

const pageContent = (t: Translate) =>
    mergeDeepRight(defaultContent(t), {
        advantages: {
            first: {
                list: [
                    t("advantages-block:firstListItem1"),
                    t("advantages-block:firstListItem2"),
                    t("advantages-block:firstListItem3"),
                    t("advantages-block:firstListItem4"),
                ],
            },
        },
    });

export default pageContent;
