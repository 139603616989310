import { Translate } from "next-translate";

import prices from "configs/prices.json";
import { PageContentProps } from "content/content.types";
import { pathConfig } from "navigation/pathConfig";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    const regularMonthPrice =
        prices.subscriptions.retainers.light.monthly[version];

    return {
        headerBlock: {
            headerBlockImage: {
                defaultSrc: `/images/retainers-subscription/header-block-desktop.jpg`,
                images: [
                    {
                        webp: `/images/retainers-subscription/header-block-mobile.webp`,
                        jpeg: `/images/retainers-subscription/header-block-mobile.jpg`,
                    },
                    {
                        webp: `/images/retainers-subscription/header-block-desktop.webp`,
                        jpeg: `/images/retainers-subscription/header-block-desktop.jpg`,
                    },
                ],
            },
            headerBlockTitle: t("retainersSubscription:headerBlockTitle"),
            headerBlockDescription: t(
                "retainersSubscription:headerBlockDescription"
            ),
            headerBlockFromPrice: t(
                "retainersSubscription:headerBlockFromPrice",
                {
                    price: regularMonthPrice,
                }
            ),
            headerBlockCurrencyAndMonth: t("global:currencyAndMonth", {
                currency: prices.currency[version],
            }),
            headerBlockButton: {
                label: t("retainersSubscription:headerBlockButtonLabel"),
                link: pathConfig.universalForm[version],
            },
        },
        howDoTheyWorkBlock: {
            howDoTheyWorkBlockTitle: t(
                "retainersSubscription:howDoTheyWorkBlockTitle"
            ),
            howDoTheyWorkBlockImage: {
                defaultSrc: `/images/retainers-subscription/how-do-they-work-desktop.jpg`,
                images: [
                    {
                        webp: `/images/retainers-subscription/how-do-they-work-mobile.webp`,
                        jpeg: `/images/retainers-subscription/how-do-they-work-mobile.jpg`,
                    },
                    {
                        webp: `/images/retainers-subscription/how-do-they-work-desktop.webp`,
                        jpeg: `/images/retainers-subscription/how-do-they-work-desktop.jpg`,
                    },
                ],
            },
            howDoTheyWorkBlockList: [
                {
                    number: 1,
                    title: t(
                        "retainersSubscription:howDoTheyWorkBlockListFirstTitle"
                    ),
                    description: t(
                        "retainersSubscription:howDoTheyWorkBlockListFirstDescription"
                    ),
                },
                {
                    number: 2,
                    title: t(
                        "retainersSubscription:howDoTheyWorkBlockListSecondTitle"
                    ),
                    description: t(
                        "retainersSubscription:howDoTheyWorkBlockListSecondDescription"
                    ),
                },
                {
                    number: 3,
                    title: t(
                        "retainersSubscription:howDoTheyWorkBlockListThirdTitle"
                    ),
                    description: t(
                        "retainersSubscription:howDoTheyWorkBlockListThirdDescription"
                    ),
                },
            ],
        },
        subscriptionPriceBlock: {
            subscriptionPriceBlockTitle: t(
                "retainersSubscription:subscriptionPriceBlockTitle"
            ),
        },
        keepMySmileBlock: {
            keepMySmileBlockTitle: t(
                "retainersSubscription:keepMySmileBlockTitle"
            ),
        },
        sliderBlock: {
            sliderBlockTitle: t("retainersSubscription:sliderBlockTitle"),
            sliderBlockDescription: t(
                "retainersSubscription:sliderBlockDescription"
            ),
            sliderBlockDescriptionTextLink: t(
                "retainersSubscription:sliderBlockDescriptionTextLink"
            ),
            sliderBlockDescriptionLink: pathConfig.locations[version],
            sliderBlockImage: {
                defaultSrc: `/images/retainers-subscription/keep-my-smile-3-desktop.jpg`,
                images: [
                    {
                        webp: `/images/retainers-subscription/keep-my-smile-3-mobile.webp`,
                        jpeg: `/images/retainers-subscription/keep-my-smile-3-mobile.jpg`,
                    },
                    {
                        webp: `/images/retainers-subscription/keep-my-smile-3-desktop.webp`,
                        jpeg: `/images/retainers-subscription/keep-my-smile-3-desktop.jpg`,
                    },
                ],
            },
        },
        faqBlock: {
            title: t("retainersSubscription:faqBlockTitle"),
            list: [
                {
                    question: t("retainersSubscription:faqBlockQuestionOne"),
                    answer: t("retainersSubscription:faqBlockAnswerOne"),
                },
                {
                    question: t("retainersSubscription:faqBlockQuestionTwo"),
                    answer: t("retainersSubscription:faqBlockAnswerTwo"),
                },
                {
                    question: t("retainersSubscription:faqBlockQuestionThree"),
                    answer: t("retainersSubscription:faqBlockAnswerThree"),
                },
                {
                    question: t("retainersSubscription:faqBlockQuestionFour"),
                    answer: t("retainersSubscription:faqBlockAnswerFour"),
                },
                {
                    question: t("retainersSubscription:faqBlockQuestionFive"),
                    answer: t("retainersSubscription:faqBlockAnswerFive"),
                },
            ],
        },
    };
};

export default pageContent;
