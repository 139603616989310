import { PictureProps } from "types/picture";

export enum TmpUniversalTitleWithImageBlocksTypes {
    HEADING = "heading",
    IMAGE = "image",
}

export interface HeadingBlockContent {
    title: string;
    subtitle?: string;
    button?: {
        text: string;
        link: string;
    };
}
export interface ImageBlockContent {
    image: PictureProps;
}

export type TmpUniversalTitleWithImageBlockContent =
    | {
          type: TmpUniversalTitleWithImageBlocksTypes.HEADING;
          content: HeadingBlockContent;
      }
    | {
          type: TmpUniversalTitleWithImageBlocksTypes.IMAGE;
          content: ImageBlockContent;
      };

export interface TmpUniversalTitleWithImageProps {
    content: TmpUniversalTitleWithImageBlockContent[];
}
