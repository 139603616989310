import { mergeDeepRight } from "ramda";
import { Translate } from "next-translate";

import defaultContent from "./spain";

const pageContent = (t: Translate) =>
    mergeDeepRight(defaultContent(t), {
        welcome: {
            title: t("pricesPage:welcomeTitle"),
            image: {
                images: [
                    {
                        jpeg: "/images/new/prices/office-chair-mobile.jpg",
                        webp: "/images/new/prices/office-chair-mobile.webp",
                    },
                    {
                        jpeg: "/images/new/prices/office-chair-desktop.jpg",
                        webp: "/images/new/prices/office-chair-desktop.webp",
                    },
                ],
                rounded: true,
            },
        },
        informationBlock: {
            title: t("pricesPage:informationBlockTitle"),
            description: t("pricesPage:informationBlockDescription"),
            image: {
                desktop: {
                    jpeg: "/images/new/prices/demo-clinic-desktop.jpg",
                    webp: "/images/new/prices/demo-clinic-desktop.webp",
                    media: "(min-width: 1024px)",
                },
                mobile: {
                    jpeg: "/images/new/prices/demo-clinic-mobile.jpg",
                    webp: "/images/new/prices/demo-clinic-mobile.webp",
                },
            },
        },
        appointment: {
            title: t("global:bookAppointmentButton"),
            image: {
                images: [
                    {
                        jpeg: "/images/new/main/form-ua-mobile.jpg",
                        webp: "/images/new/main/form-ua-mobile.webp",
                    },
                    {
                        jpeg: "/images/new/main/form-ua-desktop.jpg",
                        webp: "/images/new/main/form-ua-desktop.webp",
                    },
                ],
                defaultSrc: "/images/new/main/form-ua-desktop.jpg",
            },
        },
    });

export default pageContent;
